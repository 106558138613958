import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {server} from '../../util/server';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
SwiperCore.use([Navigation]);

const BrandSlider = ({brands}) => {
    const { i18n } = useTranslation();
    return (
        <>
            <Swiper
                slidesPerView={6}
                spaceBetween={20}
                //loop={false}
                breakpoints={{
                    0: {
                        slidesPerView: 3,
                    },
                    500: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1000:{
                        slidesPerView: 5,
                    },
                    1200: {
                        slidesPerView: 6,
                    },
              }}
                navigation={{
                    prevEl: ".custom_prev_br1",
                    nextEl: ".custom_next_br1",
                }}
                className="carausel-6-columns text-center"
            >
                {brands.map((item, i) => (
                    <SwiperSlide key={i}>
                        <div className="brand-logo">
                            <Link to={"/marken/"+item.slug}>
                            <img
                                className="img-grey-hover"
                                src={item.image && server+"/"+item.image}
                                alt={item['alt_'+i18n.language]}
                            />
                            </Link>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* <div
                className="slider-arrow slider-arrow-2 carausel-6-columns-arrow"
                id="carausel-6-columns-3-arrows"
            >
                <span className="slider-btn slider-prev slick-arrow custom_prev_br1">
                    <i className="fi-rs-angle-left"></i>
                </span>
                <span className="slider-btn slider-next slick-arrow custom_next_br1">
                    <i className="fi-rs-angle-right"></i>
                </span>
            </div> */}
        </>
    );
};

export default BrandSlider;
