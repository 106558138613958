import React from "react";
import {Link} from "react-router-dom"
import {useFilterContext} from '../../context/filterContext';
import {server} from '../../util/server';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import axios from "axios";
import SupportEngine from '../../SupportEngine';
import ReactWhatsapp from "react-whatsapp";

const Footer = () => {
    const { i18n, t } = useTranslation();
    const {logo,phone_number,email,address,social_icons,timing,footer_bottom_bar,webSettingsData} = useFilterContext();

    const newsletter_Subscription = async(email) => {
        try {
            let url = server+"/api/subscribe-to-newsletter"
            let res = await axios.post(url, {'subscriber_email' :email });
            let data = await res.data;
            if(data.status === true){
                toast.success(t(data.message))
                document.getElementById('subscriber').value = null;
            }else{
                toast.error(t(data.message))
                if(data.errors){
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(t(item));
                        })
                        
                    })
                }
            }
           
        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }
    const subscribeToNewsletter = (e) => {
        e.preventDefault();
        const subscriber_mail = document.getElementById('subscriber').value;
        if(!subscriber_mail){
            toast.error(t("Email is required"))
        }else{
            newsletter_Subscription(subscriber_mail);
        }
    }
    return (
        <>
            <footer className="main">
                <section className="newsletter pt-30 pb-30 mt-30 text-white wow fadeIn animated">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 d-lg-inline-block d-none  mb-md-3 mb-lg-0">
                                <div className="row align-items-center">
                                    <div className="col flex-horizontal-center">
                                    <i className="fas fa-envelope"></i>
                                        <h4 className="font-size-20 mb-0 ml-3">
                                        {t("Subscribe To Newsletter")}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <form className="form-subcriber d-flex wow fadeIn animated" method="post">
                                    <input
                                        type="email"
                                        className="form-control bg-white font-small"
                                        placeholder={t("Enter your email")}
                                        id="subscriber"
                                        onChange={(e) => e.target.value}
                                    />
                                    <button
                                        className="btn bg-dark text-white"
                                        type="submit"
                                        onClick={(e) => {subscribeToNewsletter(e)}}
                                    >
                                        {t("Subscribe")}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding footer-mid">
                    <div className="container pt-15 pb-20">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="widget-about font-md mb-md-5 mb-lg-0">
                                    <div className="logo logo-width-1 wow  mb-2 fadeIn animated">
                                        <Link to="/">
                                            <img
                                                // src="/assets/footer-logo.png"
                                                src={webSettingsData && webSettingsData.logo && server + "/" + webSettingsData.logo.text_de}
                                                alt={webSettingsData && webSettingsData.logo && webSettingsData.logo['alt_'+i18n.language]}
                                            />
                                      
                                        </Link>
                                    </div>
                                    <p>{webSettingsData && webSettingsData.footer_about_text && webSettingsData.footer_about_text['name_'+i18n.language]}</p>
                                    <div className="mobile-social-icon wow fadeIn animated mb-sm-5 mb-md-0">
                                        {webSettingsData && webSettingsData.social_icons.length > 0 && webSettingsData.social_icons.map((item, i) => (
                                             <Link to={item.link} key={i} target="_blank">
                                                <div dangerouslySetInnerHTML={{ __html: item.name_de }} className="d-inline-block mx-1 mt-2 fs-4" />
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 mt-lg-0 mt-sm-2 mt-2 ">
                                <h5 className="widget-title wow fadeIn animated">
                                    {t("Contact Info")}
                                </h5>
                                <ul className="footer-list wow fadeIn animated">
                                        <li className="wow fadeIn animated">
                                            <strong>{t("Address")}: </strong> {webSettingsData && webSettingsData.address && webSettingsData.address.name_de}
                                        </li>
                                        <li className="wow fadeIn animated">
                                            <Link to={webSettingsData && webSettingsData.phone_number ? "tel:" + webSettingsData.phone_number.name_de : "/"}>
                                            <strong>{t("Phone")}: </strong>  {webSettingsData && webSettingsData.phone_number && webSettingsData.phone_number.name_de}
                                            </Link>
                                        </li>
                                        <li className="wow fadeIn animated">
                                            <strong>{t("Whatsapp")}: </strong>
                                            <ReactWhatsapp number={webSettingsData ? webSettingsData.phone_number.name_en : '+41 79 773 87 12'} style={{ color:'white', backgroundColor: 'transparent', border: 'none' }}>
                                            {webSettingsData && webSettingsData.phone_number && webSettingsData.phone_number.name_en}
                                            </ReactWhatsapp>
                                        </li>
                                        <li className="wow fadeIn animated">
                                            <Link to={webSettingsData && webSettingsData.email ? "mailto:" + webSettingsData.email.name_de : "#"}>
                                            <strong>{t("Email")}: </strong> {webSettingsData && webSettingsData.email && webSettingsData.email.name_de}
                                            </Link>
                                        </li>
                                       
                                    </ul>
                                    
                            </div>
                            <div className="col-lg-3  col-sm-6 mt-lg-0 mt-sm-2 mt-2">
                                <h5 className="widget-title wow fadeIn animated">
                                    {t("Support")}
                                </h5>
                                <ul className="footer-list wow fadeIn animated">
                                    {
                                        footer_bottom_bar.length > 0 &&
                                        footer_bottom_bar.map((item,key) => (
                                            <li key={key}>
                                                <Link to={item.link}>{item['name_'+i18n.language]}</Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="col-lg-3 col-sm-6 mt-lg-0 mt-sm-2 mt-2">
                                <h5 className="widget-title wow fadeIn animated">
                                {t("Our Opening Hours")}
                                </h5>
                                <div className="row">
                                    <div className="col-md-8 col-lg-12">
                                        <p className="wow fadeIn animated">
                                            <strong>{t("Monday")}: </strong>{timing && timing.name_de}
                                        </p>
                                        <p className="wow fadeIn animated">
                                            <strong>{t("Tuesday")}: </strong>{timing && timing.name_en}
                                        </p>
                                        <p className="wow fadeIn animated">
                                            <strong>{t("Wednesday")}: </strong>{timing && timing.name_fr}
                                        </p>
                                        <p className="wow fadeIn animated">
                                            <strong>{t("Thursday")}: </strong>{timing && timing.name_it}
                                        </p>
                                        <p className="wow fadeIn animated">
                                            <strong>{t("Friday")}: </strong>{timing && timing.text_de}
                                        </p>
                                        <p className="wow fadeIn animated">
                                            <strong>{t("Saturday")}: </strong>{timing && timing.text_en}
                                        </p>
                                        <p className="wow fadeIn animated">
                                            <strong>{t("Sunday")}: </strong>{timing && timing.text_fr}
                                        </p>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer-bottom">
                    <div className="container pb-20 wow fadeIn animated">
                        <div className="row">
                            {/* <div className="col-12 mb-20">
                                <div className="footer-bottom"></div>
                            </div> */}
                            <div className="col-lg-12 mt-20">
                                <p className="float-md-left font-sm mb-0">
                                    {t("Copyright")} &copy; {new Date().getFullYear()} {t('All rights reserved to BrandMarkt')}
                                </p>
                            </div>
                            
                        </div>
                    </div>
                </section>
                
            </footer>


            <SupportEngine />
        </>
    );
};

export default Footer;
