import {Link} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from './../elements/Loader';
import {server} from '../../util/server';
import { useTranslation } from 'react-i18next';
import {useFilterContext} from '../../context/filterContext';
import axios from 'axios'

const SingleProduct = ({
    product
}) => {
    const { i18n,t } = useTranslation();
    const {app_currency,isUserLogin,setCartTotalCount,currency_value} = useFilterContext();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handleCart = (product) => {
         let url = server+"/api/add-to-cart"
        axios.post(url, {'product_id':product.id, 'session_id':isUserLogin, 'cart_session_id': sessionStorage.getItem('local_session')}).then(function (result) {
            if(result.data.status === false){
                toast.error(t(result.data.message));
              }else{
                toast.success(t(result.data.message));
                setCartTotalCount(result.data.count);
                sessionStorage.setItem('count', result.data.count);
                if(!sessionStorage.getItem('local_session')){
                  sessionStorage.setItem('local_session', result.data.cart_session_id)
                }
              }
          })
          .catch(function (error) {
                toast.error(t("Unexpected error occurred!"));
          });
    };

    const handleWishlist = (product) => {
        const token = window.sessionStorage.getItem('local_token')

         let url = server+"/api/add-to-wishlist"
            axios({
                method: "post",
                url: url,
                data: {'product_id':product.id},
                headers: { "Content-Type": "multipart/form-data", "Authorization": token ? `Bearer ${token}` : '' },
            }).then(function (result) {
                if(result.data.status === false){
                    toast.error(t(result.data.message));
                }else{
                    toast.success(t(result.data.message));
                }
          })
          .catch(function (error) {
                if(error.response.status === 401){
                        toast.error(t("Please login to continue"));
                }else{
                        toast.error(t("Unexpected error occurred!"));
                }
          });
    };

    return (
        <>

{!loading ?  (
<>
            <div className="product-cart-wrap mb-30" key={product.id}>
                <div className="product-img-action-wrap">
                    <div className="product-img product-img-zoom">
                        <Link
                            to={"/Produkte/"+product.slug}
                        >
                            {
                                product.main_image && 
                                <>
                                    <img
                                        className="default-img"
                                        src={product.main_image && server+"/"+product.main_image.image}
                                        alt={product.main_image['alt_'+i18n.language]}
                                    />
                                    <img
                                        className="hover-img"
                                        src={product.main_image && server+"/"+product.main_image.image}
                                        alt={product.main_image['alt_'+i18n.language]}
                                    />
                                </>
                            }

                            {
                                product.images && product.images.length > 0 && 
                                <>
                                    <img
                                        className="default-img"
                                        src={product.images && server+"/"+product.images[0].image}
                                        alt={product.images[0]['alt_'+i18n.language]}
                                    />
                                    <img
                                        className="hover-img"
                                        src={product.images && server+"/"+product.images[0].image}
                                        alt={product.images[0]['alt_'+i18n.language]}
                                    />
                                </>
                            }
                                
                        </Link>
                    </div>
                    <div className="product-action-1">
                        <Link
                            aria-label={t("Add To Wishlist")}
                            className="action-btn hover-up"
                            onClick={(e) => handleWishlist(product)}
                        >
                            <i className="fi-rs-heart"></i>
                        </Link>
                    </div>

                    <div className="product-badges product-badges-position product-badges-mrg">
                        {product.discount !== 0 ? <span className="hot">{product.discount}{product.discount_type}</span>:null }
                        {/* {product.discount !== 0 ? <span className="sale">Sale</span>:null } */}
                    </div>
                </div>
                <div className="product-content-wrap">
                    <div className="product-category">
                        {/* {product.brand && product.brand.name} */}
                        {product.sku && product.sku}
                    </div>
                    <h2>
                        <Link to={"/Produkte/"+product.slug}>
                        {product['name_'+i18n.language]}
                        </Link>
                    </h2>
                    <div className="rating-result">
                        <Link to="#"><i className={product && 1 <= product.rating ? "fas fa-star me-2 text-danger" : "fas fa-star me-2 bg-star"}></i></Link>
                        <Link to="#"><i className={product && 2 <= product.rating ? "fas fa-star me-2 text-danger" : "fas fa-star me-2 bg-star"}></i></Link>
                        <Link to="#"><i className={product && 3 <= product.rating ? "fas fa-star me-2 text-danger" : "fas fa-star me-2 bg-star"}></i></Link>
                        <Link to="#"><i className={product && 4 <= product.rating ? "fas fa-star me-2 text-danger" : "fas fa-star me-2 bg-star"}></i></Link>
                        <Link to="#"><i className={product && 5 <= product.rating ? "fas fa-star me-2 text-danger" : "fas fa-star me-2 bg-star"}></i></Link>
                        <span>
                            <span>{product.reviews_count && product.reviews_count !== 0 ? "("+product.reviews_count+")" : ''}</span>
                            <span>{!product.reviews_count && product.reviews && product.reviews.length > 0 ? "("+product.reviews.length+")" : ''}</span>
                        </span>
                    </div>
                    <div className="product-price">
                        {product.stock_type === 0 && <span className="text-danger">{t("Sold Out")}</span>}


                        {/* {product.stock_type === 1 &&  product.discount !== 0 ? <><span>{app_currency}{product['price_'+app_currency].toFixed(2)}</span><span className="old-price">{app_currency}{product['original_price_'+app_currency].toFixed(2)}</span></> : ''
                        }
                        {product.stock_type === 1 &&  product.discount === 0 ? <span>{app_currency}{product['price_'+app_currency].toFixed(2)}</span> : ''
                        } */}

                        {product.stock_type === 1 &&  product.discount !== 0 ? <><span>{app_currency}{(product['price_CHF']*currency_value[app_currency]).toFixed(2)}</span><span className="old-price">{app_currency}{(product['original_price_CHF']*currency_value[app_currency]).toFixed(2)}</span></> : ''
                        }
                        {product.stock_type === 1 &&  product.discount === 0 ? <span>{app_currency}{(product['price_CHF']*currency_value[app_currency]).toFixed(2)}</span> : ''
                        }
                    </div>
                    
                    <div className="product-action-1 show">

                        {
                            product.stock_type === 1 && !product.product_variation &&  <Link
                            aria-label={t("Reserve")}
                            className="action-btn hover-up"
                            onClick={(e) => handleCart(product)}
                        >
                            <i className="fi-rs-shopping-bag-add"></i>
                        </Link>
                        }
                       
                    </div>
                   
                </div>
            </div>

            </>
            ):(
                <Loader/>
            )}
        </>
    );
};

export default SingleProduct;
