import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {useFormik} from 'formik';
import {getReviewSchema} from '../../schemas';
import { toast } from "react-toastify";
import {server} from '../../util/server';
import { Link } from "react-router-dom";

const initialValues = {
    review_data : '',
    user_name: '',
    user_email : ''
  }

const ProductTab = ({product}) => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [rating, setRating] = useState({total: 5, selected: 0} )
    const [ratingDiv, setRatingDiv] = useState([] )
    const { i18n,t } = useTranslation();
   
    const handleOnClick = (index) => {
        setActiveIndex(index);
    };

    const submitData = async(query, actions)=>{
        let res = await fetch(server+"/api/store-review",{
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: query,
        })
        let data = await res.json();
        if(data.status === true){
            
            toast.success(t(data.message));
            actions.resetForm();
           
        }else{
            toast.error(t(data.message));
            if(data.errors){
                Object.keys(data.errors).forEach(key => {
                    data.errors[key].map(item => {
                        toast.error(t(item));
                    })
                    
                })
            }
        }
    
    }


    const {values, handleBlur, handleChange, handleSubmit, errors, touched} = useFormik({
        initialValues: initialValues,
        validationSchema: getReviewSchema,
        onSubmit: async(values, actions) => {
            values['review_rating'] = rating.selected ;
            values['product_id'] = product ? product.id : 0;
            submitData(JSON.stringify(values), actions);
        }
      });

      useEffect(() => {
            const ratingRows = [];
            for (let index = 1; index < (rating.total + 1); index++) {
                ratingRows.push(<Link to="#" onClick={() => setRating({total: 5, selected: index})}><i className={index <= rating.selected ? "fas fa-star fs-5 me-2" : "fas fa-star fs-5 me-2 bg-star"}></i></Link>);
            }

            setRatingDiv(ratingRows)
    
        
      }, [rating])
    

    return (
        <>
            <div className="tab-style3">
                <ul className="nav nav-tabs text-uppercase">
                    <li className="nav-item">
                        <a
                            className={
                                activeIndex === 1
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            id="Description-tab"
                            data-bs-toggle="tab"
                            onClick={() => handleOnClick(1)}
                        >
                            {t("Description")}
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={
                                activeIndex === 3
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            id="Reviews-tab"
                            data-bs-toggle="tab"
                            onClick={() => handleOnClick(3)}
                        >
                            {t("Reviews")} ({product && product.reviews && product.reviews.length > 0 ? product.reviews.length : 0})
                        </a>
                    </li>
                </ul>
                <div className="tab-content shop_info_tab entry-main-content">
                    <div
                        className={
                            activeIndex === 1
                                ? "tab-pane fade show active"
                                : "tab-pane fade"
                        }
                        id="Description"
                    >
                        <div className="common-dec-style">
                            {
                                product && <div dangerouslySetInnerHTML={{ __html:product['desc_'+i18n.language] }}/>
                            }
                            
                        </div>
                    </div>
                    
                    <div
                        className={
                            activeIndex === 3
                                ? "tab-pane fade show active"
                                : "tab-pane fade"
                        }
                        id="Reviews"
                    >
                        <div className="comments-area common-dec-style">
                            <div className="row">
                                <div className="col-lg-6 col-md-5">
                                    {/* <h4 className="mb-30">
                                       {t('Reviews for')}
                                    </h4> */}
                                    <div className="comment-list">
                                        {
                                            product && product.reviews && product.reviews.length > 0 && 
                                            product.reviews.map(item => (
                                                <div className="single-comment justify-content-between d-flex">
                                                <div className="user justify-content-between d-flex">
                                                    <div className="thumb text-center">
                                                        <img
                                                            src="/assets/imgs/page/avatar-6.jpg"
                                                            alt=""
                                                        />
                                                        <h6>
                                                            <a href="#">
                                                                {item.user_name}
                                                            </a>
                                                        </h6>
                                                    </div>
                                                    <div className="desc">
                                                        <Link to="#"><i className={1 <= item.review_rating ? "fas fa-star fs-5 me-2 text-danger" : "fas fa-star fs-5 me-2 bg-star"}></i></Link>
                                                        <Link to="#"><i className={2 <= item.review_rating ? "fas fa-star fs-5 me-2 text-danger" : "fas fa-star fs-5 me-2 bg-star"}></i></Link>
                                                        <Link to="#"><i className={3 <= item.review_rating ? "fas fa-star fs-5 me-2 text-danger" : "fas fa-star fs-5 me-2 bg-star"}></i></Link>
                                                        <Link to="#"><i className={4 <= item.review_rating ? "fas fa-star fs-5 me-2 text-danger" : "fas fa-star fs-5 me-2 bg-star"}></i></Link>
                                                        <Link to="#"><i className={5 <= item.review_rating ? "fas fa-star fs-5 me-2 text-danger" : "fas fa-star fs-5 me-2 bg-star"}></i></Link>
                                                        <p>
                                                            {item.review_data}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <p className="font-xs mr-30">
                                                                {item.review_date}
                                                                </p>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ))
                                        }
                                       

                                        

                                      
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-7">
                                    <div className="comment-form">
                                        <h4 className="mb-30">{t("Add a review")}</h4>
                                        <form
                                            className="form-contact comment_form"
                                            method="post"
                                            id="commentForm"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                        {ratingDiv.length > 0 && ratingDiv}
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            className="form-control w-100"
                                                            name="review_data"
                                                            id="comment"
                                                            cols="30"
                                                            rows="9"
                                                            placeholder={t("Write Comment")}
                                                            value={values.review_data} onBlur={handleBlur} onChange={handleChange}
                                                        ></textarea>
                                                        <small className='text-danger fw-bold'>{errors.review_data && touched.review_data && errors.review_data}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            name="user_name"
                                                            id="name"
                                                            type="text"
                                                            placeholder={t("Name")}
                                                            value={values.user_name} onBlur={handleBlur} onChange={handleChange}
                                                        />
                                                        <small className='text-danger fw-bold'>{errors.user_name && touched.user_name && errors.user_name}</small>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            name="user_email"
                                                            id="email"
                                                            type="email"
                                                            placeholder={t("Email")}
                                                            value={values.user_email} onBlur={handleBlur} onChange={handleChange}
                                                        />
                                                        <small className='text-danger fw-bold'>{errors.user_email && touched.user_email && errors.user_email}</small>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                            <div className="form-group">
                                                <button
                                                    type="submit"
                                                    className="button button-contactForm"
                                                >
                                                    {t("Submit Review")}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductTab;
