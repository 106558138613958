import React, {useEffect,useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import SortSelect from "../../components/ecommerce/SortSelect";
import CategoryProduct from "../../components/ecommerce/CategoryProduct";
import SingleProduct from "../../components/ecommerce/SingleProduct";
import {useFilterContext} from '../../context/filterContext';
import PriceRangeSlider from '../../components/ecommerce/PriceRangeSlider';
import ShopFilters from '../../components/ecommerce/ShopFilters';
import RecentlyViewdProducts from "../../components/sliders/RecentlyViewdProducts";
import { useTranslation } from 'react-i18next';
import { Meta } from '../../components/elements/Meta';
import Pagination from 'react-js-pagination';

export default function Search() {
    const { t } = useTranslation();
    const location = useLocation();
    const {filter_products,productCategoryFilter,filter_values,selected_price_range, category_filter, clearFilter, setSearchQuery,updatemetas, setPageNumber} = useFilterContext();
    //pagination
    const [currentItems, setCurrentItems] = useState([]);
    // const [pageCount, setPageCount] = useState(0);
    // const [itemOffset, setItemOffset] = useState(0);
    // let itemsPerPage = 12; 
   
    useEffect(() => {
        setSearchQuery(location.search)
        setPageNumber(1)
    },[location.search]);

    useEffect(() => {
        updatemetas('search',4)
        setPageNumber(1)
    },[]);

      // pagination
      useEffect(() => {
        // const endOffset = itemOffset + itemsPerPage;
        //current paginate products
        setCurrentItems(filter_products.data);
        // setPageCount(Math.ceil(filter_products.length / itemsPerPage));
    }, [filter_products.data]);

     // Invoke when user click to request another page.
    //  const handlePageClick = (event) => {
    //     const newOffset = event.selected * itemsPerPage % filter_products.length;
    //     setItemOffset(newOffset);
    //   };

    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                        {t("Home")}
                        </Link>
                        <span></span> {t("Search")}
                    </div>
                </div>
            </div>
            <section className="mt-50 mb-50">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-9">
                            <div className="shop-product-fillter">
                                <div className="totall-product">
                                    <p>
                                        <span className="badge bg-light text-dark rounded-pill productCount">
                                            {filter_products.total}      {t("Products")}
                                        </span>
                                   
                                        {
                                            filter_values.length > 0 || selected_price_range || category_filter ? <Link to="#" onClick={(e) => clearFilter('brand')} className="ms-2 clearFilter fw-bold badge rounded-pill bg-dark text-white">{t("Clear Filter")}</Link> : ''
                                        } 
                                    </p>
                                </div>
                                <div className="sort-by-product-area">
                                    <div className="sort-by-cover">
                                        <SortSelect />
                                    </div>
                                </div>
                            </div>
                            <div className="row product-grid-3">
                                {
                                    currentItems.length === 0 && <h3>{t("No Products Found")} </h3>
                                }

                                {currentItems.length > 0 && currentItems.map((item, index) => (
                                    <div key={index} className="col-lg-4 col-md-4 col-12 col-sm-6" >
                                        <SingleProduct product={item} />
                                    </div>
                                ))}
                                    
                                
                            </div>
                            {currentItems.length !== 0 &&
                                <div className="col-12 mt-5">
                                    <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                                        <nav aria-label="Page navigation example">
                                            <Pagination
                                                totalItemsCount={filter_products && filter_products.total}
                                                activePage={filter_products && filter_products.current_page}
                                                itemsCountPerPage={filter_products && filter_products.per_page}
                                                onChange={(pageNumber) => { setPageNumber(pageNumber) }}
                                                itemClass='page-item'
                                                linkClass='page-link'
                                                activeClass='active'
                                            />
                                        </nav>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="col-lg-3 primary-sidebar sticky-sidebar">

                            <div className="widget-category mb-30">
                               
                                <CategoryProduct productCategoryFilter={productCategoryFilter} />
                            </div>
                            
                            <ShopFilters />
                         

                           
                            <PriceRangeSlider filter_products={filter_products.data} />

                            
                        </div>
                    </div>

                    <RecentlyViewdProducts />
                </div>
            </section>
        </>
    )
}
