import React,{useEffect, useState} from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { server } from '../../util/server'
import axios from 'axios'
import { toast } from 'react-toastify'
import {useFilterContext} from '../../context/filterContext';
import PriceRangeSlider from '../../components/ecommerce/PriceRangeSlider';
import ShopFilters from '../../components/ecommerce/ShopFilters';
import RecentlyViewdProducts from "../../components/sliders/RecentlyViewdProducts";
import SingleProduct from "../../components/ecommerce/SingleProduct";
import SortSelect from "../../components/ecommerce/SortSelect";
import BrandFilter from '../../components/ecommerce/BrandFilter';
import DynamicPage from '../DynamicPage'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import VendorFilter from '../../components/ecommerce/VendorFilter';
import { Meta } from '../../components/elements/Meta'
import Pagination from "react-js-pagination";
import Spinner from '../../components/Spinner/Main'

export default function Category() {

    const {maincategory,subcategory=null,childcategory=null} = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState([])
    const [pageType, setPageType] = useState([])
    const [spinner, setSpinner] = useState(true);
    const navigate = useNavigate();
    const { filter_products,filter_values,selected_price_range, brand_value, clearFilter, productBrandHandler,vendorValue,ShopInitalLoadHandler,updatemetas, setPageNumber} = useFilterContext();
     //pagination
     const [currentItems, setCurrentItems] = useState([]);
    //  const [pageCount, setPageCount] = useState(0);
    //  const [itemOffset, setItemOffset] = useState(0);
    //  let itemsPerPage = 12; 

    const getcategoryData = async() => {
        try {
            let url = server+"/api/"+maincategory
            if(childcategory && subcategory){
                url = server+"/api/"+maincategory+'/'+subcategory+"/"+childcategory
            }
            else if(!childcategory && subcategory){
                url = server+"/api/"+maincategory+'/'+subcategory
            }
           
            let res = await axios.get(url);
            let data = await res.data;
            if(data.status === true){
                setData(data.data)
                setPageType(data.type)
                updatemetas(null,0,data.meta)
                if(data.type === 'category'){
                    ShopInitalLoadHandler({'type':'category', 'value':data.category_id});
                }
                setSpinner(false)
            
            }else{
                navigate("/404");
                setSpinner(false)
            }
        
           
        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))

        }
    }

    useEffect(() => {
        getcategoryData()
        setPageNumber(1)
    },[maincategory,subcategory,childcategory])

      // pagination
      useEffect(() => {
        // const endOffset = itemOffset + itemsPerPage;
        //current paginate products
        setCurrentItems(filter_products.data);
        // setPageCount(Math.ceil(filter_products.data.length / itemsPerPage));
    }, [filter_products.data]);

     // Invoke when user click to request another page.
    //  const handlePageClick = (event) => {
    //     const newOffset = event.selected * itemsPerPage % filter_products.length;
    //     setItemOffset(newOffset);
    //   };

  
  return (
    <>
        <Meta />
        {spinner ? <Spinner /> :

        <>
        {
            pageType === 'page' &&
            <DynamicPage data={data} />
        }
        {
            pageType === 'category' &&
            <>
            
                <div className={`page-header breadcrumb-wrap`}>
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to="/">
                            {t("Home")}
                            </Link>
                            <Breadcrumb maincategory={maincategory} subcategory={subcategory} childcategory={childcategory} data={data} />
                            
                            
                        </div>
                    </div>
                </div>

                <section className="mt-50 mb-50">
                        <div className="container">
                            <div className="row flex-row-reverse">
                                <div className="col-lg-9">
                                    <div className="shop-product-fillter">
                                        <div className="totall-product">
                                            <p>
                                                <span className="badge bg-light text-dark rounded-pill productCount">
                                                    {filter_products.total}      {t("Products")}
                                                </span>
                                        
                                                {
                                                    filter_values.length > 0 || selected_price_range || brand_value || vendorValue ? <Link to="#" onClick={(e) => clearFilter('category')} className="ms-2 clearFilter fw-bold badge rounded-pill bg-dark text-white">Clear Filter</Link> : ''
                                                } 
                                            </p>
                                        </div>
                                        <div className="sort-by-product-area">
                                            <div className="sort-by-cover">
                                                <SortSelect />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row product-grid-3">
                                        {
                                            currentItems.length === 0 && <h3>{t("No Products Found")}</h3>
                                        }

                                        {currentItems.length > 0 && currentItems.map((item, i) => (
                                            <div
                                                className="col-lg-4 col-md-4 col-12 col-sm-6"
                                                key={i}
                                            >
                                                <SingleProduct product={item} />
                                            </div>
                                        ))}
                                            
                                        
                                    </div>


                                    {currentItems.length !== 0 &&
                                        <div className="col-12 mt-5">
                                        <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                                            <nav aria-label="Page navigation example">
                                                <Pagination
                                                    totalItemsCount={filter_products && filter_products.total}
                                                    activePage={filter_products && filter_products.current_page}
                                                    itemsCountPerPage={filter_products && filter_products.per_page}
                                                    onChange={(pageNumber) => { setPageNumber(pageNumber) }}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    activeClass='active'
                                                />
                                            </nav>
                                        </div>
                                    </div>
                                    }
                                    


                                </div>
                                <div className="col-lg-3 primary-sidebar sticky-sidebar">
                                <CategoryComp maincategory={maincategory} subcategory={subcategory} childcategory={childcategory} data={data} />
                                    {/* <BrandFilter productBrandHandler={productBrandHandler} filter_products={filter_products.data} /> */}
                                    <VendorFilter />
                                    <ShopFilters />
                                
                                    <PriceRangeSlider filter_products={filter_products.data} />

                                    
                                </div>
                            </div>

                            <RecentlyViewdProducts />
                        </div>
                </section>
            </>
        }
        </>
        
        }
    
    </>
  )
}

function Breadcrumb({maincategory,subcategory,childcategory,data}) {
    if(childcategory && subcategory){
        return (
            <>
                <Link to={"/"+maincategory}>
                            <span></span>   {data && data.main_category && data.main_category['name_'+ i18next.language ]}
                </Link>
                <Link to={"/"+maincategory+"/"+subcategory}>
                            <span></span>   {data && data.sub_category && data.sub_category['name_'+i18next.language]}
                </Link>
                <span></span> {data && data.child_category && data.child_category['name_'+i18next.language]}
            </>
        )
    }else if(!childcategory && subcategory) {
        return (
            <>
                <Link to={"/"+maincategory}>
                            <span></span>   {data && data.main_category && data.main_category['name_'+i18next.language]}
                </Link>
                <span></span> {data && data.sub_category && data.sub_category['name_'+i18next.language]}
            </>
        )
    }else{
        return (
            <>
               <span></span>   {data && data.main_category && data.main_category['name_'+i18next.language]}
            </>
        )
    }
}

function CategoryComp({maincategory,subcategory,childcategory,data}) {
    if(!childcategory && subcategory && data.sub_category && data.sub_category.subcategory.length > 0) {
        return (
            <div className="widget-category mb-30">
                            
                <h5 className="section-title style-1 mb-30 wow fadeIn animated">
                    Filter By Category
                </h5>
                <ul className="categories">
                    {data &&
                        data.sub_category.subcategory.map((item, index) => (
                            <li key={index}><Link to={"/"+maincategory+"/"+subcategory+"/"+item.slug} >{item['name_'+ i18next.language ]}</Link></li>
                        ))
                    }
                </ul>         
            </div>
        )
    }else if(!childcategory && !subcategory && data.main_category && data.main_category.subcategory.length > 0){
        return (
            <div className="widget-category mb-30">
                            
                <h5 className="section-title style-1 mb-30 wow fadeIn animated">
                    Filter By Category
                </h5>
                <ul className="categories">
                    {data &&
                        data.main_category.subcategory.map((item, index) => (
                            <>
                            <li key={index}><Link to={"/"+maincategory+"/"+item.slug} >{item['name_'+i18next.language]}</Link></li>
                            {
                                item.subcategory.length > 0 &&  item.subcategory.map((childitem, key) => (
                                    <li className="ms-5" key={key}><Link to={"/"+maincategory+"/"+subcategory+"/"+childitem.slug} >{childitem['name_'+i18next.language]}</Link></li>
                                ))
                            }
                            </>
                        ))
                    }
                </ul>         
            </div>
        )
    }
}
