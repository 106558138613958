import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SingleProduct from "./../ecommerce/SingleProduct";
import {useFilterContext} from '../../context/filterContext';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation]);

const RecentlyViewdProducts = () => {
    const { t } = useTranslation();
    const {recentlyViewedProducts} = useFilterContext();
    return (
        <>
        {
           recentlyViewedProducts.length > 0 &&  
           <div className="row mt-60">
                    <div className="col-12">
                        <h3 className="section-title style-1 mb-30">
                            {t("Recently Viewed products")}
                        </h3>
                    </div>
                    <div className="col-12">
                        <div className="row related-products position-relative">

                            <Swiper
                                slidesPerView={4}
                                spaceBetween={30}
                                //loop={false}
                                navigation={{
                                    prevEl: ".custom_prev_n",
                                    nextEl: ".custom_next_n",
                                }}
                                className="custom-class"
                            >
                                {recentlyViewedProducts.length > 0 && recentlyViewedProducts.map((product, index) => (
                                    <SwiperSlide key={index}>
                                        <SingleProduct product={product} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div
                                className="slider-arrow slider-arrow-2 carausel-6-columns-arrow"
                            >
                                <span className="slider-btn slider-prev slick-arrow custom_prev_n">
                                    <i className="fi-rs-angle-left"></i>
                                </span>
                                <span className="slider-btn slider-next slick-arrow custom_next_n">
                                    <i className="fi-rs-angle-right"></i>
                                </span>
                            </div>

                        </div>
                    </div>
            </div>
        }
            
            
        </>
    );
};

export default RecentlyViewdProducts;
