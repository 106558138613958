import {useFilterContext} from '../../context/filterContext';
import {Link} from 'react-router-dom'
import { useEffect,useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useTranslation } from 'react-i18next';
const VendorFilter = () => {
    const {product_vendors,filter_products,productVendorHandler,vendorValue} = useFilterContext();
    const { t } = useTranslation();

   
    return (
        <>
        {
            filter_products.data.length > 0 && 
            <div className="widget-category mb-30">
                <h5 className="section-title style-1 mb-30 wow fadeIn animated" >
                    {t("Filter By Vendors")} 
                </h5>
                    <ul className="categories">
                        {product_vendors &&
                            Object.entries(product_vendors).map(([curElem, index], key) => (
                            <li key={index}><Link to="#" onClick={(e) => productVendorHandler(index)} className={vendorValue && vendorValue === index ? "shop-active" : ''}>{curElem}</Link></li>
                        ))}
                    </ul>
            </div>
        }
        
     </>
    );
};



export default VendorFilter;
