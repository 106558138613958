import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { server } from '../util/server';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useFilterContext } from '../context/filterContext';
import { toast } from 'react-toastify';
import { Meta } from "../components/elements/Meta";
import Spinner from '../components/Spinner/Main';
function About() {
    const [data, setData] = useState();
    const { i18n, t } = useTranslation();
    const { updatemetas } = useFilterContext();
    const [spinner, setSpinner] = useState(true);

    const aboutUsdata = async () => {
        try {
            let url = server + "/api/aboutpage-data"
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === true) {
                setData(data.data)
                updatemetas(null, 0, data.meta)
                setSpinner(false)
            } else {
                toast.error(t(data.message))
                setSpinner(false)
            }

        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        aboutUsdata()
    }, []);


    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <span></span> {t("About Us")}
                    </div>
                </div>
            </div>
            {spinner ? <Spinner /> :
                <>
                    <section className="section-padding">
                        <div className="container pt-25">
                            <div className="row">
                                <div className="col-lg-6 align-self-center mb-lg-0 mb-4">
                                    {/* <h6 className="mt-0 mb-15 text-uppercase font-sm text-brand wow fadeIn animated">
                                    Our Company
                                </h6> */}
                                    <h1 className="font-heading mb-40">
                                        <span className='text-danger '>{t("About")} </span>
                                        {t("Us")}
                                    </h1>
                                    <p className='m-auto text-grey-3 wow fadeIn animated about-us-text'>
                                        {
                                            data && data['about_section_1'][0]['text_' + i18n.language]
                                        }
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <img
                                        src={data && server + "/" + data['about_section_1'][0]['name_de']}
                                        alt={data && data['about_section_1'][0]['alt_' + i18n.language]}
                                        height={372}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="work" className="mt-40 pt-50 pb-50 section-border">
                        <div className="container">
                            <div className="row mb-50">
                                <div className="col-lg-12 col-md-12 text-center">
                                    <h2 className="mb-15 text-grey-1 wow fadeIn animated">
                                        {data && data['about_section_2'][0]['name_' + i18n.language]}
                                    </h2>
                                    <p className="w-50 m-auto text-grey-3 wow fadeIn animated">
                                        {data && data['about_section_2'][0]['text_' + i18n.language]}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    data && data['about_section_3'].length > 0 &&
                                    data['about_section_3'].map(item => (
                                        <div className="col-md-4 text-center mb-md-0 mb-4" key={item.name_en}>
                                            <div dangerouslySetInnerHTML={{ __html: item['link'] }} className="d-inline-block fs-1 rounded-circle about-us-choose" />
                                            <h4 className="mt-30 mb-15 wow fadeIn animated">
                                                {item['name_' + i18n.language]}
                                            </h4>
                                            <p className="text-grey-3 wow fadeIn animated">
                                                {item['text_' + i18n.language]}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    );
}

export default About;
