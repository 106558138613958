import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {useFilterContext} from '../../context/filterContext';
export const Meta = () => {
    const { i18n} = useTranslation();
    const {meta} = useFilterContext();
  return (
        <Helmet>
            <title>{meta && meta['meta_title_'+i18n.language] ? meta['meta_title_'+i18n.language] : 'Brandmarkt'}</title>
            <meta name="description" content={meta && meta['meta_description_'+i18n.language]} />
            <meta name="keywords" content={meta && meta['meta_keywords_'+i18n.language]} />
            {
              meta && meta['robots'] && <meta name="robots" content={meta['robots']} />
            }

            {
              meta && meta['canonical_url'] && <link rel="canonical" href={meta['canonical_url']} />
            }
            
        </Helmet>
  )
}
