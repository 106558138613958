import {Link, useNavigate} from 'react-router-dom'; 
import { useState, useEffect } from 'react';
import {checkoutSchema} from '../schemas';
import {useFormik} from 'formik';
import { server } from '../util/server';
import {useFilterContext} from '../context/filterContext';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import axios from "axios"
import { Modal } from "react-bootstrap";
import { format } from 'date-fns';
import { Meta } from '../components/elements/Meta';
const Checkout = () => {
    const { i18n,t } = useTranslation();
    const {app_currency,setCartTotalCount,coupon,unsetOrderData,updatemetas,currency_value} = useFilterContext();
    const navigate = useNavigate();
    const [cart, setCart] = useState([]);
    const [orderData, setOrderData] = useState(null);
    const [user, setUser] = useState(null);
    const [cartTotal, setCartTotal] = useState(0);
    const [preDiscountcartTotal, setpreDiscountCartTotal] = useState(0);
    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const token = window.sessionStorage.getItem('local_token')

        const getCartItems = async () => {
            const data = await fetch(server+`/api/cart?session_id=${sessionStorage.getItem('local_session')}`);
            const response = await data.json();
            if(response.data.cart.length === 0){
                navigate('/shop');
            }
            setCart(response.data.cart)
            setCartTotal(response.data['cart_total_CHF'])
            setpreDiscountCartTotal(response.data['pre_discount_total'])
        }

        const getUserData = async () => {
            let url = server+"/api/user"
            axios({
                method: "get",
                url: url,
                headers: { "Content-Type": "multipart/form-data", "Authorization": token ? `Bearer ${token}` : '' },
            }).then(function (result) {
                setUser(result.data)
            })
            .catch(function (error) {
                if(error.response.status === 401){
                    toast.error(t("Please login to continue"));
                }else{
                    toast.error(t("Unexpected error occurred!"));
                }
            });
        }

    useEffect(() => {
        getCartItems();
        if(token){
            getUserData();
        }

        updatemetas('checkout',4)
       
    },[]);


    const submitData = async(query, actions)=>{
        query['session_id'] = sessionStorage.getItem('local_session');
        query['user_id'] = user ? user.id : '';
        query['currency'] = app_currency;
        query['discount'] = coupon ? coupon['CHF'] : null;
        query['coupon'] = coupon ? coupon['coupon_code'] : null;
        query['currency_rate'] = currency_value[app_currency];
        let res = await fetch(server+"/api/checkout",{
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(query),
        })
        let result = await res.json()
        if(result.status === true){
            // toast.success(result.message);
            setCartTotalCount(0);
            unsetOrderData();
            setOrderData(result.order);
            actions.resetForm();
            setCart([])
            setCartTotal(0)
            setShow(true)
            // navigate('/thank-you', { state: {'order': result.order }});
        }
    
      }
    const initialValues = {
        first_name : user ? user.first_name : '',
        last_name: user ? user.last_name : '',
        country : user ? user.country : 'Switzerland',
        city: user ? user.city : '',
        zip_code: user ? user.post_code : '',
        phone_number: user ? user.phone_number : '',
        email: user ? user.email : '',
        order_note:'',
        order_type:0
    };
    const {values, handleBlur, handleChange, handleSubmit, errors, touched} = useFormik({
        //can remove the value variable in case both have same name
        initialValues,
         validationSchema: checkoutSchema,
           enableReinitialize: true,
         onSubmit: async(values, actions) => {
            document.getElementById("reserve-btn").disabled = true;
            submitData(values, actions);
          
         }
      });

     

    return (
        <>
            <Meta />
          <div className={`page-header breadcrumb-wrap`}>
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to="/">
                               {t("Home")}
                            </Link>
                            <Link to="/warenkorb">
                            <span></span>  {t("Cart")}
                            </Link>
                            <span></span> {t("Checkout")}
                        </div>
                    </div>
                </div>

                <section className="mt-50 mb-50">
                    <div className="container">
                    <form method="post" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-25">
                                    <h4>{t("Billing Details")}</h4>
                                </div>
                               

                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <label>{t("First Name")}</label>
                                            <input type="text" className="form-control" name="first_name"  value={values.first_name} onBlur={handleBlur} onChange={handleChange} />
                                            <small className='text-danger fw-bold'>{errors.first_name && touched.first_name && errors.first_name}</small>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <label>{t("Last Name")}</label>
                                            <input type="text" className="form-control" name="last_name"  value={values.last_name} onBlur={handleBlur} onChange={handleChange} />
                                            <small className='text-danger fw-bold'>{errors.last_name && touched.last_name && errors.last_name}</small>
                                        </div>
                                    </div>
            
                                    <div className="col-lg-12">
                                        <div className="form-group mb-4">
                                            <label>{t("Country")}</label>
                                            <select className="form-control" name='country'  value={values.country} onBlur={handleBlur} onChange={handleChange} >
                                                <option value="Switzerland">Switzerland</option>
                                            </select>
                                            <small className='text-danger fw-bold'>{errors.country && touched.country && errors.country}</small>
                                        </div>
                                    </div>
            
                                    {/* <div className="col-lg-12">
                                        <div className="form-group mb-4">
                                            <label>House Number & Street Address</label>
                                            <input type="text" className="form-control" name="street_address"  value={values.street_address} onBlur={handleBlur} onChange={handleChange} />
                                            <small className='text-danger fw-bold'>{errors.street_address && touched.street_address && errors.street_address}</small>
                                        </div>
                                    </div> */}
            
                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <label>{t("Town / City")} </label>
                                            <input type="text" className="form-control" name="city"  value={values.city} onBlur={handleBlur} onChange={handleChange} />
                                            <small className='text-danger fw-bold'>{errors.city && touched.city && errors.city}</small>
                                        </div>
                                    </div>
            
                                    
                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <label>{t("Postcode / ZIP (optional)")}</label>
                                            <input type="text" className="form-control" name="zip_code"  value={values.zip_code} onBlur={handleBlur} onChange={handleChange} />
                                            <small className='text-danger fw-bold'>{errors.zip_code && touched.zip_code && errors.zip_code}</small>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <label>{t("Phone")} </label>
                                            <input type="text" className="form-control" name="phone_number"  value={values.phone_number} onBlur={handleBlur} onChange={handleChange} />
                                            <small className='text-danger fw-bold'>{errors.phone_number && touched.phone_number && errors.phone_number}</small>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <label>{t("Email address")} </label>
                                            <input type="email"  className="form-control" name="email"  defaultValue={values.email} onBlur={handleBlur} onChange={handleChange} />
                                            <small className='text-danger fw-bold'>{errors.email && touched.email && errors.email}</small>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group mb-4">
                                            <label>{t("Order Note")} </label>
                                            <textarea className="form-control" name="order_note" rows={10} defaultValue={values.order_note} onBlur={handleBlur} onChange={handleChange}></textarea>
                                            <small className='text-danger fw-bold'>{errors.order_note && touched.order_note && errors.order_note}</small>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                    {/* <div className="payment_method">
                                        <div className="payment_option">
                                            <div className="custome-radio d-inline-block">
                                                <input className="form-check-input" required="" type="radio" name="order_type" id="onlineOrder"  value="1" defaultValue={values.order_type === 1} onBlur={handleBlur} onChange={handleChange} />
                                                <label className="form-check-label" htmlFor="onlineOrder" >
                                                    {t("Order Online")}
                                                </label>
                                            </div>
                                            <div className="custome-radio d-inline-block ps-3">
                                                <input className="form-check-input" required="" type="radio" name="order_type" id="orderreservation" value="0" defaultValue={values.order_type === 0} onBlur={handleBlur} onChange={handleChange} />
                                                <label className="form-check-label" htmlFor="orderreservation">
                                                    {t("Reserve Order")}
                                                </label>
                                            </div>
                                        </div>

                                        <small className='text-danger fw-bold'>{errors.order_type && touched.order_type && errors.order_type}</small>
                                    </div> */}
                                    </div>
            
                                
                                </div>


                               
                            </div>
                            <div className="col-md-6">
                                <div className="order_review">
                                    <div className="mb-20">
                                        <h4>{t("Order Details")}</h4>
                                    </div>
                                    <div className="table-responsive order_table text-center">
                                        {cart.length <= 0 && "No Products"}
                                        <table className={cart.length > 0 ? "table" : "d-none"}>
                                            <thead>
                                                <tr>
                                                    <th colSpan="2">{t("Product")}</th>
                                                    <th>{t("Total")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart.length > 0 && cart.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="image product-thumbnail">
                                                                {
                                                                    item.cart_product && item.cart_product.main_image && 
                                                                    <img src={server+'/'+item.cart_product.main_image.image} alt={item.cart_product.main_image.alt_en}/>
                                                                }
                                                            
                                                            </td>
                                                            <td>
                                                                <h5>
                                                                    {item.cart_product['name_'+i18n.language]}
                                                                </h5>
                                                                <p className="fst-italic font-13 line-16">SKU: {item.sku}</p>
                                                                {
                                                                    item.var_id &&
                                                                    <p className="fst-italic font-13 line-16">{item.var_data}</p>
                                                                }
                                                                <p className="fst-italic font-13 line-16" >Qty: {item.quantity}</p>
                                                            </td>
                                                            <td>
                                                            {item.coupon_discount != 0 ? <del className="d-block">{app_currency}{((item['coupon_discount']*currency_value[app_currency] + item['price_CHF']*currency_value[app_currency]) * item.quantity).toFixed(2)}</del> : ''}
                                                            {item['price_CHF'] !== item['actual_price_CHF'] && item.coupon_discount == 0 ? <del className="d-block">{app_currency}{(item['actual_price_CHF'] * currency_value[app_currency] * item.quantity).toFixed(2)}</del> : ''}
                                                            {app_currency} { (item['price_CHF'] * currency_value[app_currency] * item.quantity).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                ))}
                                                {/* <tr>
                                                    <th>{t("SubTotal")}</th>
                                                    <td className="product-subtotal" colSpan="2">
                                                    {app_currency}{(cartTotal * currency_value[app_currency]).toFixed(2) }
                                                    </td>
                                                </tr> */}
                                                <tr>
                                                    <th>{t("Shipping")}</th>
                                                    <td colSpan="2">
                                                        <em>{t("Store Pickup")}</em>
                                                    </td>
                                                </tr>
                                                {
                                                    coupon && 
                                                    <tr>
                                                        <td>{t("Discount")}</td>
                                                        <td className="product-subtotal" colSpan="2">
                                                        {coupon['coupon_code']}
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th>{t("Total")}</th>
                                                    <td colSpan="2" className="product-subtotal">
                                                        <span className="font-xl text-brand fw-900">
                                                        {
                                                            coupon && <del className="me-3">{app_currency} {(preDiscountcartTotal *currency_value[app_currency]).toFixed(2)}</del>
                                                        }
                                                            {app_currency}{(cartTotal *currency_value[app_currency]).toFixed(2)}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                                    <button type="submit" id="reserve-btn" className="btn btn-fill-out btn-block mt-30">
                                        {t("Reserve Order")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </section>




                <Modal show={showModal} onHide={() => handleClose()} size="lg">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12  text-center">
                                <h2 className="mb-15 text-brand">{t("Thank You For Reservation")}</h2>
                                <p className='mb-0'><b className='fw-bold'>Reservation Number: </b>{orderData && orderData.order_number}</p>
                                <p className='mb-0'><b className='fw-bold'>Date: </b>{orderData && format(new Date(orderData.created_at), 'do  MMMM yyyy')}</p>
                                <p className='mb-0'><b className='fw-bold'>Total: </b>{orderData && orderData.currency} {orderData && orderData.total.toFixed(2)}</p>
                                <Link to="/shop" className="btn btn-fill-out btn-block mt-30 mb-30">
                                        {t("Continue Shopping")}
                                    </Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};


export default Checkout;
