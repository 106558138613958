import { Link } from "react-router-dom"
import { useFilterContext } from '../context/filterContext';
import { server } from '../util/server';
import { useFormik } from 'formik';
import { contactSchema } from '../schemas';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { Meta } from "../components/elements/Meta";
const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: ''
}
function Contact() {
    const { webSettingsData, updatemetas } = useFilterContext();
    const { t } = useTranslation();
    const submitData = async (query, actions) => {
        let res = await fetch(server + "/api/contact-query", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: query,
        })
        let data = await res.json();
        if (data.status === true) {
            toast.success(t(data.message));
            actions.resetForm();
        } else {
            toast.error(t(data.message));
        }

    }

    useEffect(() => {
        updatemetas('kontact-uns', 4)
    }, [])


    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, actions) => {
            submitData(JSON.stringify(values), actions);
        }
    });
    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <span></span> {t("Contact Us")}
                    </div>
                </div>
            </div>

            <section className="section-border pt-50 pb-50">
                <div className="container">
                    <main>
                        <div className="infographic">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 mb-md-0 mb-30 infographic__grid__item">
                                    <img
                                        src="https://www.desertfinancial.com/-/media/desert-financial/lc-test/infographics/infographic__benefitsofvolunteering_3.ashx?la=en&amp;hash=C7857E1920E916D93748F255CFD8D4BCFFA170B7"
                                        className="infographic__grid__item__img" alt="" />
                                    <h2 className="infographic__grid__item__title title-ltblue mt-3">{t("Address")}</h2>
                                    <p className="infographic__grid__item__p">{webSettingsData && webSettingsData.address && webSettingsData.address.name_de}</p>
                                </div>
                                <div className="col-md-4 col-sm-6 mb-md-0 mb-30 infographic__grid__item">
                                <img
                                        src="https://www.desertfinancial.com/-/media/desert-financial/lc-test/infographics/infographic__benefitsofvolunteering_3.ashx?la=en&amp;hash=C7857E1920E916D93748F255CFD8D4BCFFA170B7"
                                        className="infographic__grid__item__img" alt="" />
                                    <h2 className="infographic__grid__item__title title-ltblue mt-3">{t("Email")}</h2>
                                    <p className="infographic__grid__item__p">{webSettingsData && webSettingsData.email && webSettingsData.email.name_de}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  m-auto infographic__grid__item">
                                <img
                                        src="https://www.desertfinancial.com/-/media/desert-financial/lc-test/infographics/infographic__benefitsofvolunteering_3.ashx?la=en&amp;hash=C7857E1920E916D93748F255CFD8D4BCFFA170B7"
                                        className="infographic__grid__item__img" alt="" />
                                    <h2 className="infographic__grid__item__title title-ltblue mt-3">{t("Phone")}</h2>
                                    <p className="infographic__grid__item__p">{webSettingsData && webSettingsData.phone_number && webSettingsData.phone_number.name_de} /  {webSettingsData && webSettingsData.phone_number && webSettingsData.phone_number.name_en}</p>
                                </div>

                            </div>
                        </div>
                    </main>
                    
                </div>
            </section>
            <section className="pt-50 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-7 col-md-6 col-12">
                            <div className="contact-from-area wow FadeInUp">
                                <h3 className="mb-10 text-center">
                                    {t("Get In Touch")}
                                </h3>
                                <form
                                    className="contact-form-style text-center"
                                    id="contact-form"
                                    method="post" onSubmit={handleSubmit}
                                >
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="input-style mb-20">
                                                <input
                                                    name="first_name"
                                                    placeholder={t("First Name")}
                                                    type="text"
                                                    value={values.first_name} onBlur={handleBlur} onChange={handleChange} />
                                                <small className='text-danger fw-bold'>{errors.first_name && touched.first_name && errors.first_name}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="input-style mb-20">
                                                <input
                                                    name="last_name"
                                                    placeholder={t("Last Name")}
                                                    type="text"
                                                    value={values.last_name} onBlur={handleBlur} onChange={handleChange} />
                                                <small className='text-danger fw-bold'>{errors.last_name && touched.last_name && errors.last_name}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="input-style mb-20">
                                                <input
                                                    name="email"
                                                    placeholder={t("Email")}
                                                    type="email"
                                                    value={values.email} onBlur={handleBlur} onChange={handleChange} />
                                                <small className='text-danger fw-bold'>{errors.email && touched.email && errors.email}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="input-style mb-20">
                                                <input
                                                    name="phone"
                                                    placeholder={t("Phone Number")}
                                                    type="tel"
                                                    value={values.phone} onBlur={handleBlur} onChange={handleChange} maxLength="20" />
                                                <small className='text-danger fw-bold'>{errors.phone && touched.phone && errors.phone}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="textarea-style mb-30">
                                                <textarea
                                                    name="message"
                                                    placeholder={t("Your Message")}
                                                    value={values.message} onBlur={handleBlur} onChange={handleChange}
                                                ></textarea>
                                                <small className='text-danger fw-bold'>{errors.message && touched.message && errors.message}</small>
                                            </div>
                                            <button
                                                className="submit submit-auto-width"
                                                type="submit"
                                            >
                                                {t("Submit")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <p className="form-messege"></p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-6 col-12 mt-md-0 mt-50" id="contact-address">
                            {
                                webSettingsData && webSettingsData.map && <div dangerouslySetInnerHTML={{ __html: webSettingsData.map.text_de }} />
                            }

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
