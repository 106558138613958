import React from 'react'
import { useFormik } from 'formik';
import { commentSchema } from '../../schemas';
import { toast } from "react-toastify";
import { server } from '../../util/server';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { format } from 'date-fns';

const initialValues = {
    email: '',
    name: '',
    website: '',
    comment: ''
}
export default function BlogComments({singleBlog}) {
    const { t } = useTranslation();
    const[blogComments, setBlogComments] = useState(singleBlog && singleBlog.blog_comments ? singleBlog.blog_comments : []);
    const submitSignupData = async (query, actions) => {
        query['blog_id'] = singleBlog.blog_id
        query['slug'] = singleBlog.slug
        try {
            let res = await axios.post(server + "/api/submit-blog-comment", query);
            let data = await res.data;
            if (data.status === false) {
                if (data.message) { toast.error(t(data.message)) }

                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(t(item));
                        })

                    })
                }
            } else {
                toast.success(t(data.message));
                actions.resetForm();
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }

    }
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: commentSchema,
        onSubmit: async (values, actions) => {
            submitSignupData(values, actions);
        }
    });

  return (
    <>
    <div className="comments-area">
                    <div className="row">
                        <div className="col-md-10">
                            <h4 className="mb-30">{t("Comments")}</h4>
                            <div className="comment-list">
                                {
                                    blogComments && blogComments.length > 0 && 
                                    blogComments.map(singleComment => (
                                        <div className="single-comment justify-content-between d-flex">
                                            <div className="user justify-content-between d-flex">
                                                <div className="thumb text-center">
                                                    <img
                                                        src="/assets/imgs/page/avatar-6.jpg"
                                                        alt="blog-image"
                                                        style={{ width:'150px' }}
                                                    />
                                                    
                                                </div>
                                                <div className="desc">
                                                    <p>
                                                       {singleComment.comment}
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <p className="font-xs mr-30">
                                                        
                                                        <a href="#">{singleComment.name}</a> - { format(new Date(singleComment.created_at), 'do  MMMM yyyy')}
                                                            </p>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                
                             
                                {/* <!--single-comment --> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comment-form">
                    <h4 className="mb-15">{t("Leave a Comment")}</h4>
                    <p>{t("Your email address will not be published. Required fields are marked *")}</p>
                    
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <form
                                className="form-contact comment_form"
                                id="commentForm"
                                onSubmit={handleSubmit}
                                method='post'
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea
                                                className="form-control w-100"
                                                name="comment"
                                                id="comment"
                                                cols="30"
                                                rows="9"
                                                placeholder={t('Write Comment')}
                                                value={values.comment} onBlur={handleBlur} onChange={handleChange}
                                            ></textarea>
                                            
                                        <small className='text-danger fw-bold'>{errors.comment && touched.comment && errors.comment}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                name="name"
                                                id="name"
                                                type="text"
                                                placeholder={t("Name")}
                                                value={values.name} onBlur={handleBlur} onChange={handleChange} />
                                                <small className='text-danger fw-bold'>{errors.name && touched.name && errors.name}</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                name="email"
                                                id="email"
                                                type="email"
                                                placeholder={t("Email")}
                                                value={values.email} onBlur={handleBlur} onChange={handleChange} />
                                                <small className='text-danger fw-bold'>{errors.email && touched.email && errors.email}</small>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                name="website"
                                                id="website"
                                                type="url"
                                                placeholder={t("Website")}
                                                value={values.website} onBlur={handleBlur} onChange={handleChange} />
                                                <small className='text-danger fw-bold'>{errors.website && touched.website && errors.website}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="button button-contactForm"
                                    >
                                        {t("Post Comment")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
    </>
  )
}
