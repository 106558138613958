import {Link} from "react-router-dom";
import SwiperCore, { EffectFade, Pagination, Scrollbar, Autoplay } from "swiper";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import {server} from '../../util/server';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Pagination, EffectFade,Autoplay]);
const Intro3 = ({homePageBanners}) => {
    const { i18n } = useTranslation();
    return (
        <>
            <Swiper
                 modules={[Pagination, Scrollbar, Autoplay]}
                 spaceBetween={0}
                 slidesPerView={1}
                 autoplay={{
                     delay: 3000,
                     disableOnInteraction: false,
                   }}
                 effect={"fade"}
                 fadeEffect={{
                     crossFade: true,
                 }}
                 pagination={{ clickable: true }}
                 scrollbar={{ draggable: true }}
                className="hero-slider-1 style-3 dot-style-1 dot-style-1-position-1"
            >
                {homePageBanners.map((banner, i) => (
                    <SwiperSlide key={i}>
                       <div className="single-hero-slider single-animation-wrap">
                            <div className="container">
                                <div className="slider-1-height-3 slider-animated-1">
                                    <div className="hero-slider-content-2">
                                        {banner.heading && 
                                            <h2 className="animated fw-900">
                                                {banner.heading}
                                            </h2>
                                        }

                                        {banner.paragraph && 
                                            <p className="animated">
                                                {banner.paragraph}
                                            </p>
                                        }

                                        {banner.link_text && 
                                            <Link to={banner.page_link ? banner.page_link : '/'} className="animated btn btn-brush btn-brush-3">
                                                {banner.link_text}
                                            </Link>
                                        }
                                        
                                        
                                    </div>
                                    <div className="slider-img">
                                        <img
                                             src={server+"/"+banner.name}
                                             alt={banner['alt_'+i18n.language]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}

              
            </Swiper>

           
        </>
    );
};

export default Intro3;
