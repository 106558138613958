import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SortSelect from "../../components/ecommerce/SortSelect";
import CategoryProduct from "../../components/ecommerce/CategoryProduct";
import SingleProduct from "../../components/ecommerce/SingleProduct";
import { useFilterContext } from '../../context/filterContext';
import BrandFilter from '../../components/ecommerce/BrandFilter';
import PriceRangeSlider from '../../components/ecommerce/PriceRangeSlider';
import ShopFilters from '../../components/ecommerce/ShopFilters';
import RecentlyViewdProducts from "../../components/sliders/RecentlyViewdProducts";
import { useTranslation } from 'react-i18next';
import VendorFilter from '../../components/ecommerce/VendorFilter';
import { Meta } from '../../components/elements/Meta';
import Spinner from '../../components/Spinner/Main';
import Pagination from "react-js-pagination";

export default function Shop() {
    const { t } = useTranslation();
    const { filter_products, productCategoryFilter, productBrandHandler, filter_values, brand_value, selected_price_range, category_filter, clearFilter, vendorValue, ShopInitalLoadHandler, pagination, setPageValue, updatemetas,setPageNumber } = useFilterContext();
    //pagination
    const [currentItems, setCurrentItems] = useState([]);


    useEffect(() => {
        ShopInitalLoadHandler({ 'type': 'shop' });
        updatemetas('shop', 4)
        setPageNumber(1)

    }, []);



    // DB pagination
    //   useEffect(() => {
    //     setCurrentItems(filter_products);
    // }, [ filter_products]);

    // const handlePagination = (item) => 
    // {
    //     setPageValue(item);
    // }

    // pagination
    useEffect(() => {
        //current paginate products
        setCurrentItems(filter_products.data);
    }, [filter_products.data]);

    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <span></span> {t("Shop")}
                    </div>
                </div>
            </div>
            {filter_products.length !== 0 &&
                <section className="mt-50 mb-50">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-9">
                                <div className="shop-product-fillter">
                                    <div className="totall-product">
                                        <p>
                                            <span className="badge bg-light text-dark rounded-pill productCount">
                                                {filter_products.total}      {t("Products")}
                                            </span>

                                            {
                                                currentItems.length > 0 && (brand_value || selected_price_range || category_filter || vendorValue) ? <Link to="#" onClick={(e) => clearFilter(e)} className="ms-2 clearFilter fw-bold badge rounded-pill bg-dark text-white">{t("Clear Filter")}</Link> : ''
                                            }
                                        </p>
                                    </div>
                                    <div className="sort-by-product-area">
                                        <div className="sort-by-cover">
                                            <SortSelect />
                                        </div>
                                    </div>
                                </div>
                                <div className="row product-grid-3">
                                    {
                                        currentItems.length === 0 && <h3>{t("No Products Found")} </h3>
                                    }

                                    {currentItems.length > 0 && currentItems.map((item, i) => (
                                        <div
                                            className="col-lg-4 col-md-4 col-12 col-sm-6"
                                            key={i}
                                        >
                                            <SingleProduct product={item} />
                                        </div>
                                    ))}


                                </div>

                                {currentItems.length !== 0 &&
                                    <div className="col-12 mt-5">
                                        <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                                            <nav aria-label="Page navigation example">
                                                <Pagination
                                                    totalItemsCount={filter_products && filter_products.total}
                                                    activePage={filter_products && filter_products.current_page}
                                                    itemsCountPerPage={filter_products && filter_products.per_page}
                                                    onChange={(pageNumber) => { setPageNumber(pageNumber) }}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                    activeClass='active'
                                                />
                                            </nav>
                                        </div>
                                    </div>
                                }
                                

                            </div>
                            <div className="col-lg-3 primary-sidebar sticky-sidebar">

                                <div className="widget-category mb-30">

                                    <CategoryProduct productCategoryFilter={productCategoryFilter} />
                                </div>

                                {/* <BrandFilter productBrandHandler={productBrandHandler} filter_products={filter_products.data} /> */}

                                <VendorFilter />

                                <ShopFilters />

                                <PriceRangeSlider filter_products={filter_products.data} />

                            </div>
                        </div>

                        <RecentlyViewdProducts />
                    </div>
                </section>
            }

        </>
    )
}

