import React, { useRef, useEffect, useState } from "react";

import SupportWindow from './SupportWindow'

import Avatar from './Avatar'
import { useTranslation } from 'react-i18next';
import { server } from "../util/server";
import axios from "axios";
import { toast } from "react-toastify";

const SupportEngine = () => {
    const wrapperRef = useRef(null);
    const {  t } = useTranslation();
    useOutsideAlerter(wrapperRef);
    const [visible, setVisible] = useState(false)
    const [showChat, setShowChat] = useState([])
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    // setVisible(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const getCustomerChat = async() => {
        if (localStorage.getItem('chat_id')) {
            await axios({
                method: "get",
                url: server + "/api/get-chat-data?session_id=" + localStorage.getItem('chat_id'),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    if (response.data.status === true) {
                        setShowChat(response.data.data)
                    }
                })
                .catch(function (response) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }
    }


    const openChatEng = () => {
        getCustomerChat();
        setVisible(true)
    }

    return (
        <div ref={wrapperRef}>
            <SupportWindow visible={visible} setVisible={setVisible} showChat={showChat} setShowChat={setShowChat} />

            <Avatar 
                onClick={() => openChatEng()}
                style={{
                    position: 'fixed',
                    bottom: '24px',
                    right: '24px',
                }}
            />
        </div>
    )
}

export default SupportEngine;

