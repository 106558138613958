import React from "react";
import {Link} from "react-router-dom";
import { server } from "../../util/server";
import { useTranslation } from 'react-i18next';
import {useFilterContext} from '../../context/filterContext';
const Bottom = ({bottom,homePageContent}) => {
    const { i18n,t } = useTranslation();
    const {app_currency,currency_value} = useFilterContext();
    return (
        <>
            <section className="pt-50">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3 col-md-6 mb-sm-5 mb-md-0">
                            <div className="banner-img wow fadeIn animated mb-md-4 mb-lg-0">
                                    <img src={homePageContent['home_section_4'] && server+'/'+homePageContent['home_section_4'][0]['name_de']}
                                alt={homePageContent['home_section_4'] && homePageContent['home_section_4'][0]['alt_'+i18n.language]} />
                                {
                                    homePageContent['home_section_4'] &&  <div className="banner-text"  dangerouslySetInnerHTML={{ __html: homePageContent['home_section_4'][0]['text_'+i18n.language] }} />
                                }
                            </div>
                        </div> */}
                         <div className="col-lg-4 col-md-12">
                            <h4 className="section-title style-1 mb-30 wow fadeIn animated">
                                {t("Branded Accessories")}
                            </h4>
                            <div className="product-list-small wow fadeIn animated">
                            {
                                    bottom && bottom.branded_accessories &&
                                    bottom.branded_accessories.map(product => (
                                        <article className="row align-items-center" key={product.id}>
                                            <figure className="col-sm-4 mb-0">
                                                <Link to={"/Produkte/"+product.slug}>
                                                        <img
                                                             src={product.main_image && server+"/"+product.main_image.image}
                                                             alt={product.main_image && product.main_image['alt_'+i18n.language]}
                                                        />
                                                </Link>
                                            </figure>
                                            <div className="col-sm-8 mb-0">
                                                <h4 className="title-small">
                                                    <Link to={"/Produkte/"+product.slug}>
                                                        {product['name_'+i18n.language]}
                                                    </Link>
                                                </h4>
                                                <div className="product-price">
                                                    <span>{app_currency}{(product['price_CHF'] * currency_value[app_currency]).toFixed(2)}</span>
                                                    <span className="old-price">
                                                    {app_currency}{(product['original_price_CHF'] * currency_value[app_currency]).toFixed(2)}
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mb-sm-5 mb-md-0">
                            <h4 className="section-title style-1 mb-30 wow fadeIn animated">
                                {t("Deals & Discounts")}
                            </h4>
                            <div className="product-list-small wow fadeIn animated">
                                {
                                    bottom && bottom.deals &&
                                    bottom.deals.map(product => (
                                        <article className="row align-items-center" key={product.id}>
                                            <figure className="col-sm-4 mb-0">
                                                <Link to={"/Produkte/"+product.slug}>
                                                        <img
                                                             src={product.main_image && server+"/"+product.main_image.image}
                                                             alt={product.main_image['alt_'+i18n.language]}
                                                        />
                                                </Link>
                                            </figure>
                                            <div className="col-sm-8 mb-0">
                                                <h4 className="title-small">
                                                    <Link to={"/Produkte/"+product.slug}>
                                                        {product['name_'+i18n.language]}
                                                    </Link>
                                                </h4>
                                                <div className="product-price">
                                                    <span>{app_currency}{(product['price_CHF'] * currency_value[app_currency]).toFixed(2)}</span>
                                                    <span className="old-price">
                                                    {app_currency}{(product['original_price_CHF'] * currency_value[app_currency]).toFixed(2)}
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    ))
                                }
                               

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mb-sm-5 mb-md-0">
                            <h4 className="section-title style-1 mb-30 wow fadeIn animated">
                                {t("Top Selling")}
                            </h4>
                            <div className="product-list-small wow fadeIn animated">
                            {
                                    bottom && bottom.top_selling &&
                                    bottom.top_selling.map(product => (
                                        <article className="row align-items-center" key={product.id}>
                                            <figure className="col-sm-4 mb-0">
                                                <Link to={"/Produkte/"+product.slug}>
                                                        <img
                                                             src={product.main_image && server+"/"+product.main_image.image}
                                                             alt={product.main_image['alt_'+i18n.language]}
                                                        />
                                                </Link>
                                            </figure>
                                            <div className="col-sm-8 mb-0">
                                                <h4 className="title-small">
                                                    <Link to={"/Produkte/"+product.slug}>
                                                        {product['name_'+i18n.language]}
                                                    </Link>
                                                </h4>
                                                <div className="product-price">
                                                    <span>{app_currency}{(product['price_CHF'] * currency_value[app_currency]).toFixed(2)}</span>
                                                    <span className="old-price">
                                                    {app_currency}{(product['original_price_CHF'] * currency_value[app_currency]).toFixed(2)}
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    ))
                                }
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        </>
    );
};

export default Bottom;
