import React, {useEffect} from "react";
import { useFormik } from 'formik';
import { passwordResetSchema } from '../schemas';
import { toast } from "react-toastify";
import { server } from '../util/server';
import axios from 'axios';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {useFilterContext} from '../context/filterContext';
import { Meta } from "../components/elements/Meta";
const initialValues = {
    code: '',
    password: '',
    cpassword: '',
}


export default function PasswordReset() {
    const { t } = useTranslation();
    const {updatemetas} = useFilterContext();

    useEffect(() => {
        updatemetas('lost-password',4)
    },[]);

    const submitPasswordReset = async (query, actions) => {
        try {
            let res = await axios.post(server + "/api/password-reset", query);
            let data = await res.data;
            if (data.status === false) {
                if (data.message) { toast.error(t(data.message)) }

                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(t(item));
                        })

                    })
                }
            } else {
                toast.success(t(data.message));
                actions.resetForm();
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }

    }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: passwordResetSchema,
        onSubmit: async (values, actions) => {
            submitPasswordReset(values, actions);
        }
    });
    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <Link to="/konto">
                        <span></span>  {t("Login")}
                        </Link>
                        <span></span>  {t("Password Reset")}
                    </div>
                </div>
            </div>

            <section className="pt-60 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 m-auto">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                                        <div className="padding_eight_all bg-white">
                                            <div className="heading_s1">
                                                <h3 className="mb-30">
                                                    {t("Password Reset")}
                                                </h3>
                                            </div>
                                            <form method="post" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="code"
                                                        placeholder={t("Code")}
                                                        value={values.code} onBlur={handleBlur} onChange={handleChange} />
                                                    <small className='text-danger fw-bold'>{errors.code && touched.code && errors.code}</small>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        placeholder={t("Password")}
                                                        value={values.password} onBlur={handleBlur} onChange={handleChange} />
                                                    <small className='text-danger fw-bold'>{errors.password && touched.password && errors.password}</small>
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="cpassword"
                                                        placeholder={t("Confirm password")}
                                                        value={values.cpassword} onBlur={handleBlur} onChange={handleChange} />
                                                    <small className='text-danger fw-bold'>{errors.cpassword && touched.cpassword && errors.cpassword}</small>
                                                </div>
                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-fill-out btn-block hover-up"
                                                        name="login"
                                                    >
                                                        {t("Password Reset")}
                                                    </button>
                                                    <p className="mt-10">{t("Password reset successfully?")} <Link to="/konto">{t("Login")}</Link></p>
                                                </div>
                                            </form>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}
