import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header.old";
import MobileMenu from "./components/layout/MobileMenu";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-responsive-modal/styles.css";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { HashRouter, Route, Routes, BrowserRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// Swiper Slider
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Preloader from "./components/elements/Preloader";
import WOW from 'wowjs'
//custom css
import "./assets/sass/main.scss"
import "./App.css"
//pages
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Shop from "./pages/products/Shop";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Blog from "./pages/Blog";
import SingleBlog from "./pages/SingleBlog";
import LoginRegister from "./pages/LoginRegister";
import ProductDetail from "./pages/products/ProductDetail";
import Dashboard from "./pages/user/Dashboard";
import SingleBrand from "./pages/products/SingleBrand";
import Brands from "./pages/products/Brands";
import PageNotFound from "./pages/errors/PageNotFound";

import axios from 'axios';
import Search from "./pages/products/Search";
import Order from './pages/user/Order';
import Wishlist from "./pages/user/Wishlist";
import Profile from "./pages/user/Profile";
import PasswordReset from "./pages/PasswordReset";
import Category from "./pages/products/Category";
import DynamicPage from "./pages/DynamicPage";
import FAQ from "./pages/FAQ";
import { Coupons } from "./pages/Coupons";


axios.defaults.headers.post['Content-Type'] = 'application/json'

function App() {
  const [isToggled, setToggled] = useState(false);
    const toggleClick = () => {
        setToggled(!isToggled);
        isToggled ? document.querySelector("body").classList.remove("mobile-menu-active") : document.querySelector("body").classList.add("mobile-menu-active");
    };
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);

      
            new WOW.WOW({
              live: false
          }).init();
      
       
    }, []);

    const { i18n } = useTranslation();
    const changeLangueg = (lng,lng_name) => {
        sessionStorage.setItem('lng', lng);
        sessionStorage.setItem('lng_name', lng_name)
        i18n.changeLanguage(lng);
    }
  return (
    <>
        <BrowserRouter>
            {!loading ? (
                <>
                    <ToastContainer />
                    {isToggled && <div className="body-overlay-1" onClick={toggleClick}></div>}

                  <Header isToggled={isToggled} toggleClick={toggleClick} changeLangueg={changeLangueg} />
                  <MobileMenu isToggled={isToggled} toggleClick={toggleClick} changeLangueg={changeLangueg} />

                  <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/uber-uns" element={<About />}></Route>
                    <Route path="/kontact-uns" element={<Contact />}></Route>
                    <Route path="/gutscheine" element={<Coupons />}></Route>
                    <Route path="/shop" element={<Shop />}></Route>
                    <Route path="/warenkorb" element={<Cart />}></Route>
                    <Route path="/kasse" element={<Checkout />}></Route>
                    <Route path="/bloggen" element={<Blog />}></Route>
                    <Route path="/bloggen/:slug" element={<SingleBlog />}></Route>
                    <Route path="/konto" element={<LoginRegister />}></Route>
                    <Route path="/lost-password" element={<PasswordReset />}></Route>
                    <Route path="/Produkte/:slug" element={<ProductDetail />}></Route>
                    <Route path="/marken" element={<Brands />}></Route>
                    <Route path="/marken/:slug" element={<SingleBrand />}></Route>
                    <Route path="/404" element={<PageNotFound/>}></Route>
                    <Route path="/suche" element={<Search />}></Route>
                    <Route path="/faq" element={<FAQ />}></Route>
                    <Route path="/benutzer/bestellungen" element={<Order />}></Route>
                    <Route path="/benutzer/armaturenbrett" element={<Dashboard />}></Route>
                    <Route path="/benutzer/wunschzettel" element={<Wishlist />}></Route>
                    <Route path="/benutzer/Profil" element={<Profile />}></Route>
                    <Route path="/:maincategory/:subcategory?/:childcategory?" element={<Category />}></Route>
                    <Route path="/*" element={<DynamicPage />}></Route>
                  </Routes>

                  <Footer />
                </>
            ): (
                <Preloader />
            )}
        </BrowserRouter> 
    </>
  );
}

export default App;
