import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {server} from '../../util/server';
import { Link } from "react-router-dom";
import Timer from "./Timer";

const Deals1 = ({homePageContent}) => {
    const { t,i18n } = useTranslation();
    let imageUrl = homePageContent['home_section_6'] ? server+"/"+homePageContent['home_section_6'][0]['image'] : 'assets/imgs/banner/menu-banner-7.jpg';
    return (
        <>
           

            {
                homePageContent['home_section_6'] && 
                <div  className="deal wow fadeIn animated mb-md-4 mb-sm-4 mb-lg-0"
                style={{
                    backgroundImage: `url(${imageUrl})`,
                }}  >

                    <div className="deal-top" dangerouslySetInnerHTML={{ __html: homePageContent['home_section_6'][0]['text_'+i18n.language] }} />

                    <div className="deal-bottom">
                    {
                            homePageContent['home_section_6'][0]['categories'] && 
                            <>
                                <p>{t("Offer Ends In")}:</p>
                                <Timer
                                    endDateTime={homePageContent['home_section_6'][0]['categories']+" 00:00:00"}
                                />
                            </>
                        }
                        <Link className="btn hover-up" to={ homePageContent['home_section_6'] && homePageContent['home_section_6'][0]['link'] }>{ homePageContent['home_section_6'] && homePageContent['home_section_6'][0]['name_'+i18n.language] } <i className="fi-rs-arrow-right"></i></Link>
                    </div>
                </div>


            }

            
           
        </>
    );
};

export default Deals1;
