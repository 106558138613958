import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import {useFilterContext} from '../../context/filterContext';
import { useTranslation } from 'react-i18next';

export default function Sidebar() {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();
    const {unsetUserLogin} = useFilterContext();
    
    useEffect(() => {
        if(location.pathname === '/benutzer/bestellungen'){
            setActiveIndex(2)
        } else if(location.pathname === '/benutzer/wunschzettel'){
            setActiveIndex(3)
        } else if(location.pathname === '/benutzer/Profil'){
            setActiveIndex(4)
        } else{
            setActiveIndex(1)
        }
    },[location.pathname])
  return (
    <div className="col-lg-3 col-md-4">

    <div className="dashboard-menu">
        <ul
            className="nav flex-column"
            role="tablist"
        >
            <li className="nav-item">
                <Link to="/benutzer/armaturenbrett"
                    className={activeIndex === 1 ? "nav-link active" : "nav-link"}
                    
                >
                    <i className="fi-rs-settings-sliders mr-10"></i>
                    {t("Dashboard")}
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/benutzer/bestellungen"
                    className={activeIndex === 2 ? "nav-link active" : "nav-link"}
                    
                >
                    <i className="fi-rs-shopping-bag mr-10"></i>
                    {t("Orders")}
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/benutzer/wunschzettel"
                    className={activeIndex === 3 ? "nav-link active" : "nav-link"}
                    
                >
                    <i className="fi-rs-shopping-cart-check mr-10"></i>
                    {t("Wishlist")}
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/benutzer/Profil"
                    className={activeIndex === 4 ? "nav-link active" : "nav-link"}
                    
                >
                    <i className="fi-rs-user mr-10"></i>
                    {t("Account details")}
                </Link>
            </li>
            <li className="nav-item">
                <Link to="#" onClick={() => unsetUserLogin()} className="nav-link">
                    <i className="fi-rs-sign-out mr-10"></i>
                    {t("Logout")}
                </Link>
            </li>
        </ul>
    </div>
</div>
  )
}
