
import RegistrationForm from "../components/elements/RegistrationForm";
import LoginForm from "../components/elements/LoginForm";
import {useNavigate,useLocation  } from "react-router-dom";
import {useFilterContext} from '../context/filterContext';
import { useEffect } from "react";
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Meta } from "../components/elements/Meta";
import { toast } from "react-toastify";
import { server } from "../util/server";
import axios from "axios";
function LoginRegister() {
    const { t } = useTranslation();
    const {isUserLogin,updatemetas} = useFilterContext();
    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    useEffect(() => {
        if(isUserLogin !== null){
          navigate('/');
        }
    }, [isUserLogin])


    useEffect(() => {
        updatemetas('konto',4)
    },[]);

    useEffect(() => {
        if(params.get("code")){
            axios({
                method: "get",
                url: server+"/api/account-activation?code="+params.get("code"),
            }).then(function (result) {
                if(result.data.status === false){
                    toast.error(t(result.data.message))
                }else{
                    toast.success(t(result.data.message))
                }
             
            })
            .catch(function (error) {
                toast.error(t("Unexpected error occurred!"))
            });

        }
    },[params]);
    return (
        <>
        <Meta />
        <div className={`page-header breadcrumb-wrap`}>
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to="/">
                               {t("Home")}
                            </Link>
                            <span></span> {t("Login / Register")}
                        </div>
                    </div>
                </div>

                <section className="pt-60 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 m-auto">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                                            <div className="padding_eight_all bg-white">
                                                <div className="heading_s1">
                                                    <h3 className="mb-30">
                                                        {t("Login")}
                                                    </h3>
                                                </div>
                                               <LoginForm />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-6">
                                        <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                                            <div className="padding_eight_all bg-white">
                                                <div className="heading_s1">
                                                    <h3 className="mb-20">
                                                        {t("Create an Account")}
                                                    </h3>
                                                </div>
                                                <p className="mb-20 font-sm">
                                                    {t("Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy")}
                                                </p>
                                                <RegistrationForm />
                                              
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    );
}


export default LoginRegister;
