import {useFilterContext} from '../../context/filterContext';
import {Link} from 'react-router-dom'

export default function ShopFilters() {
    const { product_filters,productFilterHandler} = useFilterContext();
    const groupBy2 = (arr) => {
        return arr.reduce((acc, { type_value_en, filter_value }) => {
          // if(!filter_id) return acc;
          
          if(!acc[type_value_en]) acc[type_value_en] = {};
          
          const make = acc[type_value_en];
          
          if(!make[filter_value]) make[filter_value] = { count: 0 };
          
          make[filter_value].count++;
          
          return acc;
        }, Object.create(null));
      };

      const myShopFilterHandler = (e, key) => {
        
        productFilterHandler(key)
      }
    return (
        <>
        {
            product_filters.length > 0 && 
            <div className="widget-category mb-30">
                {
                    product_filters && Object.entries(groupBy2(product_filters)).map(([key, value]) => (
                        <div key={key}>
                            <h5 className="section-title style-1 mb-20 mt-20 wow fadeIn animated">
                                    {key} 
                                </h5>
                                <ul className="categories" key={key}>
                                        { value && Object.entries(value).map(([key2, value2]) => (
                                                <li key={key2}><Link to="#"  onClick={(e) => myShopFilterHandler(e, key2)} >{key2} ({value2.count})</Link></li>
                                        ))}
                                </ul>
                        </div>
                    ))
                                
                }
            </div>

        }
           
          
        </>
    )
}
