import {useFilterContext} from '../../context/filterContext';
import { useTranslation } from 'react-i18next';
const SortSelect = () => {
    const { productSortHandler} = useFilterContext();
    const { t } = useTranslation();

    return (
        <>
            <div className="sort-by-product-wrap">
                <div className="sort-by">
                    <span>
                        <i className="fi-rs-apps-sort"></i>
                        {t("Sort by")}:
                    </span>
                </div>
                <div className="sort-by-dropdown-wrap custom-select">
                    <select onChange={productSortHandler}>
                        <option value="sort_latest" >{t("Default sorting")}</option>
                        <option value="sort_popularity">{t("Sort by popularity")}</option>
                        <option value="sort_rating">{t("Sort by average rating")}</option>
                        <option value="sort_latest">{t("Sort by latest")}</option>
                        <option value="sort_price_asc">{t("Sort by price: low to high")}</option>
                        <option value="sort_price_desc">{t("Sort by price: high to low")}</option>
                    </select>
                </div>
            </div>
        </>
    );
};



export default SortSelect;
