import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import useClickOutside from "../../util/outsideClick";
import { useFilterContext } from '../../context/filterContext';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
const MobileMenu = ({ isToggled, toggleClick, changeLangueg }) => {
    const { ChangeCurrency, app_currency, brands, navbar_items,isUserLogin,unsetUserLogin } = useFilterContext();
    const { i18n,t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState({
        status: false,
        key: "",
    });
    const [headerBrands, setheaderBrands] = useState([]);

    const handleToggle = (key) => {
        if (isActive.key === key) {
            setIsActive({
                status: false,
            });
        } else {
            setIsActive({
                status: true,
                key,
            });
        }
    };

    let domNode = useClickOutside(() => {
        setIsActive({
            status: false,
        });
    });

    function splitToChunks(array, parts) {
        let result = [];
        for (let i = parts; i > 0; i--) {
            result.push(array.splice(0, Math.ceil(array.length / i)));
        }
        return result;
    }

    useEffect(() => {
        setheaderBrands(splitToChunks([...brands], 4))
    }, [brands]);

    const handleSearch = () => {
        document.getElementById('mobile-search').value = ''
        toggleClick()
        let query = '?query=' + searchTerm + '&lang=' + i18n.language + '&currency=' + app_currency;
        navigate('/suche' + query)

    };

    const handleInput = () => {
        if (searchTerm) {
            handleSearch();
        } else {
            toast.error(t("Please enter query"))
        }
    };
    return (
        <>
            <div className={isToggled ? "mobile-header-active mobile-header-wrapper-style sidebar-visible" : "mobile-header-active mobile-header-wrapper-style"}>
                <div className="mobile-header-wrapper-inner">
                     
                    <div className="mobile-header-top">
                        
                        <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                            <button className="close-style search-close" onClick={toggleClick}>
                                <i className="icon-top"></i>
                                <i className="icon-bottom"></i>
                            </button>
                        </div>
                        <div className="mobile-header-logo">
                            {
                                !isUserLogin 
                                ? 
                                    <Link to="/konto" className="text-dark">
                                        <i className="fi-rs-user"></i> {t("Log In / Sign Up")}
                                    </Link>
                                :
                                <>
                                    <Link to="/benutzer/armaturenbrett" className="text-dark d-inline-block" title={t("Profile")}>
                                        <i className="fas fa-user-alt fs-5"></i>
                                    </Link>
                                    <Link to="#" className="text-dark d-inline-block ms-2" onClick={() => unsetUserLogin()} title={t("Logout")}>
                                        <i className="fas fa-sign-out-alt fs-5"></i>
                                    </Link>
                                </>
                            }
                           
                        </div>
                    </div>
                    <div className="mobile-header-content-area">
                        <div className="mobile-search search-style-3 mobile-header-border">
                            <form action="#">
                                <input type="text" placeholder={t("Search")} onKeyDown={(e) => e.key === "Enter" ? e.preventDefault() : ''}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    id="mobile-search" />
                                <button type="button" onClick={() => handleInput()}>
                                    <i className="fi-rs-search"></i>
                                </button>
                            </form>
                        </div>
                        <div className="mobile-menu-wrap mobile-header-border">


                            <nav>
                                <ul className="mobile-menu" ref={domNode}>
                                    {
                                        navbar_items.length > 0 &&
                                        navbar_items.map((item, index) => (
                                            <>
                                                {
                                                    item.name_en.toLocaleLowerCase().indexOf("brands") !== -1 || item.name_en.toLocaleLowerCase().indexOf("brand") !== -1
                                                        ?
                                                        <li key={item.main_page_id} className={isActive.key === item.main_page_id ? "menu-item-has-children active" : "menu-item-has-children"}>
                                                            <span className="menu-expand" onClick={() => handleToggle(item.main_page_id)}>
                                                                <i className="fi-rs-angle-small-down"></i>
                                                            </span>
                                                            <Link to={item.link}>
                                                                {item['name_' + i18n.language]}
                                                            </Link>
                                                            <ul className={isActive.key === item.main_page_id ? "dropdown" : "d-none"}>
                                                                {
                                                                    headerBrands.length > 0 &&
                                                                    headerBrands.map((item, index) => (
                                                                        <li key={'inf'+index} className="sub-mega-menu sub-mega-menu-width-22" >
                                                                            <Link className="menu-title" to="#">Top Brands</Link>
                                                                            <ul>
                                                                                {
                                                                                    item.length > 0 && item.map((elem, index) => (
                                                                                        <li key={elem.slug}>
                                                                                            <Link to={"/marken/" + elem.slug}>
                                                                                                {elem.name}
                                                                                            </Link>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        </li>
                                                                    ))
                                                                }

                                                            </ul>
                                                        </li>
                                                        :
                                                        <li key={index} className={item.subnav.length > 0 ? 'menu-item-has-children' : ''}>
                                                            <>
                                                                {item.subnav.length > 0 && <span className="menu-expand" onClick={() => handleToggle(item.main_page_id)}>
                                                                    <i className="fi-rs-angle-small-down"></i>
                                                                </span>}
                                                                <Link to={item.link}>
                                                                    {item['name_' + i18n.language]}

                                                                </Link>
                                                                {item.subnav.length > 0 &&
                                                                    <ul className={isActive.key === item.main_page_id ? "dropdown" : "d-none"}>
                                                                        {
                                                                            item.subnav.map((subnav, sbkey) => (
                                                                                <li key={subnav}>
                                                                                    <Link to={subnav.link}>
                                                                                        {subnav['name_' + i18n.language]}
                                                                                    </Link>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                }
                                                            </>
                                                        </li>
                                                }
                                            </>

                                        ))
                                    }

                                </ul>
                            </nav>
                        </div>
                        <div className="mobile-header-info-wrap mobile-header-border">
                            <div className="single-mobile-header-info mt-30">
                                <h5 className="mb-2">{t("Choose language")}</h5>
                                <Link to="#" className={i18n.language === 'de' ? 'bg-danger text-light rounded-circle d-inline-block text-center' : 'bg-dark text-light rounded-circle d-inline-block text-center'} style={{ lineHeight: '40px', width: '40px', height: '40px', fontSize: '13px' }} onClick={() => changeLangueg('de', 'German')}>
                                    De
                                </Link>

                                <Link to="#" className={i18n.language === 'en' ? 'bg-danger text-light rounded-circle d-inline-block text-center ms-2' : 'bg-dark text-light rounded-circle d-inline-block text-center ms-2'} style={{ lineHeight: '40px', width: '40px', height: '40px', fontSize: '13px' }} onClick={() => changeLangueg('en', 'English')}>
                                    En
                                </Link>
                                <Link to="#" className={i18n.language === 'fr' ? 'bg-danger text-light rounded-circle d-inline-block text-center ms-2' : 'bg-dark text-light rounded-circle d-inline-block text-center ms-2'} style={{ lineHeight: '40px', width: '40px', height: '40px', fontSize: '13px' }} onClick={() => changeLangueg('fr', 'Français')}>
                                    Fr
                                </Link>
                                <Link to="#" className={i18n.language === 'it' ? 'bg-danger text-light rounded-circle d-inline-block text-center ms-2' : 'bg-dark text-light rounded-circle d-inline-block text-center ms-2'} style={{ lineHeight: '40px', width: '40px', height: '40px', fontSize: '13px' }} onClick={() => changeLangueg('it', 'Italian')}>
                                    It
                                </Link>
                            </div>
                            <div className="single-mobile-header-info">
                                <h5 className="mb-2 mt-3">{t("Choose Currency")}</h5>
                                <Link to="#" className={app_currency === 'CHF' ? 'bg-danger text-light rounded-circle d-inline-block text-center' : 'bg-dark text-light rounded-circle d-inline-block text-center'} style={{ lineHeight: '40px', width: '40px', height: '40px',fontSize:'12px' }} onClick={() => ChangeCurrency('CHF')}>
                                        CHF
                                </Link>

                                <Link to="#" className={app_currency === 'USD' ? 'bg-danger text-light rounded-circle d-inline-block text-center ms-2' : 'bg-dark text-light rounded-circle d-inline-block text-center ms-2'} style={{ lineHeight: '40px', width: '40px', height: '40px',fontSize:'12px' }} onClick={() => ChangeCurrency('USD')}>
                                USD
                                </Link>
                                <Link to="#" className={app_currency === 'EUR' ? 'bg-danger text-light rounded-circle d-inline-block text-center ms-2' : 'bg-dark text-light rounded-circle d-inline-block text-center ms-2'} style={{ lineHeight: '40px', width: '40px', height: '40px',fontSize:'12px' }} onClick={() => ChangeCurrency('EUR')}>
                                EUR
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileMenu;
