import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useFilterContext } from '../../context/filterContext';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import { Modal } from "react-bootstrap";
import { format } from 'date-fns';
import { server } from '../../util/server';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Meta } from '../../components/elements/Meta';
import Spinner from '../../components/Spinner/Main'

export default function Order() {
    const { i18n, t } = useTranslation();
    const [spinner, setSpinner] = useState(true);
    const token = window.sessionStorage.getItem('local_token')
    const { isUserLogin, updatemetas } = useFilterContext();
    const navigate = useNavigate();
    const [showModal, setShow] = useState(false);
    const [orders, setOrders] = useState([]);
    const [modalData, setModalData] = useState(null);
    const handleClose = () => setShow(false);

    //pagination
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 12;

    const handleShow = (item) => {
        setShow(true); setModalData(item)
    };

    useEffect(() => {
        if (isUserLogin === null) {
            navigate('/');
        }
    }, [isUserLogin])

    const getOrders = async () => {
        try {
            let url = server + "/api/orders"
            let res = await axios.get(url, {
                headers: { "Content-Type": "multipart/form-data", "Authorization": token ? `Bearer ${token}` : '' },
            });
            let data = await res.data;
            if (data.status === true) {
                setOrders(data.data)
                updatemetas(null, 0, data.meta)
                setSpinner(false)
            } else {
                toast.error(t(data.message))
                setSpinner(false)
            }

        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getOrders()
    }, [])

    // pagination
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        //current paginate products
        setCurrentItems(orders.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(orders.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, orders]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % orders.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <Link to="/benutzer/armaturenbrett">
                            <span></span>   {t("Dashboard")}
                        </Link>
                        <span></span> {t("Orders")}
                    </div>
                </div>
            </div>



            <section className="pt-50 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 m-auto">
                            <div className="row">
                                <Sidebar />
                                <div className="col-lg-9 col-md-8">
                                    {spinner ? <Spinner /> :
                                        <>
                                            <h3 className="mb-0">{t("Orders")}</h3>
                                            <hr className='mb-4' />
                                            {
                                                orders.length === 0 && <h4 className='text-center'>{t("No reservation found")}</h4>
                                            }
                                            {
                                                currentItems.length > 0 &&
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {t("Order")}
                                                                </th>
                                                                <th>
                                                                    {t("Date")}
                                                                </th>
                                                                <th>
                                                                    {t("Status")}
                                                                </th>
                                                                <th>
                                                                    {t("Total")}
                                                                </th>
                                                                <th>
                                                                    {t("Actions")}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                currentItems.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            #{item.order_number}
                                                                        </td>
                                                                        <td>
                                                                            {format(new Date(item.created_at), 'do  MMMM yyyy')}
                                                                        </td>
                                                                        <td>
                                                                            {item.order_status} ({item.order_type === 0 ? t("Reserved") : t("Online")})
                                                                        </td>
                                                                        <td>
                                                                            {item.currency} {item.total.toFixed(2)}
                                                                        </td>
                                                                        <td>
                                                                            <Link to="#" onClick={() => handleShow(item)} title={t("Order Details")} className='bg-danger py-2 px-3 text-light rounded' >{t("View")}</Link>

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }



                                                        </tbody>
                                                    </table>
                                                </div>
                                            }

                                            {pageCount > 1 && <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                                                <nav aria-label="Page navigation example">
                                                    <ReactPaginate
                                                        nextLabel=">"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={3}
                                                        marginPagesDisplayed={2}
                                                        pageCount={pageCount}
                                                        previousLabel="<"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakLabel="..."
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        renderOnZeroPageCount={null}
                                                    />
                                                </nav>
                                            </div>

                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={showModal} onHide={() => handleClose()} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Order")} #{modalData && modalData.order_number}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className={modalData && modalData.cart.length > 0 ? "table shopping-summery text-center clean" : "d-none"}>
                                    <thead>
                                        <tr className="main-heading">
                                            <th scope="col">{t("Image")}</th>
                                            <th scope="col">{t("Name")}</th>
                                            <th scope="col">{t("Price")}</th>
                                            <th scope="col">{t("Qty")}</th>
                                            <th scope="col">{t("Subtotal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalData && modalData.cart.length > 0 && modalData.cart.map((item, i) => (
                                            <tr key={i}>
                                                <td className="image product-thumbnail">
                                                    <img src={server + '/' + item.cart_product.main_image.image} alt={item.cart_product.main_image['alt_' + i18n.language]} />
                                                </td>

                                                <td className="product-des product-name">
                                                    <p className="product-name">
                                                        {item.cart_product['name_' + i18n.language]}
                                                    </p>
                                                </td>
                                                <td className="price" data-title="Price">
                                                    {item['price_CHF'] !== item['actual_price_CHF'] ? <del className="d-block">{modalData.currency}{(item['actual_price_CHF'] * modalData.currency_rate).toFixed(2)}</del> : ''}
                                                    <span>{modalData.currency} {(item['price_CHF'] * modalData.currency_rate).toFixed(2)}</span>
                                                </td>
                                                <td className="text-center" data-title="Stock">
                                                    <span>{item.quantity}</span>
                                                </td>
                                                <td className="text-right" data-title="Cart">
                                                    {item['price_CHF'] !== item['actual_price_CHF'] ? <del className="d-block">{modalData.currency}{(item['actual_price_CHF'] * item.quantity * modalData.currency_rate).toFixed(2)}</del> : ''}
                                                    <span>{modalData.currency} {(item['price_CHF'] * item.quantity * modalData.currency_rate).toFixed(2)}</span>
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="cart_total_label text-start fw-bold" colSpan={2}>
                                                <h5> {t("Cart Subtotal")}</h5>
                                            </td>
                                            <td className="cart_total_amount  text-end" colSpan={3}>
                                                <span className="font-lg fw-900 text-brand">{modalData && modalData.currency}{modalData && modalData.subtotal}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="cart_total_label text-start" colSpan={2}><h5>{t("Shipping")}</h5></td>
                                            <td className="cart_total_amount text-end" colSpan={3}>
                                                <h5> {t("Store Pickup")}</h5>
                                            </td>
                                        </tr>

                                        {
                                            modalData && modalData.coupon_code &&
                                            <tr>
                                                <td className="cart_total_label text-start" colSpan={2}><h5>{t("Discount")} {'(' + modalData.coupon_code + ')'}</h5></td>
                                                <td className="cart_total_amount text-end" colSpan={3}>
                                                    <h5>{modalData.currency} {modalData.discount_amount}</h5>
                                                </td>
                                            </tr>
                                        }

                                        <tr>
                                            <td className="cart_total_label text-start" colSpan={2}><h5>{t("Total")}</h5></td>
                                            <td className="cart_total_amount text-end" colSpan={3}>
                                                <strong>
                                                    <span className="font-xl fw-900 text-brand">{modalData && modalData.currency}{modalData && modalData.total}</span>
                                                </strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>

    )
}
