
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
export const Officemanager = ({homePageContent}) => {
    const [active, setActive] = useState("1");
    const { i18n,t } = useTranslation();
  return (
    <>
    <div className="heading-tab ">
      {
        homePageContent['home_section_9'] &&   <div className="wow fadeIn animated" dangerouslySetInnerHTML={{ __html: homePageContent['home_section_9'][0]['name_'+i18n.language] }} />
      }
       
            {/* <h3 className="section-title mb-20 text-center">
            <span>Office </span> Manager
            </h3>
           
        </div> */}
    </div>

    <div className="row">
        <div className="col-lg-8 col-12 m-auto">
        <p className="text-center mb-50">{homePageContent['home_section_9'] && homePageContent['home_section_9'][0]['text_'+i18n.language]} </p>

        {
        homePageContent['home_section_9'] &&   <div className="wow fadeIn animated" dangerouslySetInnerHTML={{ __html: homePageContent['home_section_9'][0]['link'] }} />
      }

        {/* {homePageContent['home_section_9'] && homePageContent['home_section_9'][0]['link']} */}
        {/* <iframe class="elementor-video" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="Men's fashion collection for office men |  Brandmarkt St. Gallen" width="100%" height="400" src="https://www.youtube.com/embed/rIk65nWO0Zo?controls=1&rel=0&playsinline=1&modestbranding=0&autoplay=0&enablejsapi=1&origin=https://brandmarkt.store&widgetid=3" id="widget4" data-gtm-yt-inspected-9="true"></iframe> */}

        </div>
        <div className="col-lg-12">
          
        </div>
    </div>
</>
  )
}
