import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SingleProduct from "./../ecommerce/SingleProduct";

SwiperCore.use([Navigation]);

const TrendingSlider = ({ products }) => {
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                //loop={false}
                navigation={{
                    prevEl: ".custom_prev_t",
                    nextEl: ".custom_next_t"
                }}
                className="custom-class"
            >
                {products.map((product, i) => (
                    <SwiperSlide key={i}>
                        <SingleProduct product={product} />
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="slider-arrow slider-arrow-2 carausel-4-columns-arrow" style={{ marginTop:'13px' }}>
                <span className="slider-btn slider-prev slick-arrow custom_prev_t" style={{ left:'auto',right:'65px' }}>
                    <i className="fi-rs-angle-left"></i>
                </span>
                <span className="slider-btn slider-next slick-arrow custom_next_t">
                    <i className="fi-rs-angle-right"></i>
                </span>
            </div>
        </>
    );
};

export default TrendingSlider;
