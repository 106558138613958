import React,{useState} from "react";
import {useFormik} from 'formik';
import {loginSchema} from '../../schemas';
import { toast } from "react-toastify";
import {server} from '../../util/server';
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom";
import {useFilterContext} from '../../context/filterContext';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
const initialValues = {
    email : '',
    password: ''
}

export default function LoginForm() {
    const { t } = useTranslation();
    const {setUserLogin,setCartTotalCount,cartTotalCount,setCoupon} = useFilterContext();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (item) => setShow(true);
    
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword =()=>{
        if(passwordType==="password")
        {
        setPasswordType("text")
        return;
        }
        setPasswordType("password")
    }


    const submitData = async(query, actions)=>{
        try {
            query['session_id'] = sessionStorage.getItem('local_session');
            let res = await axios.post(server+"/api/customer-login", query);
            let data = await res.data;
            if(data.status === false){
                if(data.message){toast.error(t(data.message))}
                
                if(data.errors){
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(t(item));
                        })
                        
                    })
                }
            }else{
                actions.resetForm();
                setUserLogin(data.token);
                if(!sessionStorage.getItem('local_session')){
                    sessionStorage.setItem('local_session', data.session_id)
                }
                if(data.cart_total_quantity !== 0){
                    if(data.cart_total_quantity != cartTotalCount){
                        setCoupon(null);
                    }
                    setCartTotalCount(data.cart_total_quantity)
                }
                navigate('/')
            }
            
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    
    }

    const {values, handleBlur, handleChange, handleSubmit, errors, touched} = useFormik({
        initialValues: initialValues,
        validationSchema: loginSchema,
        onSubmit: async(values, actions) => {
            submitData(values, actions);
        }
    });

    const passwordReset = async(e) => {
        e.preventDefault();
        let email = document.getElementById('forget-password').value;
        if(!email){
            toast.error(t("Email is required"))
        }else{
            try {
                let res = await axios.post(server+"/api/recover-password", {email:email});
                let data = await res.data;
                if(data.status === false){
                    if(data.message){toast.error(t(data.message))}
                    
                    if(data.errors){
                        Object.keys(data.errors).forEach(key => {
                            data.errors[key].map(item => {
                                toast.error(t(item));
                            })
                            
                        })
                    }
                }else{
                    toast.success(t(data.message));
                    navigate('/lost-password')
                }
                
                
            } catch (error) {
                toast.error(t("Unexpected error occurred!"));
            }
        }
       
    }

  return (
    <>
        <form method="post"  onSubmit={handleSubmit}>
            <div className="form-group">
                <input type="email"
                    name="email"
                    placeholder={t("Email")}
                    value={values.email} onBlur={handleBlur} onChange={handleChange} />
                    <small className='text-danger fw-bold'>{errors.email && touched.email && errors.email}</small>
            </div>
            <div className="form-group">
                <input
                type={passwordType}
                name="password"
                placeholder={t("Password")}
                value={values.password} onBlur={handleBlur} onChange={handleChange} />

                <div className="chek-form mt-2 float-end">
                    <div className="custome-checkbox">
                        <input className="form-check-input" type="checkbox" onClick={togglePassword} id="exampleCheckbox12" value="false" />
                        <label className="form-check-label" for="exampleCheckbox12">
                            <span>{t("Show Password")}</span>
                        </label>
                        <br />
                        <small className="text-danger fw-bold"></small>
                    </div>
                </div>

                 <small className='text-danger fw-bold'>{errors.password && touched.password && errors.password}</small>
               
            </div>
            <div className="login_footer form-group">
                <Link className="text-muted" to="#" onClick={() => handleShow()}>
                {t("Forgot password?")}
                </Link>
            </div>
            <div className="form-group">
                <button
                type="submit"
                className="btn btn-fill-out btn-block hover-up"
                name="login"
                >
                {t("Log in")}
                </button>
            </div>
        </form>

        <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
        <Modal.Title>{t("Password Reset")}</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group mb-4">
                                <label>{t("Email")}</label>
                                <input type="email" className="form-control" id="forget-password" onChange={(e) => e.target.value} required/>
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" onClick={(e) => passwordReset(e)} className="btn btn-fill-out btn-block mt-10">
                                {t("Request Code")}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>
  );
}
