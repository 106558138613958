import { useState } from "react";
import SwiperCore, { Navigation, Thumbs, Zoom } from "swiper";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import { server } from "../../util/server";
import { useTranslation } from "react-i18next";
// import Zoom from "react-img-zoom";
import ReactImageMagnify from "react-image-magnify";

SwiperCore.use([Navigation, Thumbs,Zoom]);

const ThumbSlider = ({product}) => {
    const {i18n} = useTranslation();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    return (
        <div>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#000",
                    "--swiper-pagination-color": "#000",
                }}
                //loop={false}
                spaceBetween={10}
                navigation={true}
                zoom={true}
                thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                // thumbs={{ swiper: thumbsSwiper }}
                className="mySwiper2"
            >
                {product && product.images.length > 0 && product.images.map((item,i) => (
                    <SwiperSlide key={i}>
                         <div className="swiper-zoom-container">
                         <img src={server+'/'+item.image} alt={item['alt_'+i18n.language]} title="Double click on the image to zoom" />
                         </div>
                 
                        {/* <Zoom
                            img={item && server+'/'+item.image}
                            zoomScale={1}
                            width={500}
                            height={500}
                            ransitionTime={0.5}
                        /> */}

                        {/* <ReactImageMagnify {...{
                                smallImage: {
                                    alt: 'Wristwatch by Ted Baker London',
                                    isFluidWidth: true,
                                    src: `${server}/${item.image}`
                                    
                                },
                                largeImage: {
                                    src: `${server}/${item.image}` ,
                                    width: 1200,
                                    height: 1800,
                                    enlargedImagePosition: 'over'
                                }
                            }} /> */}

                    {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: 'http://127.0.0.1:8000/storage/products/2023/02/1676544779_menu-banner-7.jpg',
                                    className: "img-fluid",
                                },
                                largeImage: {
                                    src: 'http://127.0.0.1:8000/storage/products/2023/02/1676544779_menu-banner-7.jpg',
                                    width: 1426,
                                    height: 2000
                                },
                                enlargedImageContainerDimensions: {
                                    width: '200%',
                                    height: '100%'
                                }
                            }}
                        /> */}

                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                //loop={false}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                className="mySwiper"
            >
                {product && product.images.length > 0 && product.images.map((item, i) => (
                    <SwiperSlide key={i}>
                         <img src={server+'/'+item.image} alt={item['alt_'+i18n.language]}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ThumbSlider;
