import BlogList from "../components/elements/BlogList";
import BlogSidebar from "../components/elements/BlogSidebar";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { server } from "../util/server";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useFilterContext } from '../context/filterContext';
import { Meta } from "../components/elements/Meta";
import Spinner from "../components/Spinner/Main";
import Pagination from "react-js-pagination";

function Blog() {
    const { i18n, t } = useTranslation();
    const [blogsData, setBlogsData] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [blogsCount, setBlogsCount] = useState();
    const [filterData, setFilterData] = useState([]);
    const [blogCategories, setBlogCategories] = useState([]);
    const [trending, setTrending] = useState([]);
    const { updatemetas } = useFilterContext();
    const [spinner, setSpinner] = useState(true);

    const getBlogs = async (pageNumber = 1) => {
        try {
            let url = server + `/api/blog?page=${pageNumber}&language=` + i18n.language
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === true) {
                setBlogsData(data.data)
                setBlogs(data.data.data)
                setFilterData(data.data)
                setBlogCategories(data.blog_categories)
                setBlogsCount(data.total_blogs)
                setTrending(data.trending)
                updatemetas(null, 0, data.meta)
                setSpinner(false)
            } else {
                toast.success(t(data.message))
                setSpinner(false)
            }

        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getBlogs();

    }, [])


    const categoryFilter = async(name) => {
        if (name === 'All') {
            setFilterData(blogsData)
        } else {
            try {
                let url = server + `/api/blog?type=search&category=`+name+`&language=` + i18n.language
                let res = await axios.get(url);
                let data = await res.data;
                if (data.status === true) {
                    setBlogs(data.data.data)
                    setFilterData(data.data)
                    setBlogCategories(data.blog_categories)
                    setTrending(data.trending)
                    setBlogsCount(data.total_blogs)
                    updatemetas(null, 0, data.meta)
                    setSpinner(false)
                } else {
                    toast.success(t(data.message))
                    setSpinner(false)
                }
    
            } catch (error) {
                setSpinner(false)
                return toast.error(t("Unexpected error occurred!"))
            }
        }
    }

    const blogSearch = async(e) => {
        e.preventDefault();
        let blog_search = document.getElementById("blog_search").value;
        try {
            let url = server + `/api/blog?type=search&search=`+blog_search+`&language=` + i18n.language
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === true) {
                setBlogs(data.data.data)
                setFilterData(data.data)
                setBlogCategories(data.blog_categories)
                setTrending(data.trending)
                setBlogsCount(data.total_blogs)
                updatemetas(null, 0, data.meta)
                setSpinner(false)
            } else {
                toast.success(t(data.message))
                setSpinner(false)
            }

        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const clearBlogSearc = () => {
        setFilterData(blogsData)
        document.getElementById("blog_search").value = null;
    }

    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <span></span> {t("Blogs")}
                    </div>
                </div>
            </div>
            {spinner ? <Spinner /> :
                <>
                    <section className="mt-50 mb-50">
                        <div className="container custom">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="single-header mb-50">
                                        <h1 className="font-xxl text-brand">{t("Blogs")}</h1>
                                    </div>
                                    <div className="loop-grid loop-list pr-30">
                                        <BlogList filterData={filterData.data} />
                                        <div className="col-12 mt-5">
                                            <div className="pagination-area mt-15 mb-sm-5 mb-lg-0">
                                                <nav aria-label="Page navigation example">
                                                    <Pagination
                                                        totalItemsCount={filterData && filterData.total}
                                                        activePage={filterData && filterData.current_page}
                                                        itemsCountPerPage={filterData && filterData.per_page}
                                                        onChange={(pageNumber) => { getBlogs(pageNumber) }}
                                                        itemClass='page-item'
                                                        linkClass='page-link'
                                                        activeClass='active'
                                                    />
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 primary-sidebar sticky-sidebar">
                                    <BlogSidebar blogCategories={blogCategories} trending={trending} categoryFilter={categoryFilter} blogCount={blogsCount && blogsCount} blogSearch={blogSearch} clearBlogSearc={clearBlogSearc} />
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    );
}

export default Blog;
