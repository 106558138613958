import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useFilterContext } from '../../context/filterContext';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import { server } from '../../util/server';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Meta } from '../../components/elements/Meta';
export default function Wishlist() {
    const { isUserLogin, app_currency, setCartTotalCount,updatemetas,currency_value } = useFilterContext();
    const [wishlist, setWishlist] = useState([]);
    const { i18n,t } = useTranslation();
    const navigate = useNavigate();
    const token = window.sessionStorage.getItem('local_token')
    useEffect(() => {
        if (isUserLogin === null) {
            navigate('/');
        }
    }, [isUserLogin])

    const getWishlist = async () => {
        try {
            let url = server + "/api/wishlist"
            let res = await axios.get(url, {
                headers: { "Content-Type": "multipart/form-data", "Authorization": token ? `Bearer ${token}` : '' },
            });
            let data = await res.data;
            if (data.status === true) {
                setWishlist(data.data)
                updatemetas(null,0,data.meta)
            } else {
                toast.error(t(data.message))
            }

        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    const removeFromWishlist = async (id) => {
        try {
            let url = server + "/api/remove-from-wishlist/" + id
            let res = await axios.post(url, { 'id': id }, {
                headers: { "Content-Type": "multipart/form-data", "Authorization": token ? `Bearer ${token}` : '' },
            });
            let data = await res.data;
            if (data.status === true) {
                toast.success(t(data.message))
                getWishlist()
            } else {
                toast.error(t(data.message))
            }

        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }


    }

    const handleCart = (product) => {
        let url = server + "/api/add-to-cart"
        axios.post(url, { 'product_id': product.id, 'session_id': isUserLogin, 'cart_session_id': sessionStorage.getItem('local_session') }).then(function (result) {
            if (result.data.status === false) {
                toast.error(t(result.data.message));
            } else {
                toast.success(t(result.data.message));
                setCartTotalCount(result.data.count);
                sessionStorage.setItem('count', result.data.count);
                if (!sessionStorage.getItem('local_session')) {
                    sessionStorage.setItem('local_session', result.data.cart_session_id)
                }
            }
        })
            .catch(function (error) {
                toast.error(t("Unexpected error occurred!"));
            });
    };

    useEffect(() => {
        getWishlist()
    }, [])
    return (
        <>
        <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <Link to="/benutzer/armaturenbrett">
                            <span></span> {t("Dashboard")}
                        </Link>
                        <span></span> {t("Wishlist")}
                    </div>
                </div>
            </div>

            <section className="pt-50 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 m-auto">
                            <div className="row">
                                <Sidebar />

                                <div className="col-lg-9 col-md-8">
                                    <h3 className="mb-0">
                                        {t("Wishlist")}
                                    </h3>
                                    <hr className='mb-4' />

                                    {
                                        wishlist.length === 0 && <h4 className='text-center'>{t("No product in wishlist")}</h4>
                                    }
                                    {
                                        wishlist.length > 0 &&
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} className="text-center">
                                                            {t("Product")}
                                                        </th>
                                                        <th>
                                                            {t("Price")}
                                                        </th>
                                                        <th>
                                                            {t("Actions")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        wishlist.map((item, key) => (
                                                            <tr>
                                                                <td>
                                                                    <img src={item.wishlist_product && item.wishlist_product.main_image && server + '/' + item.wishlist_product.main_image.image}
                                                                        alt={item.wishlist_product && item.wishlist_product.main_image && item.wishlist_product.main_image['alt_' + i18n.language]}

                                                                        width="150px"

                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Link to={"/Produkte/" + item.wishlist_product.slug} className="text-dark"> {item.wishlist_product && item.wishlist_product['name_' + i18n.language]}</Link>

                                                                </td>
                                                                <td>
                                                                    {item.wishlist_product.stock_type === 0 && <span className="text-danger">Sold Out</span>}
                                                                    {item.wishlist_product.stock_type === 1 && item.wishlist_product.discount !== 0 ? <>
                                                                        <del className="old-price">{app_currency}{(item.wishlist_product['original_price_CHF'] * currency_value[app_currency]).toFixed(2)}</del><br />
                                                                        <span>{(item.wishlist_product['price_CHF'] * currency_value[app_currency]).toFixed(2)}</span></> : ''
                                                                    }
                                                                    {item.wishlist_product.stock_type === 1 && item.wishlist_product.discount === 0 ? <span>{app_currency}{(item.wishlist_product['price_CHF'] * currency_value[app_currency]).toFixed(2)}</span> : ''
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <Link to="#" onClick={() => handleCart(item.wishlist_product)} title={t("Add to Cart")} className='bg-danger py-2 px-3 text-light rounded' ><i className="fas fa-shopping-cart"></i></Link>

                                                                    <Link to="#" onClick={() => removeFromWishlist(item.id)} title={t("Remove from Wishlist")} className='bg-dark py-2 px-3 text-light rounded ms-2'><i className="fas fa-trash"></i></Link>

                                                                </td>
                                                            </tr>
                                                        ))
                                                    }


                                                </tbody>
                                            </table>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}
