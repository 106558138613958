import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { server } from "../../util/server";
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';

const BlogList = ({ filterData }) => {
    const {i18n,t} = useTranslation();
    const [currentItems, setCurrentItems] = useState([]);

    useEffect(() => {
        //current paginate products
        setCurrentItems(filterData);
    }, [filterData]);
    return (
        <>
            {
                filterData && filterData.length === 0 && <h3>{t("No Blog Found!")}</h3>  
            }
            {currentItems && currentItems.map((item, i) => (
                <article className="wow fadeIn animated hover-up mb-30" key={i}>
                    <div
                        className="list-fill"
                    >
                       <img src={server+'/'+item.main_img} className="img-fluid" alt={item['alt_'+i18n.language]} />
                    </div>
                    <div className="entry-content-2">
                        <h3 className="post-title mb-15">
                            <Link to={"/bloggen/"+item.slug}>
                                    {item['title_'+i18n.language]}
                            </Link>
                        </h3>
                        <p className="post-exerpt mb-30">
                            {item['main_content_'+i18n.language]}
                        </p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                            <div>
                                <span className="post-on">
                                    {" "}
                                    <i className="fi-rs-clock"></i> {format(new Date(item.created_at), 'do  MMMM yyyy')}
                                </span>
                            </div>
                            <Link to={"/bloggen/"+item.slug} className="text-brand">
                                    {t("Read more")}{" "}
                                    <i className="fi-rs-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </article>
            ))}

        </>
    );
};

export default BlogList;
