const filterReducer = (state, action) => {
    switch (action.type) {
        case "API_ERROR":
            return {
                ...state,
                isError:true
            };

        case "SHOP_PRODUCTS":
            let max_price_Range = Math.ceil(action.payload.max_price_range);
            let min_price_range = Math.trunc(action.payload.min_price_range);
            // }
            return {
                ...state,
                filter_products:action.payload.data,
                product_vendors:action.payload.vendors,
                product_filters:action.payload.filters,
                max_price_Range:(max_price_Range * state.currency_value[state.app_currency]).toFixed(2),
                min_price_range:(min_price_range * state.currency_value[state.app_currency]).toFixed(2)
                // pagination: { paginationstr:action.payload.paginationstr, total:action.payload.total, current: action.payload.current},

            };
        
        case "WEB_SETTINGS":
            return {
                ...state,
                timing:action.payload.timing,
                categories:action.payload.categories,
                brands:action.payload.brands,
                navbar_items:action.payload.navbar_items,
                footer_bottom_bar:action.payload.footer_bottom_bar,
                webSettingsData: action.payload.webSettingsData
              
            }


        case "LIVE_CURRENCY":
            return {
                ...state,
                currency_value: {CHF: "1", USD:(action.payload.CHFUSD).toFixed(2), EUR: (action.payload.CHFEUR).toFixed(2)}
            }
        
       

        case "PRODUCT_SORT_VALUE":
            return {
                ...state,
                sort_value:action.payload
            };
        
        case "PRODUCT_FILTER_VALUE":
            return {
                ...state,
                filter_values:[...state.filter_values, action.payload]
            };

        case "PRODUCT_VENDOR_VALUE":
            return{
                ...state,
                vendorValue:action.payload
            }

        case "PRODUCT_CATEGORY_FILTER_VALUE":
            return {
                ...state,
                category_filter:action.payload
            };

        case "PRODUCT_PRICE_RANGE_VALUE":
            return {
                ...state,
                selected_price_range:action.payload
            }

        case "PRODUCT_BRAND_VALUE":
            return{
                ...state,
                brand_value:action.payload
            }

        case "SET_PAGE_NUMBER":
            return{
                ...state,
                pageNumber:action.payload
            }

        case "CHANGE_CURRENCY":
            localStorage.setItem('UserCurrency', action.payload)
            return{
                ...state,
                app_currency:action.payload
            }

        case "USER_LOGIN":
            sessionStorage.setItem('local_token', action.payload)
            return{
                ...state,
                isUserLogin:action.payload
            }

        case "USER_LOGOUT":
            sessionStorage.removeItem('local_token');
            sessionStorage.setItem('count', 0)
            sessionStorage.removeItem('local_session')
            return{
                ...state,
                isUserLogin:null,
                cartTotalCount:0
            }

        case "CART_COUNT":
            sessionStorage.setItem('count', action.payload);
            return {
                ...state,
                cartTotalCount:action.payload
            }

        case "SORT_PRODUCTS":
            let tempProduct = state.filter_products;
            let sortedProducts = [];

            const sortProductFunction = (a,b) => {
                if(state.sort_value === 'sort_price_asc') {
                    return a.price_CHF > b.price_CHF ? 1 : -1;
                }else if(state.sort_value === 'sort_price_desc'){
                    return a.price_CHF < b.price_CHF ? 1 : -1;
                }
                else if(state.sort_value === 'sort_rating'){
                    return a.rating < b.rating ? 1 : -1;
                }
                else if(state.sort_value === 'sort_latest'){
                   return a.id < b.id ? 1 : -1;
                }
                else if(state.sort_value === 'sort_popularity'){
                    return a.orders < b.orders ? 1 : -1;
                }
            }
            
            sortedProducts = tempProduct.sort(sortProductFunction);
            return {
                ...state,
                filter_products:sortedProducts
            };


        case "PRODUCT_PRICE_RANGE":
                return {
                    ...state,
                    filter_products:state.filter_products.filter((item) => (item.price_CHF <= state.selected_price_range.split('-')[1] && item.price_CHF >= state.selected_price_range.split('-')[0]))
                };

        case "CLEAR_FILTERS":
            if(action.payload === 'query'){
                return{
                    ...state,
                    search_query:null,
                    pageNumber:1
                }
            }else if(action.payload === 'category'){
                    return{
                        ...state,
                        filter_values: [],
                        brand_value: action.payload === 'brand' ? state.brand_value : null,
                        selected_price_range: null,
                        vendorValue:null,
                        pageNumber:1
                    }
            }else{
                return{
                    ...state,
                    filter_values: [],
                    brand_value: action.payload === 'brand' ? state.brand_value : null,
                    category_filter: null,
                    selected_price_range: null,
                    vendorValue:null,
                    pageNumber:1
                };
            }

        case "SHOP_INITIAL_LOAD_HANDLER": 
        if(action.payload.type === 'brand'){
            return {
                ...state,
                filter_values: [],
                brand_value:action.payload.value,
                category_filter: null,
                selected_price_range: null,
                vendorValue:null,
                search_query:null,
                page:null
            }
        }else if(action.payload.type ==='category'){
            return {
                ...state,
                filter_values: [],
                brand_value:null,
                selected_price_range: null,
                vendorValue:null,
                category_filter:action.payload.value,
                search_query:null,
                page:null
            }
        }else if(action.payload.type === 'shop'){
            return {
                ...state,
                filter_values: [],
                brand_value:null,
                selected_price_range: null,
                vendorValue:null,
                category_filter:null,
                search_query:null,
                page:null
            }
        }else{
            return {
                ...state
            }
        }
            

        case "COUPON":
            if(action.payload === null){
                sessionStorage.removeItem('cp');
            }else{
                sessionStorage.setItem('cp', JSON.stringify(action.payload));
            }
            
            return{
                ...state,
                coupon: action.payload
            };

        case "UNSET_ORDER_DATA":
            sessionStorage.setItem('count', 0);
            sessionStorage.removeItem('local_Session')
            sessionStorage.removeItem('cp')
            return{
                ...state,
                coupon: null,
                cartTotalCount:0
            };

        case "PAGE_NUMBER_VALUE":
            return{
                ...state,
                page:action.payload
            }

        case "UPDATE_METAS":
            return{
                ...state,
                meta:action.payload
            }

        case "ADD_RECENTLY_VIEWED_PRODUCT": 
            const index = state.recentlyViewedProducts.findIndex((item) => item.id === action.payload.id);
            if(index === -1 || state.recentlyViewedProducts.length === 0){
                sessionStorage.setItem('recently_viewed_products', JSON.stringify([...state.recentlyViewedProducts, action.payload]));
                return{
                    ...state,
                    recentlyViewedProducts: [...state.recentlyViewedProducts, action.payload]
                };
            }else{
                return{
                    ...state
                };
            }

        case "SET_SEARCH_QUERY":
            var s2 = action.payload.slice(1);
            var query_array = s2.split('&');
            var query_para = query_array.filter(item => item.includes('query='))
            var cat_para = query_array.filter(item => item.includes('category_filter='))
            return{
                ...state,
                category_filter: cat_para.length > 0 ? cat_para[0].split('=')[1] : state.category_filter,
                search_query: query_para.length > 0 ? query_para[0].split('=')[1] : state.search_query,
            };
    
        default:
            return state;
    }
}

export default filterReducer;