import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {useFilterContext} from '../../context/filterContext';
const Search = ({categories}) => {
    const { i18n,t } = useTranslation();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [category, setCategory] = useState("all");
    const {app_currency} = useFilterContext();
    let query = null;

    const handleSearch = () => {
        if(category === 'all'){
            query = '?query='+searchTerm+'&lang='+i18n.language+'&currency='+app_currency;
        }else{
            query = '?query='+searchTerm+'&category_filter='+category+'&lang='+i18n.language+'&currency='+app_currency;
        }
        navigate('/suche'+query)
        setSearchTerm("");
    };

    const handleInput = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if(searchTerm){
                handleSearch();
            }else{
                toast.error(t("Please enter query"))
            }
            
        }
    };
    return (
        <>
            <form>
                <select className="select-active" value={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value="all">{t("Categories")}</option>
                    {
                        categories.length > 0 && categories.map(item => (
                            <option value={item.id}>{item['name_'+i18n.language]}</option>
                        ))
                    }
                </select>
                <input
                    value={searchTerm}
                    onKeyDown={handleInput}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    type="text"
                    placeholder={t("Search")}
                    id="search"
                />
            </form>

            {/* <Button>Search</Button> */}
            {/* <button onClick={handleSearch}>search</button> */}
        </>
    );
};

export default Search;
