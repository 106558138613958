import {Link} from "react-router-dom";
import { useState, useEffect } from 'react';
import {useFilterContext} from '../context/filterContext';
import { server } from "../util/server";
import axios from 'axios';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { Meta } from "../components/elements/Meta";
const Cart = () => {
    const { i18n,t } = useTranslation();
    const [cartItems, setCartItems] = useState([])
    const [cartTotal, setCartTotal] = useState(0);
    const [preDiscountcartTotal, setpreDiscountCartTotal] = useState(0);
    const token = window.sessionStorage.getItem('local_token')
    const {app_currency,setCartTotalCount,isUserLogin,setCoupon,coupon,unsetOrderData,updatemetas,currency_value} = useFilterContext();
    const getCartItems = async () => {
        const res = await axios.get(server+`/api/cart?session_id=${sessionStorage.getItem('local_session')}`);
        const response = await res.data;
        if(response.status === false && response.message){
            toast.error(t(response.message));
        }else{
            setCartItems(response.data.cart)
            setCartTotal(response.data['cart_total_CHF'])
            setpreDiscountCartTotal(response.data['pre_discount_total'])
        }
       
    }

    useEffect(() => {
        updatemetas('cart',4)
    },[]);

    useEffect(() => {
        getCartItems();
    },[app_currency]);

    const updateCart = (event, id, type) => {
        fetch(server+"/api/update-cart",{
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({'cart_item_id':id, 'session_id': sessionStorage.getItem('local_session'), 'type': type})
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status === true){
                    toast.success(t(result.message));
              
                }else{
                    toast.error(t(result.message));
                }

                setCartItems(result.data.cart)
                setCartTotal(result.data['cart_total_CHF'])
                setCartTotalCount(result.data.count);
                setpreDiscountCartTotal(result.data['pre_discount_total'])
               
              
            },
            (error) => {
                toast.error(t("Unexpected error occurred!"));
            }
        )
    }

    const removeFromCart = (event, id) => {
        fetch(server+"/api/remove-from-cart",{
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({'cart_item_id':id, 'session_id': sessionStorage.getItem('local_session'), 'coupon_code': coupon ? coupon['coupon_code'] : null, 'token': sessionStorage.getItem('local_token')})
        })
        .then(res => res.json())
        .then(
            (result) => {
                if(result.status === true){
                    toast.success(t(result.message))
                    setCartItems(result.data.cart)
                    setCartTotal(result.data.cart_total_CHF)
                    setCartTotalCount(result.data.count);
                    setpreDiscountCartTotal(result.data['pre_discount_total'])
                }else{
                    toast.error(t(result.message))
                }
                //coupon data
                // if(result.data.update_coupon === false){
                //     setCoupon(null);
                //     result.data.coupon_data && toast.error(t(result.data.coupon_data))
                // }else if(result.data.update_coupon === true){
                //     setCoupon(result.data.coupon_data.coupon);
                // }
               
               
            },
            (error) => {
                toast.error(t("Unexpected error occurred!"));
            }
        )
    }

    const clearCart = (event) => {
        fetch(server+"/api/clear-cart",{
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({'session_id': sessionStorage.getItem('local_session')})
        })
        .then(res => res.json())
        .then(
            (result) => {
                toast.success(t(result.message));
                setCartItems([])
                setCartTotal(0)
                unsetOrderData();
                setCoupon(null);
                setpreDiscountCartTotal(0)
            },
            (error) => {
                toast.error(t("Unexpected error occurred!"));
            }
        )
    }

    //apply coupon
    const applyCoupon = () => {
        let coupon_code = document.getElementById('coupon_code').value;
        if( !coupon_code ){
            toast.error(t("Please enter coupon code to proceed!"));
        }else if(isUserLogin === null){
            toast.error(t("Please login to continue"));
        }
        else{
            fetch(server+"/api/coupon",{
            method: "POST",
            headers: {"Content-Type": "application/json",  "Authorization": token ? `Bearer ${token}` : ''},
            body: JSON.stringify({'coupon_code':coupon_code})
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result.data.status === true){
                        toast.success(t(result.data.message));
                        setCoupon(result.data.coupon);
                        document.getElementById('coupon_code').value = null;
                        getCartItems()
                    }else{
                        toast.error(t(result.data.message));
                    }
                  
                },
                (error) => {
                    toast.error(t("Unexpected error occurred!"))
                }
            )
        }

       
    }


    return (
        <>
        <Meta />
           <div className={`page-header breadcrumb-wrap`}>
                    <div className="container">
                        <div className="breadcrumb">
                            <Link to="/">
                               {t("Home")}
                            </Link>
                            <span></span> {t("Reservation")}
                        </div>
                    </div>
                </div>
                <section className="mt-50 mb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    {cartItems.length === 0 && t("Empty Cart")}
                                    <table className={cartItems.length > 0 ? "table shopping-summery text-center clean" : "d-none"}>
                                        <thead>
                                            <tr className="main-heading">
                                                <th scope="col">{t("Image")}</th>
                                                <th scope="col" className="text-start">{t("Name")}</th>
                                                <th scope="col">{t("Price")}</th>
                                                <th scope="col">{t("Quantity")}</th>
                                                <th scope="col">{t("Subtotal")}</th>
                                                <th scope="col">{t("Remove")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {cartItems.length > 0 && cartItems.map((item, i) => (
                                                <tr key={i}>
                                                    <td className="image product-thumbnail">
                                                        {
                                                            item.cart_product && item.cart_product.main_image && 
                                                            <img src={item.cart_product && server+'/'+item.cart_product.main_image.image} alt={item.cart_product && item.cart_product.main_image.alt_en}/>
                                                        }
                                                        
                                                    </td>

                                                    <td className="product-des product-name text-start">
                                                        <h5 className="product-name">
                                                            {item.cart_product['name_'+i18n.language]}
                                                        </h5>
                                                        <p className="fst-italic font-14">SKU: {item.sku}</p>
                                                        {
                                                            item.var_id &&
                                                               <p className="fst-italic font-14">{item.var_data}</p>
                                                        }
                                                    </td>
                                                    <td className="price" data-title="Price">
                                                        {item.coupon_discount !== 0 ? <del className="d-block">{app_currency}{(item['coupon_discount']*currency_value[app_currency] + item['price_CHF']*currency_value[app_currency]).toFixed(2)}</del> : ''}
                                                        {item['price_CHF'] !== item['actual_price_CHF'] && item.coupon_discount === 0 ? <del className="d-block">{app_currency}{(item['actual_price_CHF']*currency_value[app_currency]).toFixed(2)}</del> : ''}
                                                        <span>{app_currency} {(item['price_CHF']*currency_value[app_currency]).toFixed(2)}</span>
                                                    </td>
                                                    <td className="text-center" data-title="Stock">
                                                        <div className="detail-qty border radius  m-auto">
                                                            <Link 
                                                             onClick={(event) => updateCart(event, item.id, 'decrement')}
                                                            className="qty-down">
                                                                <i className="fi-rs-angle-small-down"></i>
                                                            </Link>
                                                            <span className="qty-val">{item.quantity}</span>
                                                            <Link 
                                                            onClick={(event) => updateCart(event, item.id, 'increment')}
                                                            className="qty-up">
                                                                <i className="fi-rs-angle-small-up"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className="text-right" data-title="Cart">
                                                        {item.coupon_discount !== 0 ? <del className="d-block">{app_currency}{((item['coupon_discount']*currency_value[app_currency] + item['price_CHF']*currency_value[app_currency]) * item.quantity).toFixed(2)}</del> : ''}
                                                        {item['price_CHF'] !== item['actual_price_CHF'] && item.coupon_discount === 0 ? <del className="d-block">{app_currency}{((item['actual_price_CHF']*currency_value[app_currency]) * item.quantity).toFixed(2)}</del> : ''}
                                                        <span>{app_currency} { (item['price_CHF']*currency_value[app_currency] * item.quantity).toFixed(2)}</span>
                                                    </td>
                                                    <td className="action" data-title="Remove">
                                                        <Link 
                                                        onClick={(event) => removeFromCart(event, item.id)}
                                                        className="text-muted">
                                                            <i className="fi-rs-trash"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan="6" className="text-end">
                                                    {cartItems.length > 0 && (
                                                        <Link 
                                                        onClick={(event) => clearCart(event)}
                                                         className="text-muted">
                                                            <i className="fi-rs-cross-small"></i>
                                                            {t("Clear Cart")}
                                                        </Link>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="cart-action text-end">
                                    <Link className="btn" to="/shop">
                                        <i className="fi-rs-shopping-bag mr-10"></i>
                                        {t("Continue Shopping")}
                                    </Link>
                                </div>
                                <div className="divider center_icon mt-50 mb-50">
                                    <i className="fi-rs-fingerprint"></i>
                                </div>
                                <div className="row mb-50">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="mb-30 mt-50">
                                            <div className="heading_s1 mb-3">
                                                <h4>{t("Apply Coupon")}</h4>
                                            </div>
                                            <div className="total-amount">
                                                <div className="left">
                                                    <div className="coupon">
                                                        <form method="post">
                                                            <div className="form-row row justify-content-center">
                                                                <div className="form-group col-lg-6">
                                                                    <input className="font-medium" id="coupon_code" name="Coupon" placeholder={t("Enter Your Coupon")} />
                                                                </div>
                                                                <div className="form-group col-lg-6">
                                                                    <button className="btn  btn-sm" type="button" onClick={() => applyCoupon()}>
                                                                        <i className="fi-rs-label mr-10"></i>
                                                                        {t("Apply")}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="border p-md-4 p-30 border-radius cart-totals">
                                            <div className="heading_s1 mb-3">
                                                <h4>{t("Cart Total")}</h4>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        {/* <tr>
                                                            <td className="cart_total_label">{t("Cart Subtotal")}</td>
                                                            <td className="cart_total_amount">
                                                                <span className="font-lg fw-900 text-brand">{app_currency}{(cartTotal *currency_value[app_currency]).toFixed(2)}</span>
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td className="cart_total_label">{t("Shipping")}</td>
                                                            <td className="cart_total_amount">
                                                                <i className="ti-gift mr-5"></i>
                                                                {t("Store Pickup")}
                                                            </td>
                                                        </tr>
                                                         
                                                         {
                                                            coupon && 
                                                            <tr>
                                                                <td className="cart_total_label">{t("Coupon")}</td>
                                                                <td className="cart_total_amount">
                                                                    <i className="ti-gift mr-5"></i>
                                                                    {coupon['coupon_code']}
                                                                </td>
                                                            </tr>
                                                         }
                                                        

                                                        <tr>
                                                            <td className="cart_total_label">{t("Total")}</td>
                                                            <td className="cart_total_amount">
                                                                <strong>
                                                                    <span className="font-xl fw-900 text-brand">
                                                                        {
                                                                            coupon && <del className="me-3">{app_currency} {(preDiscountcartTotal *currency_value[app_currency]).toFixed(2)}</del>
                                                                        }
                                                                        {app_currency}{(cartTotal *currency_value[app_currency]).toFixed(2)}
                                                                    </span>
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Link to="/kasse" className="btn ">
                                                <i className="fi-rs-box-alt mr-10"></i>
                                                {t("Proceed To Checkout")}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
      
        </>
    );
};


export default Cart;
