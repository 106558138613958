import React from 'react'
import { useFormik } from 'formik';
import { registerSchema } from '../../schemas';
import { toast } from "react-toastify";
import { server } from '../../util/server';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const initialValues = {
    email: '',
    password: '',
    cpassword: '',
    policy_check: false
}

export default function RegistrationForm() {
    const { t } = useTranslation();
    const submitSignupData = async (query, actions) => {
        try {
            let res = await axios.post(server + "/api/customer-registration", query);
            let data = await res.data;
            if (data.status === false) {
                if (data.message) { toast.error(t(data.message)) }

                if (data.errors) {
                    Object.keys(data.errors).forEach(key => {
                        data.errors[key].map(item => {
                            toast.error(t(item));
                        })

                    })
                }
            } else {
                toast.success(t(data.message));
                actions.resetForm();
            }

        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }

    }

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: registerSchema,
        onSubmit: async (values, actions) => {
            submitSignupData(values, actions);
        }
    });
    return (
        <form method="post" onSubmit={handleSubmit}>
            <div className="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder={t("Email")}
                    value={values.email} onBlur={handleBlur} onChange={handleChange} />
                <small className='text-danger fw-bold'>{errors.email && touched.email && errors.email}</small>
            </div>
            <div className="form-group">
                <input
                    type="password"
                    name="password"
                    placeholder={t("Password")}
                    value={values.password} onBlur={handleBlur} onChange={handleChange} />
                <small className='text-danger fw-bold'>{errors.password && touched.password && errors.password}</small>
            </div>
            <div className="form-group">
                <input
                    type="password"
                    name="cpassword"
                    placeholder={t("Confirm password")}
                    value={values.cpassword} onBlur={handleBlur} onChange={handleChange} />
                <small className='text-danger fw-bold'>{errors.cpassword && touched.cpassword && errors.cpassword}</small>
            </div>
            <div className="login_footer form-group">
                <div className="chek-form">
                    <div className="custome-checkbox">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="policy_check"
                            id="exampleCheckbox"
                            value={values.policy_check} onBlur={handleBlur} onChange={handleChange} />
                        <label
                            className="form-check-label"
                            htmlFor="exampleCheckbox"
                        >
                            <span>
                                {t("I agree to Terms & Conditions.")}
                            </span>
                        </label>
                        <br /><small className='text-danger fw-bold'>{errors.policy_check && touched.policy_check && errors.policy_check}</small>
                    </div>
                </div>

            </div>
            <div className="form-group">
                <button
                    type="submit"
                    className="btn btn-fill-out btn-block hover-up"
                    name="login"
                >
                    {t("Register")}
                </button>
            </div>
        </form>
    )
}
