import React, { useEffect, useState } from "react";
import ProductDetails from "../../components/ecommerce/ProductDetails";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from '../../util/server';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../../context/filterContext';
import { Meta } from "../../components/elements/Meta";
import Spinner from "../../components/Spinner/Main";
const ProductDetail = () => {
    const { slug } = useParams();
    const [product, setProduct] = useState(null);
    const [variation, setVariation] = useState(null);
    const [similarProduct, setSimilarProduct] = useState([]);
    const { i18n, t } = useTranslation();
    const { setrecentlyViewedProducts, updatemetas } = useFilterContext();
    const [spinner, setSpinner] = useState(true);

    const getSingleProduct = async (slug) => {
        try {
            let url = server + "/api/product-detail/" + slug
            let res = await axios.get(url);
            let product = await res.data;
            if (product.status === false) {
                toast.error(t(product.message));
                setSpinner(false)
            } else {
                setProduct(product.product)
                setSimilarProduct(product.similar_products)
                setrecentlyViewedProducts(product.product, 'add')
                updatemetas(null, 0, product.meta)
                setVariation(product.variation)
                setSpinner(false)
            }
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
            setSpinner(false)
        }
    }

    useEffect(() => {
        getSingleProduct(slug);

    }, [slug])






    return (
        <>
            <Meta />
            {spinner ? <Spinner /> :
                <>
                    <div className={`page-header breadcrumb-wrap`}>
                        <div className="container">
                            <div className="breadcrumb">
                                <Link to="/">
                                    {t("Home")}
                                </Link>
                                <Link to="/shop">
                                    <span></span> {t("Shop")}
                                </Link>
                                <span></span> {product && product['name_' + i18n.language]}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <ProductDetails product={product} similarProduct={similarProduct} variation={variation} slug={slug} />
                    </div>

                </>
            }
        </>
    );
};





export default ProductDetail;