import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import {server} from '../../util/server';

SwiperCore.use([Navigation]);

const CategorySlider = ({categories}) => {
    const { i18n } = useTranslation();
    return (
        <>
            <Swiper
                slidesPerView={6}
                spaceBetween={20}
                breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        500: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1000:{
                            slidesPerView: 4,
                        },
                        1200: {
                            slidesPerView: 6,
                        },
                  }}
                navigation={{
                    prevEl: ".custom_prev_ct1",
                    nextEl: ".custom_next_ct1",
                }}
                className="custom-class"
            >
                {categories.map((item, i) => (
                    <SwiperSlide key={i}>
                        <div className="card-1">
                            <figure className=" img-hover-scale overflow-hidden">
                               <Link to={"/"+item.slug}>
                                        <img
                                            src={item.image && server+"/"+item.image}
                                            alt={item['alt_'+i18n.language]}
                                        />
                                </Link>
                            </figure>
                            <h5>
                               <Link to={"/"+item.slug}>
                                    {item['name_'+i18n.language]}
                                </Link>
                            </h5>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div
                className="slider-arrow slider-arrow-2 carausel-6-columns-arrow"
                id="carausel-6-columns-arrows"
            >
                <span className="slider-btn slider-prev slick-arrow custom_prev_ct1">
                    <i className="fi-rs-angle-left"></i>
                </span>
                <span className="slider-btn slider-next slick-arrow custom_next_ct1">
                    <i className="fi-rs-angle-right"></i>
                </span>
            </div>
        </>
    );
};

export default CategorySlider;
