import axios from "axios";
import {useEffect} from 'react';
import { createContext, useContext, useReducer } from "react";
import reducer from '../reducer/filterReducer';
import {server} from '../util/server';
import { useTranslation } from "react-i18next";

const FilterContext = createContext();

const initialState = {
    isError:false,
    filter_products: [],
    product_vendors: [],
    product_filters: [],
    // pagination: {paginationstr:null, total:0, current:0},
    currency_value:{CHF: 1, USD:1.08, EUR: 1.01},
    sort_value: null,
    category_filter:null,
    page:null,
    vendorValue:null,
    filter_values:[],
    min_price_range:0,
    max_price_Range:200,
    selected_price_range:null,
    brand_value:null,
    search_query:null,
    app_currency: (localStorage.getItem('UserCurrency')) ? localStorage.getItem('UserCurrency') : 'CHF',
    isUserLogin: (sessionStorage.getItem('local_token')) ? sessionStorage.getItem('local_token') : null,
    cartTotalCount: (sessionStorage.getItem('count')) ? sessionStorage.getItem('count') : 0,
    coupon: (sessionStorage.getItem('cp')) ? JSON.parse(sessionStorage.getItem('cp')) : null,
    webSettingsData:null,
    timing:null,
    categories:[],
    brands:[],
    navbar_items:[],
    footer_bottom_bar:[],
    recentlyViewedProducts:(sessionStorage.getItem('recently_viewed_products')) ? JSON.parse(sessionStorage.getItem('recently_viewed_products')) : [],
    meta: null,
    pageNumber:1

}

export const FilterContextProvider = ({children}) => {
    const { i18n } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const initailWebsiteSettingData = async() => {
        try {
            let res = await axios.get(server+"/api/website-settings");
            let data = await res.data;
            return dispatch({type:"WEB_SETTINGS", payload: data})
        } catch (error) {
            return dispatch({type:"API_ERROR"})
        }
    }
    useEffect(() => {
        initailWebsiteSettingData()
    },[]);


    //shop page initial products
    const getShopProducts = async (data = null) => {
        try {
            let shop_url = data ? server+`/api/shop` +data : server+"/api/shop?currency=CHF"
            let res = await axios.get(shop_url);
            let product = await res.data;
        return dispatch({type:"SHOP_PRODUCTS", payload: product})
        } catch (error) {
            return dispatch({type:"API_ERROR"})
        }
    }


    //update meta
    const updatemetas = (page,page_type=4,meta=null) => {
        if(page_type===0){
            dispatch({type: 'UPDATE_METAS', payload: meta})
        }else{
            try{
                fetch(`${server}/api/meta-data?page=${page}&page_type=${page_type}`).then((response) => response.json())
                .then((data) => dispatch({type: 'UPDATE_METAS', payload: data.meta}));
                
            }catch(error){
                return dispatch({type:"API_ERROR"})
            }
        }
       
       
    }

    //getCurrencyData
    const getCurrencyData = () => {
       
        var myHeaders = new Headers();
        myHeaders.append("apikey", "mxVJJ9w9Ltz1c8z4fC2JoeKMECs4GEGW");
        
        var requestOptions = {
          method: 'GET',
          redirect: 'follow',
          headers: myHeaders
        };
        
        fetch("https://api.apilayer.com/currency_data/live?source=CHF&currencies=EUR%2CCHF%2CUSD", requestOptions)
          .then(response => response.text())
          .then(result => {
                var result = JSON.parse(result);
                if(result.success === true){
                    dispatch({type:"LIVE_CURRENCY", payload: result.quotes})
                }
          })
          .catch(error => dispatch({type:"API_ERROR"}));
    }

    //global sorting method
    const productSortHandler = (event) => {
        let selected_sort_value = event.target.value;
        dispatch({type: 'PRODUCT_SORT_VALUE', payload: selected_sort_value})
    }

    //global filter method
    const productFilterHandler = (value) => {
        if(state.filter_values.indexOf(value) === -1){
            dispatch({type: 'PRODUCT_FILTER_VALUE', payload: value});
        }
    }

    const productCategoryFilter = (value) => {
        dispatch({type: 'PRODUCT_CATEGORY_FILTER_VALUE', payload: value})
       
    }

    //apply price range
    const applyPriceRange = (value) => {
        dispatch({type: 'PRODUCT_PRICE_RANGE_VALUE', payload: value});
    }

    //apply brand
    const productBrandHandler = (value) => {
        dispatch({type: 'PRODUCT_BRAND_VALUE', payload: value});
    }

    const ShopInitalLoadHandler = (data) => {
        dispatch({type: 'SHOP_INITIAL_LOAD_HANDLER', payload: data});
    }

    const productVendorHandler = (value) => {
        dispatch({type: 'PRODUCT_VENDOR_VALUE', payload: value});
    }

    //clear filter
    const clearFilter = (type = null) => {
        dispatch({type: 'CLEAR_FILTERS', payload:type});
    }

    //currency converter
    const ChangeCurrency = (currency) => {
        dispatch({type: 'CHANGE_CURRENCY', payload: currency});
    }

    //set customer login
    const setUserLogin = (token) => {
        dispatch({type: 'USER_LOGIN', payload:token});
    }

    //unset customer login
    const unsetUserLogin = () => {
        dispatch({type: 'USER_LOGOUT'});
    }

    //set cart total quantity
    const setCartTotalCount = (count) => {
        dispatch({type: 'CART_COUNT', payload:count});
    }

    //coupon
    const setCoupon = (data) => {
        dispatch({type: 'COUPON', payload:data});
    }

    const unsetOrderData = () => {
        dispatch({type: 'UNSET_ORDER_DATA'});
    }

    const setSearchQuery = (data) => {
        dispatch({type: "SET_SEARCH_QUERY", payload: data})
    }
    
    const setrecentlyViewedProducts = (data= null, type) => {
            if(type === 'add'){
                dispatch({type:'ADD_RECENTLY_VIEWED_PRODUCT', payload: data});
                
            }
    }

    const setPageValue = (data) => {
        dispatch({type: 'PAGE_NUMBER_VALUE', payload:data});
    }

    const setPageNumber = (number) => {
        dispatch({type: 'SET_PAGE_NUMBER', payload:number});
    }

    //sort products
    useEffect(() => {
        let filters = '?currency='+state.app_currency+'&lang='+i18n.language;
        let operator = '&';
        if(state.search_query !== null){
            filters += operator+"query="+state.search_query
        }

        if(state.category_filter !== null){
            filters += operator+"category_filter="+state.category_filter
        }
        if(state.brand_value !== null){
            filters += operator+"brand="+state.brand_value
        }
        if(state.selected_price_range !== null){
            filters += operator+"price_range="+state.selected_price_range
        }
        if(state.filter_values.length !== 0){
            filters += operator+"filter_values="+state.filter_values.toString()
        }
        if(state.sort_value !== null){
            filters += operator+"sort="+state.sort_value
        }

        if(state.vendorValue !== null){
            filters += operator+"vendor="+state.vendorValue
        }

        if(state.pageNumber){
            filters += operator+"page="+state.pageNumber
        }

        // if(state.page !== null){
        //     filters += operator+"page="+state.page
        // }
        if(window.location.href.endsWith("shop") || filters !== '?currency='+state.app_currency+'&lang='+i18n.language){
            getShopProducts(filters)
        }
        // else if(window.location.pathname !== '/shop' && filters === '?currency='+state.app_currency+'&lang='+i18n.language){

        // }else if(filters !== '?currency='+state.app_currency+'&lang='+i18n.language){
        //     getShopProducts(filters)
        // }
     
    }, [state.sort_value, state.filter_values, state.selected_price_range, state.brand_value,state.category_filter, state.search_query,state.vendorValue,state.pageNumber])
    

    return ( 
        <FilterContext.Provider value={{ ...state, dispatch, getShopProducts,productSortHandler,productFilterHandler,applyPriceRange ,clearFilter,productBrandHandler,ChangeCurrency,setUserLogin,unsetUserLogin,setCartTotalCount,setCoupon,productCategoryFilter,unsetOrderData,setrecentlyViewedProducts,setSearchQuery,productVendorHandler,ShopInitalLoadHandler,updatemetas,setPageValue,getCurrencyData,setPageNumber}}>
        {children}
        </FilterContext.Provider>
    )
   
}

//custom hook
export const useFilterContext = () => {
     return useContext(FilterContext);
}

export const getUniqueBrandData = (array) => {
    let newVel = array.map((item) => { return item['brand']['id']+':'+item['brand']['name'] });  
    return (newVel = [...new Set(newVel)]);
};
