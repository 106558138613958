
import {server} from '../../util/server';
import { useTranslation } from 'react-i18next';
const BannerFeatures = ({homePageContent}) => {
    const { i18n} = useTranslation();
    return (
        <>
            {homePageContent['home_section_8'] && homePageContent['home_section_8'].map((item, i) => (
                <div className="col-xl-2 col-md-4 col-sm-6 mb-lg-3 mb-md-3 mb-sm-3 mb-xl-0" key={i}>
                    <div className="banner-features wow fadeIn animated hover-up">
                        <img
                            src={server+'/'+item['link']}
                            alt={item['alt_'+i18n.language]}
                        />
                        <h4 className="bg-6">{item['text_'+i18n.language]}</h4>
                    </div>
                </div>
            ))}
        </>
    );
};

export default BannerFeatures;
