import React from "react";
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';
import { server } from "../../util/server";
import {FacebookShareButton,FacebookIcon,LinkedinShareButton,LinkedinIcon,TwitterIcon,TwitterShareButton,PinterestIcon,PinterestShareButton, WhatsappIcon,WhatsappShareButton} from 'react-share'
import BlogComments from "./BlogComments";

const BlogSingle = ({singleBlog}) => {
    const {i18n,t} = useTranslation();
    const url = window.location.href;
    return (
        <>
            <div className="single-page pl-30">
                
                    {!singleBlog && <h3 className="text-center">{t("Blog does not exist")}</h3>}
                    {
                        singleBlog && 
                        <>
                            <div className="single-header style-2">
                                <h1 className="mb-30">
                                { singleBlog['title_'+i18n.language]}
                                </h1>
                                <div className="single-header-meta">
                                    <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                                        <span className="post-on has-dot">
                                            {singleBlog && singleBlog.blog_category['type_value_'+i18n.language]}
                                        </span>
                                        <span className="post-on has-dot">{ singleBlog && format(new Date(singleBlog.created_at), 'do  MMMM yyyy')}</span>
                                    </div>

                                    <div className="social-icons single-share entry-meta">
                                        <ul className="text-grey-5 d-inline-block">
                                            <li>
                                                <strong className="mr-10">{t("Share this")}:</strong>
                                            </li>
                                            <li>
                                                <FacebookShareButton url={url} style={{ marginTop:'-14px', marginLeft:'3px' }}>
                                                    <FacebookIcon size={25} round={true}/>
                                                </FacebookShareButton> 
                                            </li>
                                           <li>
                                                <PinterestShareButton url={url} media={url} style={{ marginTop:'-14px', marginLeft:'3px' }}>
                                                    <PinterestIcon size={25} round={true} />
                                                </PinterestShareButton> 
                                           </li>
                                           <li>
                                                <TwitterShareButton url={url} style={{ marginTop:'-14px', marginLeft:'3px' }}>
                                                        <TwitterIcon size={25} round={true}/>
                                                </TwitterShareButton> 
                                           </li>
                                           <li>
                                                <LinkedinShareButton url={url} style={{ marginTop:'-14px', marginLeft:'3px' }}>
                                                        <LinkedinIcon size={25} round={true}/>
                                                </LinkedinShareButton> 
                                           </li>
                                           <li>
                                                <WhatsappShareButton url={url} style={{ marginTop:'-14px', marginLeft:'3px' }}>
                                                        <WhatsappIcon size={25} round={true}/>
                                                </WhatsappShareButton> 
                                           </li>
                                          
                                        </ul>
                                    </div>

                                </div>
                            </div>
                              
                            <figure className="single-thumbnail">
                                <img src={server+"/"+singleBlog.main_img} alt={singleBlog['alt_'+i18n.language]} />
                            </figure>
                            <div className="single-content">
                                <div className="row">
                                {
                                    singleBlog && singleBlog.blog_detail.length > 0 &&
                                    singleBlog.blog_detail.map(item => (
                                        <div className={item.cols}>
                                        { item.content_type === 'img' ? 
                                         <img src={item.blog_image && server+"/storage/media/"+item.blog_image.image} />
                                        
                                         :
                                         <div dangerouslySetInnerHTML={{ __html:item['content_'+i18n.language]}}/>}
                                        </div>
                                    ))
                                }
                                </div>
                            </div>

                            <BlogComments singleBlog={singleBlog} />
                        </>
                    }
                   
                    
             
                 
                
                
            </div>
        </>
    );
};

export default BlogSingle;
