import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useFilterContext } from '../../context/filterContext';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import { userProfile } from '../../schemas';
import { useFormik } from 'formik';
import { server } from '../../util/server';
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Meta } from '../../components/elements/Meta';


export default function Profile() {
    const {  t } = useTranslation();
    const { isUserLogin, updatemetas } = useFilterContext();
    const [user, setUser] = useState(null);
    const token = window.sessionStorage.getItem('local_token')
    const navigate = useNavigate();

    useEffect(() => {
        if (isUserLogin === null) {
            navigate('/');
        }
    }, [isUserLogin])

    const getUserData = async () => {
        let url = server + "/api/user"
        axios({
            method: "get",
            url: url,
            headers: { "Content-Type": "multipart/form-data", "Authorization": token ? `Bearer ${token}` : '' },
        }).then(function (result) {
            setUser(result.data)
        })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error(t("Please login to continue"));
                } else {
                    toast.error(t("Unexpected error occurred!"));
                }
            });
    }

    useEffect(() => {
        getUserData();
        updatemetas('benutzer/Profil',4)
    }, []);

    const initialValues = {
        first_name: user ? user.first_name : '',
        last_name: user ? user.last_name : '',
        country: user && user.country ? user.country : 'Switzerland',
        city: user ? user.city : '',
        zip_code: user ? user.post_code : '',
        phone_number: user ? user.phone_number : '',
        email: user ? user.email : ''
    };
    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues,
        validationSchema: userProfile,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
           try{
                let res = await fetch(server + "/api/update-profile", {
                    method: "POST",
                    headers: { "Authorization": token ? `Bearer ${token}` : '', 'Content-Type' : 'application/json' },
                    body: JSON.stringify(values),
                })
                let result = await res.json()
                if (result.status === true) {
                    toast.success(t(result.message));
                } else {
                    toast.error(t(result.message));
                }
           }catch(error){
                toast.error(t("Unexpected error occurred!"));
           }
            
        }
    });

    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <Link to="/benutzer/armaturenbrett">
                        <span></span> {t("Dashboard")}
                        </Link>
                        <span></span> {t("Profile")}
                    </div>
                </div>
            </div>

            <section className="pt-50 pb-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 m-auto">
                            <div className="row">
                                <Sidebar />

                                <div className="col-lg-9 col-md-8">
                                    <h3 className="mb-0">{t("Profile")}</h3>
                                    <hr className='mb-4' />
                                    <form method="post" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <label>{t("First Name")}</label>
                                                    <input type="text" className="form-control" name="first_name" value={values.first_name} onBlur={handleBlur} onChange={handleChange} />
                                                    <small className='text-danger fw-bold'>{errors.first_name && touched.first_name && errors.first_name}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <label>{t("Last Name")}</label>
                                                    <input type="text" className="form-control" name="last_name" value={values.last_name} onBlur={handleBlur} onChange={handleChange} />
                                                    <small className='text-danger fw-bold'>{errors.last_name && touched.last_name && errors.last_name}</small>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group mb-4">
                                                    <label>{t("Country")}</label>
                                                    <select className="form-control" name='country' value={values.country} onBlur={handleBlur} onChange={handleChange} >
                                                    <option value="">{t("Please Select")}</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                    </select>
                                                    <small className='text-danger fw-bold'>{errors.country && touched.country && errors.country}</small>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <label>{t("Town / City")} </label>
                                                    <input type="text" className="form-control" name="city" value={values.city} onBlur={handleBlur} onChange={handleChange} />
                                                    <small className='text-danger fw-bold'>{errors.city && touched.city && errors.city}</small>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <label>{t("Postcode / ZIP (optional)")}</label>
                                                    <input type="text" className="form-control" name="zip_code" value={values.zip_code} onBlur={handleBlur} onChange={handleChange} />
                                                    <small className='text-danger fw-bold'>{errors.zip_code && touched.zip_code && errors.zip_code}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <label>{t("Phone")} </label>
                                                    <input type="text" className="form-control" name="phone_number" value={values.phone_number} onBlur={handleBlur} onChange={handleChange} />
                                                    <small className='text-danger fw-bold'>{errors.phone_number && touched.phone_number && errors.phone_number}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <label>{t("Email address")} </label>
                                                    <input type="email" className="form-control" name="email" defaultValue={values.email} onBlur={handleBlur} onChange={handleChange} readOnly />
                                                    <small className='text-danger fw-bold'>{errors.email && touched.email && errors.email}</small>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <button type="submit" className="btn btn-fill-out btn-block mt-10">
                                                    {t("Update")}
                                                </button>
                                            </div>


                                        </div>
                                    </form>


                                    {/* inner col */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}
