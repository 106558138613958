import { Link } from "react-router-dom";
import ThumbSlider from "../sliders/Thumb";
import ProductTab from "../elements/ProductTab";
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../../context/filterContext';
import { toast } from "react-toastify";
import { server } from '../../util/server';
import axios from 'axios';
import RelatedSlider from "../sliders/Related";
import RecentlyViewdProducts from "../sliders/RecentlyViewdProducts";
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TwitterIcon, TwitterShareButton, PinterestIcon, PinterestShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { useEffect, useState } from "react";

const ProductDetails = ({ product, similarProduct, variation, slug }) => {
    const { app_currency, isUserLogin, setCartTotalCount, currency_value } = useFilterContext();
    const { i18n, t } = useTranslation();
    const [choosenVariation, setchoosenVariation] = useState([]);

    const [price, setprice] = useState(0.00);

    const [actualPrice, setactualPrice] = useState(0.00);

    const [stockType, setstockType] = useState(0);

    const [sku, setsku] = useState(null);


    const url = window.location.href;

    const handleCart = (product) => {
        let url = server + "/api/add-to-cart"
        axios.post(url, { 'product_id': product.id, 'session_id': isUserLogin, 'cart_session_id': sessionStorage.getItem('local_session') , 'sku': sku}).then(function (result) {
            if (result.data.status === false) {
                toast.error(t(result.data.message));
            } else {
                toast.success(t(result.data.message));
                setCartTotalCount(result.data.count);
                sessionStorage.setItem('count', result.data.count);
                if (!sessionStorage.getItem('local_session')) {
                    sessionStorage.setItem('local_session', result.data.cart_session_id)
                }
            }
        })
            .catch(function (error) {
                toast.error(t("Unexpected error occurred!"));
            });
    };

  
    const ChangeVal = async (e, key, val) => {
        e.preventDefault()
        try {
            let url = server + "/api/product-detail/" + slug+'?product_id='+product.id+'&var_key='+key+'&var_val='+val+'&choosen_val='+choosenVariation
            let res = await axios.get(url);
            let result = await res.data;
            if (result.status === false) {
                toast.error(t(result.message));
            } else {
                setchoosenVariation(result.choosen_variation)
                setprice(result.variation.variation_price)
                setactualPrice(result.variation.original_variation_price)
                setstockType(result.variation.variation_stock === 0 ? 0 : 1)
                setsku(result.variation.sku)
            }
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
        }
    }

    useEffect(() => {
        if(variation && variation.variation_array.length != 0){
            setchoosenVariation(variation.choosen_variation)
            setprice(product.first_variation_val.variation_price)
            setactualPrice(product.first_variation_val.original_variation_price)
            setstockType(product.first_variation_val.variation_stock === 0 ? 0 : 1)
            setsku(product.first_variation_val.sku)
        }else{
            setchoosenVariation(variation.choosen_variation)
            setprice(product.price_CHF)
            setactualPrice(product.original_price_CHF)
            setstockType(product.stock_type)
            setsku(product.sku)
        }
        
    }, [variation,product])
    

    const handleWishlist = (product) => {
        const token = window.sessionStorage.getItem('local_token')

        let url = server + "/api/add-to-wishlist"
        axios({
            method: "post",
            url: url,
            data: { 'product_id': product.id },
            headers: { "Content-Type": "multipart/form-data", "Authorization": token ? `Bearer ${token}` : '' },
        }).then(function (result) {
            if (result.data.status === false) {
                toast.error(t(result.data.message));
            } else {
                toast.success(t(result.data.message));
            }
        })
        .catch(function (error) {
            if (error.response.status === 401) {
                toast.error(t("Please login to continue"));
            } else {
                toast.error(t("Unexpected error occurred!"));
            }
        });
    };


   
    return (
        <>


            <section className="mt-50 mb-50">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-12">
                            <div className="product-detail accordion-detail">
                                <div className="row mb-50">
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="detail-gallery">
                                            {/* <span className="zoom-icon">
                                                <i className="fi-rs-search"></i>
                                            </span> */}

                                            <div className="product-image-slider">
                                                <ThumbSlider product={product}
                                                />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="detail-info">
                                            <h2 className="title-detail">
                                                {product && product['name_' + i18n.language]}
                                            </h2>
                                            <div className="product-detail-rating">
                                                <div className="pro-details-brand">
                                                    {/* <span>
                                                        {t("Brand")}:
                                                        <Link to={product && product.brand ? '/marken/' + product.brand.slug : ''} className="ms-2">
                                                            {product && product.brand && product.brand.name}
                                                        </Link>
                                                    </span> */}

                                                    <span className="d-block">
                                                        {t("Vendor")}:
                                                        <span className="ms-2 text-danger">
                                                        {product && product.vendor && product.vendor.store_name}
                                                        </span>
                                                    </span>

                                                </div>
                                                <div className="product-rate-cover text-end">
                                                    <Link to="#"><i className={product && 1 <= product.rating ? "fas fa-star fs-6 me-2 text-danger" : "fas fa-star fs-6 me-2 bg-star"}></i></Link>
                                                    <Link to="#"><i className={product && 2 <= product.rating ? "fas fa-star fs-6 me-2 text-danger" : "fas fa-star fs-6 me-2 bg-star"}></i></Link>
                                                    <Link to="#"><i className={product && 3 <= product.rating ? "fas fa-star fs-6 me-2 text-danger" : "fas fa-star fs-6 me-2 bg-star"}></i></Link>
                                                    <Link to="#"><i className={product && 4 <= product.rating ? "fas fa-star fs-6 me-2 text-danger" : "fas fa-star fs-6 me-2 bg-star"}></i></Link>
                                                    <Link to="#"><i className={product && 5 <= product.rating ? "fas fa-star fs-6 me-2 text-danger" : "fas fa-star fs-6 me-2 bg-star"}></i></Link>
                                                    <span className="font-small ml-5 text-muted">
                                                        ({product && product.reviews && product.reviews.length > 0 ? product.reviews.length : 0} {t("Reviews")} )
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="clearfix product-price-cover">
                                                <div className="product-price primary-color float-left">
                                                    {product && stockType === 0 && <ins>
                                                        <span className="text-brand">
                                                            {t("Sold Out")}
                                                        </span>
                                                    </ins>}

                                                    {product && stockType === 1 && product.discount !== 0 ?
                                                        <>
                                                            <ins>
                                                                <span className="text-brand">
                                                                    {app_currency}{(price * currency_value[app_currency]).toFixed(2)}
                                                                </span>  
                                                            </ins>
                                                            <ins>
                                                                <span className="old-price font-md ml-15">
                                                                    {app_currency}{(actualPrice * currency_value[app_currency]).toFixed(2)}
                                                                </span>
                                                            </ins>
                                                            <span className="save-price  font-md color3 ml-15">
                                                                {product.discount}{product.discount_type} {t("Off")}
                                                            </span>
                                                        </> : ''
                                                    }

                                                    {product && stockType === 1 && product.discount === 0 ? <ins>
                                                        <span className="text-brand">
                                                            {app_currency}{(price * currency_value[app_currency]).toFixed(2)}
                                                        </span>
                                                    </ins> : ''
                                                    }



                                                </div>
                                            </div>
                                            <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                                            {/* <div className="short-desc mb-30">
                                                <p>Some description</p>
                                            </div> */}
                                            <div className="product_sort_info font-xs mb-30">
                                                <ul>
                                                    <li className="mb-10">
                                                        {product && stockType === 1 && t('In Stock')}
                                                    </li>
                                                </ul>
                                            </div>

                                            {
                                                variation  && variation.variation_array.length != 0 && Object.entries(variation.variation_array).map(([key, item]) => (
                                                    <div className="attr-detail attr-color mb-15">
                                                        <strong className="mr-10">
                                                        {key}
                                                        </strong>
                                                        <ul className={key === 'Color' || key === 'color' ? "list-filter color-filter" : "list-filter size-filter"}>
                                                            {
                                                                item.length > 0 && item.map(value => (     
                                                                <li className={choosenVariation.find(item => item === key+": "+value) ? 'active' : ''}>
                                                                    <a href="#" onClick={(e) => ChangeVal(e, key, value)}>
                                                                        {
                                                                            key === 'Color' || key === 'color' ? 
                                                                            <span
                                                                            style={{ backgroundColor: value.toLowerCase() }}
                                                                            ></span>
                                                                            :
                                                                            value
                                                                        }
                                                                        
                                                                    </a>
                                                                </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    
                                                ))
                                            
                                            }
                                            


                                            <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                                            <div className="detail-extralink">

                                                <div className="product-extra-link2">
                                                    {
                                                        product && stockType === 1 && <button

                                                            className="button button-add-to-cart"
                                                            onClick={(e) => handleCart(product)}
                                                        >
                                                            {t("Add to cart")}
                                                        </button>
                                                    }

                                                    <Link
                                                        to="#"
                                                        aria-label={t("Add To Wishlist")}
                                                        className="action-btn hover-up"
                                                        onClick={(e) => handleWishlist(product)}

                                                    >
                                                        <i className="fi-rs-heart"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <ul className="product-meta font-xs color-grey mt-50">
                                                <li className="mb-5">
                                                    {t("Categories")}:
                                                    {product && product.categories.length > 0 && product.categories.map((item, i) => (
                                                        <span className="ms-2" key={i}>
                                                            {item.category_detail['name_' + i18n.language]},
                                                        </span>
                                                    ))}


                                                </li>

                                                <li className="mb-5">
                                                    {t("SKU")}: {product && sku}


                                                </li>
                                                <li>
                                                    <div className="social-icons single-share">
                                                        <ul className="text-grey-5 d-inline-block">
                                                            <li>
                                                                <strong className="mr-10">
                                                                    {t("Share this")}:
                                                                </strong>
                                                            </li>
                                                            <li>
                                                                <FacebookShareButton url={url} style={{ marginLeft: '3px' }}>
                                                                    <FacebookIcon size={25} round={true} />
                                                                </FacebookShareButton>
                                                            </li>
                                                            {/* <li>
                                                                <PinterestShareButton url={url} media={url} style={{ marginLeft: '3px' }}>
                                                                    <PinterestIcon size={25} round={true} />
                                                                </PinterestShareButton>
                                                            </li> */}
                                                            <li>
                                                                <TwitterShareButton url={url} style={{ marginLeft: '3px' }}>
                                                                    <TwitterIcon size={25} round={true} />
                                                                </TwitterShareButton>
                                                            </li>
                                                            <li>
                                                                <LinkedinShareButton url={url} style={{ marginLeft: '3px' }}>
                                                                    <LinkedinIcon size={25} round={true} />
                                                                </LinkedinShareButton>
                                                            </li>
                                                            <li>
                                                                <WhatsappShareButton url={url} style={{ marginLeft: '3px' }}>
                                                                    <WhatsappIcon size={25} round={true} />
                                                                </WhatsappShareButton>
                                                            </li>


                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ProductTab product={product} />
                                {
                                    similarProduct.length > 0 &&
                                    <div className="row mt-60">
                                        <div className="col-12">
                                            <h3 className="section-title style-1 mb-30">
                                                {t("Similar products")}
                                            </h3>
                                        </div>
                                        <div className="col-12">
                                            <div className="row related-products position-relative">
                                                <RelatedSlider similarProduct={similarProduct} />
                                            </div>
                                        </div>
                                    </div>

                                }



                                <RecentlyViewdProducts />



                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};



export default ProductDetails;
