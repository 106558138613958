import React from "react";
import {Link} from "react-router-dom"
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';
import { server } from "../../util/server";
const BlogSidebar = ({blogCategories, trending,categoryFilter, blogCount, blogSearch,clearBlogSearc}) => {
    const {i18n,t} = useTranslation();
    return (
        <>
            <div className="widget-area">
                <div className="sidebar-widget widget_search mb-50">
                    <div className="search-form">
                        <form action="#">
                            <input type="text" placeholder={t("Search")} id="blog_search" onChange={(e) => e.target.value} />
                            <button type="submit" onClick={(e) => blogSearch(e)}>
                                <i className="fi-rs-search"></i>
                            </button>
                            <Link to="#" className="float-end text-secondary" onClick={() => clearBlogSearc()}><i className="fas fa-times"></i> {t("Clear")}</Link>
                        </form>
                    </div>
                </div>

                <div className="sidebar-widget widget_categories mb-40">
                    <div className="widget-header position-relative mb-20 pb-10">
                        <h5 className="widget-title">{t("Categories")}</h5>
                    </div>
                    <div className="post-block-list post-module-1 post-module-5">
                        <ul>
                            {
                                blogCategories && 
                                <li className="cat-item cat-item-3">
                                    <Link to="#" onClick={() => categoryFilter('All')}>
                                        {t("All")}
                                    </Link>{" "}
                                    {blogCount}
                                </li>
                            }
                                
                            {
                                blogCategories && 
                                Object.entries(blogCategories).map(([key, value], index) => (  
                                    <>
                                    <li className="cat-item cat-item-3" key={index}>
                                        <Link to="#" onClick={() => categoryFilter(key)}>
                                            {key}
                                        </Link>{" "}
                                        {value}
                                    </li></>
                                )) 
                            }
                           
                        </ul>
                    </div>
                </div>

                <div className="sidebar-widget widget_alitheme_lastpost mb-20">
                    <div className="widget-header position-relative mb-20 pb-10">
                        <h5 className="widget-title">{t("Trending Now")}</h5>
                    </div>
                    <div className="row">
                        {
                            trending && trending.length > 0 && trending.map((item, key )=> (
                              <>
                                <div key={item.id} className="col-xl-4 col-lg-12 col-sm-4 sm-grid-content mb-5 px-lg-0">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-xl-15 mb-lg-0 mb-sm-15">
                                    <Link to={"/bloggen/" + item.slug}>
                                        <img
                                            src={server + "/" + item.main_img}
                                            alt={item['alt_' + i18n.language]}
                                            className="img-fluid"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className='col-xl-8 col-lg-12 col-sm-8 mb-xl-5 mb-lg-50 mb-sm-5'>
                                <div className="post-content media-body mt-xl-2 mt-lg-0 mt-sm-5">
                                    <h6 className="post-title mb-10 text-limit-2-row">
                                        {item['title_' + i18n.language]}
                                    </h6>
                                    <div className="entry-meta meta-13 font-xxs color-grey">
                                        <span className="post-on mr-10">
                                            {format(new Date(item.created_at), 'do  MMMM yyyy')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            </>
                            ))
                        }
                       
                        
                    </div>
                </div>

               

                
            </div>
        </>
    );
};

export default BlogSidebar;
