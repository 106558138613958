import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useFilterContext} from '../../context/filterContext';
import { server } from '../../util/server';
import { useTranslation } from 'react-i18next';
import { Meta } from '../../components/elements/Meta';
export default function Brands() {
    const {brands,updatemetas} = useFilterContext();
    const {  t } = useTranslation();

    useEffect(() => {
        updatemetas('marken',4)
    }, [])
    

    return (
        <>
        <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                        {t("Home")}
                        </Link>
                        <span></span> {t("Brands")}
                    </div>
                </div>
            </div>
            <section className="mt-50 mb-50">
                <div className="container">
                    <div className="row">
                       

                            {
                                brands.length > 0 &&
                                brands.map((elem, k) => (
                                        <div className="col-md-3 col-sm-4 col-6" key={k}>
                                            <Link to={"/marken/"+elem.slug} title={elem.name}>
                                                    <img src={server+"/"+elem.image} />
                                            </Link>
                                        </div>
                                ))
                                        
                            }

                  
                    </div>
                </div>
            </section>
        </>
    )
}
