import * as Yup from 'yup';

export const contactSchema = Yup.object({
    first_name: Yup.string().min(3).max(25).required("First Name is required"),
    last_name: Yup.string().min(3).max(25).required("Last Name is required"),
    email: Yup.string().email().max(50).required("Email is required"),
    phone: Yup.number().required("Phone Number is required"),
    message: Yup.string().min(10).max(500).required("Please enter message")
})

export const newsletterSchema = Yup.object({
    email: Yup.string().email().max(50).required("Email is required")
})

// const passwordRegex = new RegExp("^(?=.*?[A-Z])");
// const smallLetters = new RegExp("^(?=.*?[a-z])$");

export const registerSchema = Yup.object({
    email: Yup.string().email().max(50).required("Email is required"),
    password : Yup.string().min(8, 'Password must contain at least 8 characters').max(20, 'Password must not exceed 20 characters').required("Please enter your password!"),
    cpassword: Yup.string().oneOf([Yup.ref('password')], "Password does not match!").required("Please confirm your password!"),
    policy_check: Yup.bool().oneOf([true], 'You must agree to our terms & conditions')
})

export const loginSchema = Yup.object({
    email: Yup.string().email().max(50).required("Email is required"),
    password : Yup.string().min(8, 'Password must contain at least 8 characters').max(20, 'Password must not exceed 20 characters').required("Please enter your password!")
  
})

export const checkoutSchema = Yup.object({
    first_name: Yup.string().max(30).required("Plase enter First Name"),
    last_name: Yup.string().max(30).required("Please enter Last Name"),
    email: Yup.string().email().max(50).required("Please enter email"),
    phone_number: Yup.string().max(20).required("Please enter phone number"),
    city: Yup.string().max(50).required("Please enter City"),
    zip_code: Yup.string().max(6).required("Please enter Zip Code"),
    order_note: Yup.string().max(500, "Max 500 characters are allowed!"),
    order_type: Yup.string().required("Please choose atleast one order option"),
    
})

export const passwordResetSchema = Yup.object({
    code: Yup.string().max(50).required("Please enter code you recieved in email"),
    password : Yup.string().min(8, 'Password must contain at least 8 characters').max(20, 'Password must not exceed 20 characters').required("Please enter your password!"),
    cpassword: Yup.string().oneOf([Yup.ref('password')], "Password does not match!").required("Please confirm your password!"),
    
})

export const getReviewSchema = Yup.object({
    review_data: Yup.string().max(500).required("Please enter your review"),
    user_name : Yup.string().max(50).required("Please enter Name"),
    user_email: Yup.string().email().max(50).required("Please enter email"),
    
})

export const userProfile = Yup.object({
    first_name: Yup.string().max(30).required("Plase enter First Name"),
    last_name: Yup.string().max(30).required("Please enter Last Name"),
    email: Yup.string().email().max(50).required("Please enter email"),
    phone_number: Yup.string().max(20).required("Please enter phone number"),
    city: Yup.string().max(50).required("Please enter City"),
    zip_code: Yup.string().max(6).required("Please enter Zip Code")
    
})


export const commentSchema = Yup.object({
    email: Yup.string().email().max(50).required("Email is required"),
    name : Yup.string().min(2, 'Name must contain at least 2 characters').max(30, 'Name must not exceed 30 characters').required("Please enter your name!"),
    website: Yup.string().max(50, 'Name must not exceed 50 characters'),
    comment: Yup.string().min(10, 'Comment must contain at least 10 characters').max(300, 'Comment must not exceed 300 characters').required("Please enter your comment!"),
})