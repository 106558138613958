import React, { useEffect, useState } from "react";
import FeaturedSlider from "../sliders/Featured";
import FeaturedSlider2 from "../sliders/FeaturedSlider2";
import TrendingSlider from "../sliders/Trending";
import { server } from '../../util/server';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function FeatchTabSlider({ products, homePageContent }) {
    const [active, setActive] = useState("1");
    const { i18n, t } = useTranslation();

    const featuredProduct = async () => {
        setActive("1");
    };

    const trendingProduct = async () => {
        setActive("2");
    };


    useEffect(() => {
        featuredProduct();
    }, []);

    return (
        <>
            <div className="heading-tab d-flex">
                <div className="heading-tab-left wow fadeIn animated">
                    <h3 className="section-title mb-20">
                        <span>{t("Stock")}</span> {t("Sale")}
                    </h3>
                </div>
                {/* <div className="heading-tab-right wow fadeIn animated">
                    <ul className="nav nav-tabs right no-border" id="myTab-1" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={active === "1" ? "nav-link active" : "nav-link"} onClick={featuredProduct}>
                                {t("Featured")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={active === "2" ? "nav-link active" : "nav-link"} onClick={trendingProduct}>
                                {t("Popular")}
                            </button>
                        </li>
                    </ul>
                </div> */}
            </div>

            <div className="row">
                <div className="col-lg-3 d-none d-lg-flex">
                    <div className="banner-img style-2 wow fadeIn animated">
                        <img src={homePageContent['home_section_3'] && server+'/'+homePageContent['home_section_3'][0]['image']}
                         alt={homePageContent['home_section_3'] && homePageContent['home_section_3'][0]['alt_'+i18n.language]} />
                        {/* <img src="assets/temp/sako.jpg"
                            alt={homePageContent['home_section_3'] && homePageContent['home_section_3'][0]['alt_' + i18n.language]} />
                        <div className="banner-text text-dark">
                            <span>Fashion</span>
                            <h4 className="mt-5 text-dark">Save 50% on <br />Suits</h4>
                            <a className="text-dark" href="#">Shop Now <i className="fi-rs-arrow-right"></i></a>
                        </div> */}
                        <div className="banner-text">
                            {
                                homePageContent['home_section_3'] && <div dangerouslySetInnerHTML={{ __html: homePageContent['home_section_3'][0]['text_' + i18n.language] }} />
                            }
                            <Link to={homePageContent['home_section_3'] && homePageContent['home_section_3'][0]['link']}>{homePageContent['home_section_3'] && homePageContent['home_section_3'][0]['name_'+ i18n.language]} <i className="fi-rs-arrow-right"></i>
                            </Link>
                        </div>
                        
                       
                    </div>
                </div>
                <div className="col-lg-9 col-md-12">
                    <div className="tab-content wow fadeIn animated" id="myTabContent">
                        <div className={active === "1" ? "tab-pane fade show active" : "tab-pane fade"}>
                            <div className="carausel-4-columns-cover card-product-small arrow-center position-relative">
                                <FeaturedSlider products={products} />
                            </div>
                        </div>
                        {/* 
                        <div className={active === "2" ? "tab-pane fade show active" : "tab-pane fade"}>
                            <div className="carausel-4-columns-cover card-product-small arrow-center position-relative">
                                <TrendingSlider products={popularProducts} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
export default FeatchTabSlider;
