import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SingleProduct from "./../ecommerce/SingleProduct";

SwiperCore.use([Navigation]);

const FeaturedSlider2 = ({ products }) => {
    
    return (
        <>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                // grid={{
                //     rows: 2,
                // }}
                //loop={false}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    540: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1000:{
                        slidesPerView: 3,
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                }}
                navigation={{
                    prevEl: ".custom_prev_f",
                    nextEl: ".custom_next_f",
                }}
                className="custom-class"
            >
                {products.map((product, i) => (
                    <SwiperSlide key={i}>
                        <SingleProduct product={product} />
                    </SwiperSlide>
                ))}
            </Swiper>          

            <div
                className="slider-arrow slider-arrow-2 carausel-4-columns-arrow"
                style={{ top: '15%' }}
            >
                <span className="slider-btn slider-prev slick-arrow custom_prev_f"  style={{ left:'auto',right:'65px' }}>
                    <i className="fi-rs-angle-left"></i>
                </span>
                <span className="slider-btn slider-next slick-arrow custom_next_f">
                    <i className="fi-rs-angle-right"></i>
                </span>
            </div>
        </>
    );
};

export default FeaturedSlider2;
