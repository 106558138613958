import React, {useEffect,useState} from 'react'
import {Link} from 'react-router-dom'
import { toast } from 'react-toastify';
import { server } from '../util/server';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import {useFilterContext} from '../context/filterContext';
import { Meta } from '../components/elements/Meta';
export default function FAQ() {
    const [data, setData] = useState([]);
    const { i18n,t } = useTranslation();
    const {updatemetas} = useFilterContext();
    //load inital data for home page
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try{
            const request = await fetch(`${server}/api/faq`);
            const allData = await request.json();
            if(allData.status === false){
                toast.error(t("Unexpected error occurred!"));
            }else{
                setData(allData.data);
                updatemetas(null,0,allData.meta)
            }
        }catch(error){
            toast.error(t("Unexpected error occurred!"));
        }
        
    
    };

    return (
        <>
        <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                        {t("Home")}
                        </Link>
                        <span></span> {t("FAQs")}
                    </div>
                </div>
            </div>
            <section className="mt-50 mb-50">
                <div className="container">
                    <Accordion>
                        <div className="row">
                            {
                                data.length > 0 &&
                                data.map((elem, k) => (
                                    <div className="col-md-6 col-sm-6 col-12 mb-3" key={k}>
                                            <Accordion.Item eventKey={elem.id}>
                                            <Accordion.Header> {elem['question_'+i18n.language]}</Accordion.Header>
                                            <Accordion.Body>
                                                <div dangerouslySetInnerHTML={{ __html:elem['answer_'+i18n.language] }}/>
                                                
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        
                                    </div>
                                ))
                                        
                            }
                        </div>
                    </Accordion>
                </div>
            </section>
        </>
    )
}
