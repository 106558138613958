import {useFilterContext} from '../../context/filterContext';
import {Link} from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import {useState} from 'react'
import { useTranslation } from 'react-i18next';
const CategoryProduct = ({productCategoryFilter}) => {
    const { t,i18n } = useTranslation();
    const { categories, category_filter} = useFilterContext();
    const [open, setOpen] = useState(false);
    const selectCategory = (e, id) => {
         productCategoryFilter(id)
    }
    return (
        <>
                <h5 className="section-title style-1 mb-30 wow fadeIn animated">
                        {t("Filter By Category")}   <Link className="float-end" data-bs-toggle="collapse"  onClick={() => setOpen(!open)} aria-controls="cat-colapse" aria-expanded={open}><i className="fas fa-chevron-down"></i></Link>
                </h5>
                <Collapse in={open}>
                    <ul className="categories">
                        {categories &&
                            categories.map((item, index) => (
                            <div key={index} className="text-dark">
                                <li key={item.id+'ca-'+index}><Link to="#" onClick={(e) => selectCategory(e,item.id )} className={category_filter && category_filter === item.id ? "shop-active" : 'text-dark'}>{item['name_'+ i18n.language]}</Link></li>
                                {item.subcategory.length > 0 &&  item.subcategory.map((subitem) => (
                                    <div key={subitem.id+'ch-'+index}>
                                    <li className="ms-3" key={subitem.id+'ch-'+index}><Link to="#" onClick={(e) => selectCategory(e,subitem.id)} className={category_filter && category_filter === subitem.id ? "shop-active" : 'text-dark'}>{subitem['name_'+ i18n.language]}</Link></li>
                                    {subitem.subcategory.length > 0 &&  subitem.subcategory.map((childitem) => (
                                        <li className="ms-5" key={childitem.id+'sc-'+index}><Link to="#" onClick={(e) => selectCategory(e,childitem.id )} className={category_filter && category_filter === childitem.id ? "shop-active" : 'text-dark'}>{childitem['name_'+ i18n.language]}</Link></li>
                                    ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </ul>
                </Collapse>
               
        </>
    );
};

export default CategoryProduct;
