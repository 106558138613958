import React, {useEffect,useState} from 'react'
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Meta } from '../components/elements/Meta';
import {useFilterContext} from '../context/filterContext';
import { server } from '../util/server';
import { format } from 'date-fns';
import axios from 'axios';

export const Coupons = () => {
    const {  i18n,t } = useTranslation();
    const [data, setData] = useState([]);
    const {updatemetas} = useFilterContext();
    const coupondata = async() => {
        try {
            let url = server + "/api/coupon-list"
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === true) {
                setData(data.data)
                updatemetas(null,0,data.meta)
            } else {
                toast.error(t(data.message))
                
            }

        } catch (error) {
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        coupondata()
    }, []);
    return (
        <>
        <Meta/>
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <span></span> {t("Coupons")}
                    </div>
                </div>
            </div>

            <section className="mt-50 mb-50">
                <div className="container">
                    <div className="row">
                        {
                            data.length > 0 && data.map(item => (
                                <div className="col-md-6">
                                    <div className="coupon-card">
                                        <h3 className='mb-2'>{item['coupon_title_'+i18n.language]}</h3>
                                        <p>{item['coupon_purpose_'+i18n.language]}</p>
                                        <di className="coupon-row">
                                            <span id="cpnCode">{item['coupon_code']}</span>
                                            <span id="cpnBtn" onClick={() =>  navigator.clipboard.writeText(item['coupon_code'])}>Copy Code</span>
                                        </di>
                                        <p>Valid Till:  {format(new Date(item['coupon_valid_till']+' 00:00:00'), 'do  MMMM yyyy')}</p>
                                        <div className="circle1"></div>
                                        <div className="circle2"></div>
                                    </div>
                                </div>
                            ))
                        }
                      
                    </div>
                </div>
            </section>
        </>
    )
}
