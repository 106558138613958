import BlogSingle from '../components/elements/BlogSingle';
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { server } from "../util/server";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useFilterContext } from '../context/filterContext';
import { Meta } from "../components/elements/Meta";
import { format } from 'date-fns';
import Spinner from '../components/Spinner/Main';
function SingleBlog() {
    const { i18n, t } = useTranslation();
    const { slug } = useParams();
    const [singleBlog, setSingleBlog] = useState(null);
    const [relatedBlog, setRelatedBlog] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const { updatemetas, currency_value, app_currency } = useFilterContext();
    const [spinner, setSpinner] = useState(true);
    const getSingleBlog = async () => {
        try {
            let url = server + "/api/single-blog/" + slug + "?language=" + i18n.language
            let res = await axios.get(url);
            let data = await res.data;
            if (data.status === true) {
                setSingleBlog(data.data)
                setRelatedBlog(data.related_blogs)
                setRelatedProducts(data.related_products)
                updatemetas(null, 0, data.meta)
                setSpinner(false)
            }
            // else{
            //     toast.success(data.message)
            // }

        } catch (error) {
            setSpinner(false)
            return toast.error(t("Unexpected error occurred!"))
        }
    }

    useEffect(() => {
        getSingleBlog();
    }, [slug]);

    return (
        <>
            <Meta />
            <div className={`page-header breadcrumb-wrap`}>
                <div className="container">
                    <div className="breadcrumb">
                        <Link to="/">
                            {t("Home")}
                        </Link>
                        <Link to="/bloggen">
                            <span></span>  {t("Blogs")}
                        </Link>
                        <span></span> {singleBlog && singleBlog['title_' + i18n.language]}
                    </div>
                </div>
            </div>

            {spinner ? <Spinner /> :
                <>
                    <section className="mt-50 mb-50">
                        <div className="container custom">
                            <div className="row">
                                <div className="col-lg-9">
                                    <BlogSingle singleBlog={singleBlog} />
                                </div>
                                <div className="col-lg-3 primary-sidebar sticky-sidebar mt-lg-0 mt-sm-50">
                                    <div className="widget-area">
                                        {
                                            relatedBlog && relatedBlog.length > 0 &&
                                            <div className="sidebar-widget widget_alitheme_lastpost mb-20">
                                                <div className="widget-header position-relative mb-20 pb-10">
                                                    <h5 className="widget-title">{t("Related Blogs")}</h5>
                                                </div>
                                                <div className="row ps-3">
                                                    {
                                                        relatedBlog.map((item, key) => (
                                                            <>
                                                                <div className="col-xl-4 col-lg-12 col-sm-4 sm-grid-content mb-5 px-lg-0" key={item.id}>
                                                                    <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-xl-15 mb-lg-0 mb-sm-15">
                                                                        <Link to={"/bloggen/" + item.slug}>
                                                                            <img
                                                                                src={server + "/" + item.main_img}
                                                                                alt={item['alt_' + i18n.language]}
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-8 col-lg-12 col-sm-8 mb-xl-5 mb-lg-50 mb-sm-5'>
                                                                    <div className="post-content media-body mt-xl-2 mt-lg-0 mt-sm-5">
                                                                        <h6 className="post-title mb-10 text-limit-2-row">
                                                                            {item['title_' + i18n.language]}
                                                                        </h6>
                                                                        <div className="entry-meta meta-13 font-xxs color-grey">
                                                                            <span className="post-on mr-10">
                                                                                {format(new Date(item.created_at), 'do  MMMM yyyy')}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            relatedProducts && relatedProducts.length > 0 &&
                                            <div className="sidebar-widget widget_alitheme_lastpost mb-20">
                                                <div className="widget-header position-relative mb-20 pb-10">
                                                    <h5 className="widget-title">{t("Related Products")}</h5>
                                                </div>
                                                <div className="row ps-3">
                                                    {
                                                        relatedProducts.map((product) => (
                                                            <>
                                                                <div className="col-xl-4 col-lg-12 col-sm-4 sm-grid-content mb-5 px-lg-0" key={product.id}>
                                                                    <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-xl-15 mb-lg-0 mb-sm-15">
                                                                        <Link to={"/Produkte/" + product.slug}>
                                                                            <img
                                                                                src={product.main_image && server + "/" + product.main_image.image}
                                                                                alt={product.main_image['alt_' + i18n.language]}
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-8 col-lg-12 col-sm-8 mb-xl-5 mb-lg-50 mb-sm-5'>
                                                                    <div className="post-content media-body mt-xl-2 mt-lg-0 mt-sm-5">
                                                                        <h6 className="post-title mb-10 text-limit-2-row">
                                                                            {product['name_' + i18n.language]}
                                                                        </h6>
                                                                        <div className="entry-meta meta-13 font-xxs color-grey">
                                                                            {product.stock_type === 0 && <span className="text-danger">{t("Sold Out")}</span>}

                                                                            {product.stock_type === 1 && product.discount !== 0 ? <><span className="old-price text-decoration-line-through d-block">{app_currency}{(product['original_price_CHF'] * currency_value[app_currency]).toFixed(2)}</span><span>{app_currency}{(product['price_CHF'] * currency_value[app_currency]).toFixed(2)}</span></> : ''
                                                                            }
                                                                            {product.stock_type === 1 && product.discount === 0 ? <span>{app_currency}{(product['price_CHF'] * currency_value[app_currency]).toFixed(2)}</span> : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {/* sidebar */}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    );
}

export default SingleBlog;
