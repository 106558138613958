import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PageNotFound() {
  const { t } = useTranslation();
  return (
        <div className='container mt-5'>
            <div id="notfound">
<div className="notfound">
<div className="notfound-404">
<h3>{t("Oops! Page not found")}</h3>
<h1><span>4</span><span>0</span><span>4</span></h1>
</div>
<h2>{t("The page you are looking for was moved, removed, renamed or might never existed.")}</h2>
</div>
</div>
        </div>
  )
}
