import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useFilterContext } from '../../context/filterContext';
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

const PriceRangeSlider = ({filter_products}) => {
    const { t } = useTranslation();
    const { max_price_Range, min_price_range, applyPriceRange,currency_value,app_currency} = useFilterContext();

    // const [price, setPrice] = useState({ value: { min: min_price_range, max: max_price_Range } });
    const [price, setPrice] = useState(max_price_Range);
    // useEffect(() => {
    //     if(selected_price_range === null){
    //         setPrice(max_price_Range)
    //     } 
    // },[])
   


    const applyPriceRange2 = () => {
        if(app_currency !== 'CHF'){
            let min_price = (min_price_range/currency_value[app_currency]).toFixed(2);
            let max_price = (price/currency_value[app_currency]).toFixed(2);
            applyPriceRange(min_price + '-' + max_price)
        }else{
            applyPriceRange(min_price_range + '-' + price)
        }
       
    }


    return (
        <>
            {
                filter_products.length > 12 && 
                <div className="widget-category mb-30">
                    <h5 className="section-title style-1 mb-10 wow fadeIn animated">
                        {t("Price Filter")}
                    </h5>

                    {/* <div className="price-filter">
                        <div className="price-filter-inner">
                            <br />

                            <div className="evara_price_slider_amount"> */}
                                {/* <Slider
                                    range
                                    allowCross={false}
                                    defaultValue={[min_price_range, max_price_Range]}
                                    min={min_price_range}
                                    max={max_price_Range}
                                    onChange={(value) => pricerangeapply(value)}
                                    step={15}
                                // onChange={(value) => setPrice({ value: { min: value[0], max: value[1] } })}
                                /> */}

                            <input type="range" className="form-range" onChange={(event) => setPrice(event.target.value)}
                              defaultValue={price} min={min_price_range} max={max_price_Range} step="5"
                            />

                                <div className="d-flex justify-content-between">
                                    <span>
                                    {min_price_range}
                                    </span>
                                    <span>
                                    {max_price_Range}
                                    </span>
                                </div>

                                   
                                <div className="d-flex justify-content-end mt-2">
                                    <button className="btn btn-danger btn-sm" onClick={() => applyPriceRange2()}>{t("Filter Upto")} {price}</button>
                                </div>

                             
                            </div>

                //         </div>
                //     </div>
                // </div>
            }

        </>
    );
};

export default PriceRangeSlider;
