import React, { useState,useEffect } from "react"

import { styles } from "../styles";

import EmailForm from "./EmailForm";
import ChatEngine from "./ChatEngine";
import { server } from "../../util/server";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

const SupportWindow = props => {
    const [user, setUser] = useState(null)
    const {  t } = useTranslation();

    useEffect(() => {
        
        if(localStorage.getItem('chat') && localStorage.getItem('chat_expire') && (Date.now() - localStorage.getItem('chat_expire') < 6 * 3600000)){
            setUser(localStorage.getItem('chat'))
        }else{
            localStorage.removeItem('chat');
            localStorage.removeItem('chat_expire');
            localStorage.removeItem('chat_id');
        }
      
    }, [])
    
   
   

    const getCustomerChat = () => {
        if (localStorage.getItem('chat_id')) {
            axios({
                method: "get",
                url: server + "/api/get-chat-data?session_id=" + localStorage.getItem('chat_id'),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    if (response.data.status === true) {
                        props.setShowChat(response.data.data)
                    }
                })
                .catch(function (response) {
                    toast.error(t("Unexpected error occurred!"));
                });
        }
    }

   

    

    // useEffect(() => {
    //     if(document.querySelector('#chateng').style.display !== 'none'){
    //         setTimeout(() => {
    //             getCustomerChat();
    //         }, 60000)
    //     }
    // });

    useEffect(() => {
        if (localStorage.getItem('chat_id') && document.querySelector('#chateng').style.display !== 'none') {
            const timerId = setTimeout(() => {
                getCustomerChat();
            }, 10000)
            return () => clearTimeout(timerId);
        }
    })

    return (
        <div 
            className='transition-5'
            id="chateng"
            style={{
                ...styles.supportWindow,
                ...{ display: props.visible === false ? 'none' : 'inline-block' }
                // ...{ display: 'none' }
            }}
        >
            <EmailForm 
                visible={user === null}
                setUser={user => setUser(user)} 
                setVisible={props.setVisible}
                // setChat={chat => setChat(chat)} 
            />

            <ChatEngine 
                visible={user !== null}
                user={user} 
                showChat={props.showChat} 
                setShowChat={props.setShowChat}
                setVisible={props.setVisible}
                getCustomerChat={getCustomerChat}
            />
        </div>
    )
}

export default SupportWindow;
