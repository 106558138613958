import { useEffect, useState } from "react";
import Bottom from "../components/elements/Bottom";
import FetchTabSlider from "./../components/ecommerce/fetchTabSlider";
import FetchTabSlider3 from "./../components/ecommerce/fetchTabSlider3";
import BannerFeatures from "./../components/elements/BannerFeatures";
import Deals1 from "./../components/elements/Deals1";
import Deals2 from "./../components/elements/Deals2";
import BrandSlider from "./../components/sliders/Brand";
// import NewArrival from "./../components/sliders/NewArrival";
import CategorySlider from "./../components/sliders/Category";
import HomeSlider from "./../components/sliders/intro3";
import { server } from '../util/server';
import { useTranslation } from 'react-i18next';
import { useFilterContext } from '../context/filterContext';
import { toast } from "react-toastify";
import { Meta } from "../components/elements/Meta";
import { Officemanager } from "../components/elements/Officemanager";
import Spinner from '../components/Spinner/Main';
import { Link } from "react-router-dom";

export default function Home() {
    const [firstProductSlider, setFirstProductSlider] = useState([]);
    const [heroSectionData, setheroSectionData] = useState([]);
    const [homePageContent, setHomePageContent] = useState([]);
    const [secondProductSlider, setsecondProductSlider] = useState([]);
    const [heroSectionDataType, setheroSectionDataType] = useState(null);
    const [categories, setCategories] = useState([]);
    const [bottom, setBottom] = useState([]);
    const { i18n, t } = useTranslation();
    const { brands, updatemetas } = useFilterContext();
    const [spinner, setSpinner] = useState(true);

    //load inital data for home page
    useEffect(() => {
        fetchData();
    }, []);



    const fetchData = async () => {
        // With Category
        try {
            const request = await fetch(`${server}/api/homepage-data`);
            const allData = await request.json();
            if (allData.status === false) {
                toast.error(t("Unexpected error occurred!"));
                setSpinner(false)
            } else {
                setFirstProductSlider(allData.data.first_product_slider);
                setheroSectionData(allData.data.banners);
                setHomePageContent(allData.data.data);
                setsecondProductSlider(allData.data.second_product_slider);
                setheroSectionDataType(allData.data.hero_section);
                setBottom(allData.data.bottom);
                updatemetas(null, 0, allData.meta)
                setCategories(allData.data.home_page_categories)
                setSpinner(false)

            }
        } catch (error) {
            toast.error(t("Unexpected error occurred!"));
            setSpinner(false)
        }


    };

    return (
        <>
            <Meta />
            {/* <IntroPopup /> */}


            {spinner ? <Spinner /> :

                <>
                    <section className="home-slider position-relative pt-25 pb-20">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="position-relative">
                                        {
                                            heroSectionDataType && heroSectionDataType == 'Hero Banner Slider' ?
                                                <HomeSlider homePageBanners={heroSectionData} />
                                                :

                                                heroSectionData && heroSectionData.length != 0 && Object.entries(heroSectionData).map(([key, item]) => (
                                                    <iframe className="elementor-video" frameBorder="0" allowFullScreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title={item.paragraph} id="home-video" width="100%" src={item.name} data-gtm-yt-inspected-6="true"></iframe>
                                                ))





                                        }
                                    </div>

                                </div>
                                <div className="col-lg-3 d-md-none d-lg-block">
                                    <div className="banner-img banner-1 wow fadeIn  animated home-3">
                                        <img
                                            className="border-radius-10"
                                            src={homePageContent['home_section_1'] && server + "/" + homePageContent['home_section_1'][0]['image']}
                                            alt={homePageContent['home_section_1'] && homePageContent['home_section_1'][0]['alt_' + i18n.language]}
                                        />
                                        <div className="banner-text">
                                            {
                                                homePageContent['home_section_1'] && <div dangerouslySetInnerHTML={{ __html: homePageContent['home_section_1'][0]['text_' + i18n.language] }} />
                                            }
                                            <Link to={homePageContent['home_section_1'] && homePageContent['home_section_1'][0]['link']}>{homePageContent['home_section_1'] && homePageContent['home_section_1'][0]['name_'+ i18n.language]} <i className="fi-rs-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="banner-img banner-2 wow fadeIn  animated mb-0">
                                        <img
                                            className="border-radius-10"
                                            src={homePageContent['home_section_2'] && server + "/" + homePageContent['home_section_2'][0]['image']}
                                            alt={homePageContent['home_section_2'] && homePageContent['home_section_2'][0]['alt_' + i18n.language]}
                                        />
                                        <div className="banner-text">
                                            {
                                                homePageContent['home_section_2'] && <div dangerouslySetInnerHTML={{ __html: homePageContent['home_section_2'][0]['text_' + i18n.language] }} />
                                            }
                                            <Link to={homePageContent['home_section_2'] && homePageContent['home_section_2'][0]['link']}>{homePageContent['home_section_2'] && homePageContent['home_section_2'][0]['name_'+ i18n.language]} <i className="fi-rs-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* popular categories */}
                    <section className="popular-categories section-padding mt-15 mb-25">
                        <div className="container wow fadeIn animated">
                            <h3 className="section-title mb-20">
                                <span>{t("Popular")}</span> {t("Categories")}
                            </h3>
                            <div className="carausel-6-columns-cover position-relative">
                                <div
                                    className="carausel-6-columns"
                                    id="carausel-6-columns"
                                >
                                    <CategorySlider categories={categories} />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Stock sale */}
                    <section className="section-padding">
                        <div className="container pt-25 pb-25">
                            <FetchTabSlider products={firstProductSlider} homePageContent={homePageContent} />
                        </div>
                    </section>

                    {/* Suit */}
                    {/* <section className=" section-padding">
                <div className="container pt-25 pb-25">
                    <FetchTabSlider2 heroSectionDataType={heroSectionDataType} homePageContent={homePageContent} />
                </div>
            </section> */}

                    {/* deals */}
                    <section className="deals section-padding pt-50 pb-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 deal-co">
                                    <Deals1 homePageContent={homePageContent} />
                                </div>
                                <div className="col-lg-6 deal-co">
                                    <Deals2 homePageContent={homePageContent} />
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Branded Shoes */}
                    <section className="section-padding">
                        <div className="container pt-25 pb-25">
                            <FetchTabSlider3 products={secondProductSlider} homePageContent={homePageContent} />
                        </div>
                    </section>




                    {/* offic manager */}
                    <section className="section-padding">
                        <div className="container pt-25 pb-25">
                            <Officemanager homePageContent={homePageContent} />
                        </div>
                    </section>
                    {/* special features */}
                    <section className="featured section-padding position-relative">
                        <div className="container">
                            <div className="row">
                                <BannerFeatures homePageContent={homePageContent} />
                            </div>
                        </div>
                    </section>


                    <Bottom bottom={bottom} homePageContent={homePageContent} />



                    {/* brands slider */}
                    {/* <section className="section-padding" style={{ padding: '25px 0 0 0' }}>
                        <div className="container">
                            <div className="carausel-6-columns-cover position-relative wow fadeIn animated">
                                <BrandSlider brands={brands} />
                            </div>
                        </div>
                    </section> */}


                </>

            }


        </>
    );
}
