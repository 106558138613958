import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langEn from './languages/eng_trans.json';
import langDe from './languages/de_lang.json';
import langFr from './languages/fr_lang.json';
import langIT from './languages/it_lang.json';

i18n
  .use(initReactI18next)
  .init({
    lng: (!sessionStorage.getItem('lng')) ? 'en' : sessionStorage.getItem('lng'),
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    // language resources
    resources: {
      en: {
        translation: langEn
      },
      de: {
        translation: langDe
      },
      fr: {
        translation: langFr
      },
      it: {
        translation: langIT
      },
    }
  });

export default i18n;