import { Link, NavLink,useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Search from "../ecommerce/Search";
import { useFilterContext } from '../../context/filterContext';
import { server } from '../../util/server';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import ReactWhatsapp from "react-whatsapp";

const Header = ({
    toggleClick,
    changeLangueg
}) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate()
    const [isToggled, setToggled] = useState(false);
    const [scroll, setScroll] = useState(0);
    const [headerBrands, setheaderBrands] = useState([]);
    const { ChangeCurrency, app_currency,isUserLogin, unsetUserLogin, cartTotalCount, categories, brands, navbar_items,getCurrencyData,webSettingsData} = useFilterContext();
    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY >= 100;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        });
    });

    // useEffect(() => {
    //     if(app_currency !== 'CHF'){
    //         getCurrencyData();
    //     }
    // }, [app_currency]);

    // useEffect(() => {
    //     if(app_currency !== 'CHF'){
    //         getCurrencyData();
    //     }
        
    // }, []);

    // useEffect(() => {
    //     setInterval(
    //         getCurrencyData
    //     , 600000); 
    //   },[]); 

    function splitToChunks(array, parts) {
        let result = [];
        for (let i = parts; i > 0; i--) {
            result.push(array.splice(0, Math.ceil(array.length / i)));
        }
        return result;
    }

    useEffect(() => {
        setheaderBrands(splitToChunks([...brands], 4))
    }, []);

    const logout = () => {
        unsetUserLogin()
        navigate('/')
    }


    const handleToggle = () => setToggled(!isToggled);
    return (
        <>
            <header className={`header-area header-style-4 header-height-2`}>
                <div className="header-top header-top-ptb-1 d-none d-lg-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="header-info">
                                    <ul>
                                        <li>
                                            <i className="fi-rs-smartphone"></i>
                                            <Link to={webSettingsData && webSettingsData.phone_number ? "tel:" + webSettingsData.phone_number.name_de : "/"}>
                                                {webSettingsData && webSettingsData.phone_number && webSettingsData.phone_number.name_de}
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="far fa-envelope"></i>
                                            <Link to={webSettingsData && webSettingsData.email ? "mailto:" + webSettingsData.email.name_de : "#"}>
                                                {webSettingsData && webSettingsData.email && webSettingsData.email.name_de}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="header-info header-info-right">
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                {!sessionStorage.getItem('lng_name') ? 'English' : sessionStorage.getItem('lng_name')}
                                                <i className="fi-rs-angle-small-down"></i>

                                            </Link>
                                            <ul className="language-dropdown">
                                                <li>
                                                    <Link to="#" onClick={() => changeLangueg('de', 'German')}>
                                                        German
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#" onClick={() => changeLangueg('en', 'English')}>
                                                        English
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#" onClick={() => changeLangueg('fr', 'Français')}>
                                                        Français
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="#" onClick={() => changeLangueg('it', 'Italian')}>
                                                        Italian
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to="#">
                                                {app_currency}
                                                <i className="fi-rs-angle-small-down"></i>

                                            </Link>
                                            <ul className="language-dropdown">
                                                <li>
                                                    <Link to="#" onClick={() => ChangeCurrency('CHF')}>
                                                        CHF
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#" onClick={() => ChangeCurrency('USD')}>
                                                        USD
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#" onClick={() => ChangeCurrency('EUR')}>
                                                        EUR
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <i className="fi-rs-user"></i>
                                            {!isUserLogin ? <Link to="/konto">{t("Log In / Sign Up")}
                                            </Link> : <><Link to="#">{t("Dashboard")}  <i className="fi-rs-angle-small-down"></i>
                                            </Link> <ul className="language-dropdown">
                                                    <li>
                                                        <Link to="/benutzer/armaturenbrett">{t("Profile")}</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" onClick={() => logout()}>{t("Logout")}</Link>
                                                    </li>
                                                </ul></>}

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-middle d-none d-lg-block" style={{ padding:'15px 0' }}>
                    <div className="container">
                        <div className="header-wrap">
                            <div className="logo logo-width-1">
                                <Link to="/">
                                    <img
                                        src={webSettingsData && webSettingsData.logo && server + "/" + webSettingsData.logo.link}
                                        alt={webSettingsData && webSettingsData.logo && webSettingsData.logo['alt_' + i18n.language]}

                                    />
                                </Link>
                            </div>
                            <div className="header-right">
                                <div className="search-style-2">
                                    <Search categories={categories} />
                                </div>
                                <div className="header-action-right">
                                    <div className="header-action-2">
                                        {
                                            isUserLogin && <div className="header-action-icon-2">
                                                <Link to="/benutzer/wunschzettel">
                                                    <img
                                                        className="svgInject"
                                                        alt={t("Wishlist")}
                                                        src="/assets/imgs/theme/icons/icon-heart.svg"
                                                    />
                                                </Link>
                                            </div>
                                        }

                                        <div className="header-action-icon-2">
                                            <Link to="/warenkorb">
                                                <img
                                                    alt={t("Cart")}
                                                    src="/assets/imgs/theme/icons/icon-cart.svg"
                                                />
                                                <span className="pro-count blue">
                                                    {cartTotalCount}
                                                </span>

                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        scroll
                            ? "header-bottom header-bottom-bg-color sticky-bar stick"
                            : "header-bottom header-bottom-bg-color sticky-bar"
                    }
                >
                    <div className="container">
                        <div className="header-wrap header-space-between position-relative">
                            <div className="logo logo-width-1 d-block d-lg-none">
                                <Link to="/">

                                    <img
                                       src={webSettingsData && webSettingsData.logo && server + "/" + webSettingsData.logo.link}
                                       alt={webSettingsData && webSettingsData.logo && webSettingsData.logo['alt_' + i18n.language]}
                                    />

                                </Link>
                            </div>
                            <div className="header-nav d-none d-lg-flex">
                                <div className="main-categori-wrap d-none d-lg-block">
                                    <Link to="#"
                                        className="categori-button-active"
                                        onClick={handleToggle}
                                    >
                                        <span className="fi-rs-apps"></span>
                                        {t("Browse Categories")}
                                    </Link>

                                    <div
                                        className={
                                            isToggled
                                                ? "categori-dropdown-wrap categori-dropdown-active-large open"
                                                : "categori-dropdown-wrap categori-dropdown-active-large"
                                        }
                                    >
                                        <ul>
                                            {
                                                categories.length > 0 && categories.map((item, index) => (
                                                    <li key={index} className={item.subcategory.length > 0 && 'has-children'}>
                                                        <Link to={"/"+item.slug}>{item['name_' + i18n.language]}</Link>
                                                        {
                                                            item.subcategory.length > 0 &&

                                                            <>
                                                                <div className="dropdown-menu">
                                                                    {/* <ul className="mega-menu d-lg-flex">
                                                                        <li className="mega-menu-col col-lg-12"> */}
                                                                            <ul >
                                                                                <li key={index}>
                                                                                    <div className="row">
                                                                                {item.subcategory.map((subitem, index) => (
                                                                                    <div className="col-6">
                                                                                        <ul>
                                                                                            <li><Link to={"/"+item.slug+"/"+subitem.slug} className="submenu-title">{subitem['name_' + i18n.language]}</Link></li>

                                                                                            {subitem.subcategory.length > 0 && subitem.subcategory.map((childitem, index) => (
                                                                                                <li key={index}><Link to={"/"+item.slug+"/"+subitem.slug+"/"+childitem.slug} className="submenu-title">{childitem['name_' + i18n.language]}</Link></li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                ))}
                                                                                </div>
                                                                                </li>
                                                                            </ul>
                                                                        {/* </li> */}
                                                                        {/* <li className="mega-menu-col col-lg-4">
                                                                            <div className="header-banner2">
                                                                                <img
                                                                                    src="/assets/imgs/banner/menu-banner-2.jpg"
                                                                                    alt="menu_banner1"
                                                                                />
                                                                                <div className="banne_info">
                                                                                    <h6>
                                                                                        10% Off
                                                                                    </h6>
                                                                                    <h4>
                                                                                        New
                                                                                        Arrival
                                                                                    </h4>
                                                                                    <Link to="#">
                                                                                            Shop
                                                                                            now
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="header-banner2">
                                                                                <img
                                                                                    src="/assets/imgs/banner/menu-banner-3.jpg"
                                                                                    alt="menu_banner2"
                                                                                />
                                                                                <div className="banne_info">
                                                                                    <h6>
                                                                                        15% Off
                                                                                    </h6>
                                                                                    <h4>
                                                                                        Hot
                                                                                        Deals
                                                                                    </h4>
                                                                                    <Link to="#">
                                                                                            Shop
                                                                                            now
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </li> */}
                                                                    {/* </ul> */}
                                                                </div>
                                                            </>
                                                        }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block">
                                    <nav>
                                        <ul>
                                            {
                                                navbar_items.length > 0 && 
                                                navbar_items.map((item,index) => (
                                                    <>
                                                        {
                                                            item.name_en.toLocaleLowerCase().indexOf("brands") !== -1 || item.name_en.toLocaleLowerCase().indexOf("brand") !== -1
                                                            ?
                                                            <li key={index} className="position-static">
                                                                <NavLink to={item.link} 
                                                                className={({isActive}) => (isActive ? "active" : '')}
                                                                >
                                                                    {item['name_'+i18n.language]} 
                                                                    <i className="fi-rs-angle-down"></i>
                                                                </NavLink>
                                                                <ul className="mega-menu">
                                                                        {
                                                                            headerBrands.length > 0 &&
                                                                            headerBrands.map((item, index) => (
                                                                                <li key={index} className="sub-mega-menu sub-mega-menu-width-22" >
                                                                                    <NavLink className="menu-title" to="#">{t("Top Brands")}</NavLink>
                                                                                    <ul>
                                                                                        {
                                                                                            item.length > 0 && item.map((elem, index) => (
                                                                                                <li key={index}>
                                                                                                    <NavLink to={"/marken/" + elem.slug}>
                                                                                                        {elem.name}
                                                                                                    </NavLink>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </ul>
                                                                                </li>
                                                                            ))
                                                                        }

                                                                </ul>
                                                            </li>
                                                            :
                                                            <li key={index}>
                                                            <>
                                                                <NavLink to={item.link}>
                                                                    {item['name_'+i18n.language]}
                                                                    { item.subnav.length > 0 &&  <i className="fi-rs-angle-down"></i>}
                                                                </NavLink>
                                                                { item.subnav.length > 0 && 
                                                                     <ul className="sub-menu">
                                                                        {
                                                                            item.subnav.map((subnav, sbkey) => (
                                                                                <li key={sbkey}>
                                                                                    <NavLink to={subnav.link}>
                                                                                            {subnav['name_'+i18n.language]}
                                                                                    </NavLink>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                     </ul>
                                                                }
                                                            </>
                                                            </li>
                                                        }
                                                    </>
                                                  
                                                ))
                                            }
                                           
                                            
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="hotline d-none d-lg-block">
                                <p>
                                    
                                    <ReactWhatsapp number={webSettingsData ? webSettingsData.phone_number.name_en : '+41 79 773 87 12'} style={{ color:'black', backgroundColor: 'transparent', border: 'none', fontWeight: 'bold' }}>
                                        <i className="fab fa-whatsapp"></i>
                                        {webSettingsData && webSettingsData.phone_number && webSettingsData.phone_number.name_en}
                                    </ReactWhatsapp>
                                </p>
                            </div>
                            <div className="header-action-right d-block d-lg-none">
                                <div className="header-action-2">
                                  
                                    <div className="header-action-icon-2">
                                        <Link to="/warenkorb">
                                                <img
                                                    alt={t("Cart")}
                                                    src="/assets/imgs/theme/icons/icon-cart.svg"
                                                />
                                                <span className="pro-count white">
                                                {cartTotalCount}
                                                </span>
                                            
                                        </Link>
                                    </div>
                                    <div className="header-action-icon-2 d-block d-lg-none">
                                        <div
                                            className="burger-icon burger-icon-white"
                                            onClick={toggleClick}
                                        >
                                            <span className="burger-icon-top"></span>
                                            <span className="burger-icon-mid"></span>
                                            <span className="burger-icon-bottom"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};



export default Header;
