import { Link } from "react-router-dom";
import { server } from "../util/server";
import { useTranslation } from 'react-i18next';
import Spinner from "../components/Spinner/Main";
function DynamicPage({ data }) {
  const { i18n, t } = useTranslation();
  
  return (
    <>
      <div className={`page-header breadcrumb-wrap`}>
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">
              {t("Home")}
            </Link>
            <span></span> {data && data['title_' + i18n.language]}
          </div>
        </div>
      </div>
      {data !== null ?
        <>
          <section className="mt-50 mb-50">
            <div className="container custom">
              <div className="row">
                <div className="col-lg-10 m-auto">
                  <div className="single-page pl-30">
                    {
                      data &&
                      <>
                        <div className="single-header style-2">
                          <h1 className="mb-30">
                            {data['title_' + i18n.language]}
                          </h1>
                        </div>

                        <div className="single-content">
                          {
                            data && data.pagedetail.length > 0 &&
                            data.pagedetail.map(item => (
                              item.content_type === 'img' ?
                                <img src={item.page_image && server + "/storage/media/" + item.page_image.image} alt="" />

                                :
                                <div dangerouslySetInnerHTML={{ __html: item['content_' + i18n.language] }} />
                            ))
                          }
                        </div>
                      </>
                    }
                  </div>
                  {/* col-10 */}
                </div>
              </div>
            </div>
          </section>
        </>
        : <Spinner />
      }

    </>
  );
}

export default DynamicPage;
