import React, { useEffect } from 'react'
import {useNavigate} from "react-router-dom";
import {useFilterContext} from '../../context/filterContext';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import { server } from '../../util/server';
import { toast } from "react-toastify";
import axios from "axios"
import { useTranslation } from 'react-i18next';
import { Meta } from '../../components/elements/Meta';

export default function Dashboard() {
    const { t } = useTranslation();
  const {isUserLogin,updatemetas} = useFilterContext();
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem('local_token')
  
  useEffect(() => {
      if(isUserLogin === null){
        navigate('/');
      }
  }, [isUserLogin])

  useEffect(() => {
    updatemetas('benutzer/armaturenbrett',4)
}, [])

  const sunUnsubNewsletter = (type) => {
            let url = server+"/api/unsubscribe-newsletter"
            axios({
                method: "get",
                url: url,
                headers: { 'Content-Type' : 'application/json' , "Authorization": token ? `Bearer ${token}` : '' },
            }).then(function (result) {
                if(result.data.status === true){
                    toast.success(t(result.data.message))
                }else{
                    toast.error(t(result.data.message))
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    toast.error(t("Please login to continue"));
                } else {
                    toast.error(t("Unexpected error occurred!"));
                }
            });
  }
  return (
        <>
                <Meta />
              <div className={`page-header breadcrumb-wrap`}>
                  <div className="container">
                      <div className="breadcrumb">
                          <Link to="/">
                              {t("Home")}
                          </Link>
                          <span></span> {t("Dashboard")}
                      </div>
                  </div>
              </div>

              <section className="pt-50 pb-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 m-auto">
                                <div className="row">
                                  <Sidebar />
                                   
                                    <div className="col-lg-9 col-md-8">
                                        <h3 className="mb-0">
                                             {t("Dashboard")}
                                             <Link to="#" className='bg-dark py-2 px-3 ms-2 fw-normal float-end rounded' style={{ fontSize: '14px',color:'#fff' }} onClick={() => sunUnsubNewsletter('unsubscribe')}>{t("Unsubscribe")}</Link>
                                        </h3>
                                        <hr className='mb-4'/>

                                        <p className='mb-5'>{t("From your account dashboard, you can easily")} <Link to="/benutzer/bestellungen">{t("check & view your reservations")}</Link>, <Link to="/benutzer/wunschzettel">{t("manage your wishlist")}</Link> {t("and")} <Link to="/benutzer/Profil">{t("edit your password and account details")}</Link>.</p>

                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </section>
        
        </>
         
  )
}
